import {
  ESPage,
  ESPageLoadingIndicator,
  RefetchOnError,
  useDocumentTitle,
} from '@energy-stacks/shared';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePagination, useSort } from '@energy-stacks/core/ui';
import { useState } from 'react';
import {
  ReasonModel,
  TariffTypeModel,
} from '@energy-stacks/obelis/feature-charging-sessions-data';
import { useChargingSessions } from './useChargingSessions';
import { DateRange } from '@energy-stacks/core/date-range-picker';
import { ChargingSessionsTable } from '@energy-stacks/obelis/shared';

export const ChargingSessionsPage = () => {
  const { t } = useTranslation('chargingSessions');
  useDocumentTitle(t('sidebarItem'));
  const { sorting, setSorting } = useSort();
  const { pagination, setPagination } = usePagination();
  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
  const [search, setSearch] = useState('');
  const [reasonFilter, setReasonFilter] = useState<ReasonModel | undefined>(
    undefined
  );
  const [tariffTypeFilter, setTariffTypeFilter] = useState<
    TariffTypeModel | undefined
  >(undefined);

  const { chargingSessionsResponse, refetch, isLoading, isError } =
    useChargingSessions({
      filterDate: {
        startDateTime: dateRange?.startDate,
        endDateTime: dateRange?.endDate,
      },
      search: search,
      reason: reasonFilter,
      tariffType: tariffTypeFilter,
      pagination: {
        page: pagination.pageIndex,
        size: pagination.pageSize,
      },
    });

  return (
    <ESPage title={t('sidebarItem')}>
      {isLoading && <ESPageLoadingIndicator />}
      {isError && <RefetchOnError onRefetch={refetch} />}
      {!isLoading && !isError ? (
        <ChargingSessionsTable
          chargingSessionsResponse={chargingSessionsResponse}
          dateRange={dateRange}
          sorting={sorting}
          onSortingChange={setSorting}
          pagination={pagination}
          onPaginationChange={setPagination}
          onDateRangeChange={(range) => setDateRange(range)}
          search={search}
          setSearch={setSearch}
          testId="chargingSessions"
          reasonFilter={reasonFilter}
          tariffTypeFilter={tariffTypeFilter}
          onSetReasonFilter={setReasonFilter}
          onSetTariffTypeFilter={setTariffTypeFilter}
        />
      ) : null}
      <Outlet />
    </ESPage>
  );
};
