import { formatMonthYearToPayload } from '@energy-stacks/shared';
import { toPayloadDateObelis } from '@energy-stacks/shared';
import { CreateProcessedNonDiscriminatoryAccessReportPayload } from './createProcessedNonDiscriminatoryAccessReportPayload';
import { EditProcessedNonDiscriminatoryAccessReportPayload } from './editProcessedNonDiscriminatoryAccessReportPayload';
import { nonDiscriminatoryAccessReportContractualBasisModelToDto } from '../transformers/nonDiscriminatoryAccessReportContractualBasisMaps';
import { additionalCostsReportToPayload } from '../normalizers/additionalCostsReportToPayload';
import { ProcessedNonDiscriminatoryAccessReportBody } from '../../systemProcessedReportsApi';

export const processedNonDiscriminatoryAccessReportCreateDataToPayload = (
  nonDiscriminatoryAccessReportFormData: ProcessedNonDiscriminatoryAccessReportBody
): CreateProcessedNonDiscriminatoryAccessReportPayload => {
  const {
    monthYear,
    providerGroup,
    cost,
    additionalCosts,
    validFrom,
    validTo,
    contractualBasis,
    reasoningDifferentCosts,
  } = nonDiscriminatoryAccessReportFormData;

  return {
    monthYear: formatMonthYearToPayload(monthYear),
    providerGroup: providerGroup,
    cost: Number(cost),
    additionalCosts: additionalCostsReportToPayload(additionalCosts ?? []),
    validFrom: toPayloadDateObelis(validFrom),
    validTo: toPayloadDateObelis(validTo),
    contractualBasis:
      nonDiscriminatoryAccessReportContractualBasisModelToDto[contractualBasis],
    reasoningDifferentCosts: reasoningDifferentCosts,
  };
};

export const processedNonDiscriminatoryAccessReportEditDataToPayload = (
  nonDiscriminatoryAccessReportFormData: ProcessedNonDiscriminatoryAccessReportBody
): EditProcessedNonDiscriminatoryAccessReportPayload => {
  const {
    monthYear,
    providerGroup,
    cost,
    additionalCosts,
    validFrom,
    validTo,
    contractualBasis,
    reasoningDifferentCosts,
  } = nonDiscriminatoryAccessReportFormData;

  return {
    monthYear: formatMonthYearToPayload(monthYear),
    providerGroup: providerGroup,
    cost: Number(cost),
    additionalCosts: additionalCostsReportToPayload(additionalCosts ?? []),
    validFrom: toPayloadDateObelis(validFrom),
    validTo: toPayloadDateObelis(validTo),
    contractualBasis:
      nonDiscriminatoryAccessReportContractualBasisModelToDto[contractualBasis],
    reasoningDifferentCosts: reasoningDifferentCosts,
  };
};
