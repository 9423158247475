import { environment } from '@energy-stacks/feature-config';
import { useEffect } from 'react';

const faviconUrl = environment.ocppServiceUrl + '/ui-configuration/favicon.ico';
const faviconLinkSelector = 'link[rel="icon"]';

const getUniqueFaviconUrl = () => {
  // Hack: Make unique source attribute, so we avoid usage of cashed image - we need instant change on success
  const hashDate = Date.now().toPrecision();
  return faviconUrl + '?' + hashDate;
};

export const useSetFavicon = () => {
  useEffect(() => {
    setFavicon();
  }, []);
};

export const setFavicon = () => {
  const faviconElement =
    document.querySelector<HTMLLinkElement>(faviconLinkSelector);
  faviconElement?.setAttribute('data-testid', 'faviconImage');

  if (!faviconElement) {
    const newLink = document.createElement('link');
    newLink.rel = 'icon';
    newLink.type = 'image/x-icon';
    newLink.href = getUniqueFaviconUrl();
    document.head.appendChild(newLink);
  }
};

export const resetFaviconHref = () => {
  const faviconElement =
    document.querySelector<HTMLLinkElement>(faviconLinkSelector);

  if (faviconElement) {
    faviconElement.setAttribute('href', getUniqueFaviconUrl());
  }
};
