import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, RadioGroup, Typography } from '@mui/material';
import { ChargingStationReportTypeRadioButton } from './ChargingStationReportTypeRadioButton';
import {
  ProcessedReportTypeModel,
  processedReportTypeModel,
} from '@energy-stacks/obelis/feature-charging-station-reports-data';

interface ChargingStationReportOptionsRadioGroupProps {
  selectedReportType: ProcessedReportTypeModel | null;
  setSelectedReportType: (value: ProcessedReportTypeModel) => void;
}

export const ChargingStationReportOptionsRadioGroup = ({
  selectedReportType,
  setSelectedReportType,
}: ChargingStationReportOptionsRadioGroupProps) => {
  const { t } = useTranslation('chargingStationReports');
  const [selectedValue, setSelectedValue] =
    useState<ProcessedReportTypeModel | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as ProcessedReportTypeModel;
    setSelectedReportType(value);
    setSelectedValue(value);
  };

  return (
    <Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: '500',
            mb: 6,
          }}
        >
          {t('chooseOneTypeOfReport')}:
        </Typography>
      </Box>

      <RadioGroup
        aria-label="options"
        name="options"
        value={selectedReportType}
        onChange={handleChange}
        sx={{
          display: 'flex',
          gap: '12px',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        {processedReportTypeModel.map((option) => (
          <ChargingStationReportTypeRadioButton
            key={option}
            option={option}
            selectedValue={selectedValue ?? null}
          />
        ))}
      </RadioGroup>
    </Grid>
  );
};
