import React, { ChangeEvent, useState } from 'react';
import { MarkerF } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  InputAdornment,
  Popper,
  TextField,
  useTheme,
} from '@mui/material';
import Geocode from 'react-geocode';
import { ESMap, showSnackbar } from '@energy-stacks/core/ui';
import { IconSearch } from '@tabler/icons-react';
import { Coordinates } from './ChargingStationLocationMapView';
import csMarkerPOI from '../../../assets/csMarkerPOI.svg';
import { coordsToNumbers } from './coordsToNumbers';

type EditableMapProps = {
  center: Coordinates;
  zoom: number;
  onMapReady: () => void;
  onCoordsChange: (newCoords: Coordinates) => void;
  hasBorderRadius?: boolean;
  icon?: string;
};

export const EditableMap: React.FC<EditableMapProps> = ({
  center,
  zoom,
  onMapReady,
  onCoordsChange,
  hasBorderRadius,
  icon,
}) => {
  const [t] = useTranslation('chargingStations');
  const [tShared] = useTranslation('shared');
  const { spacing } = useTheme();
  const mapCenter = coordsToNumbers(center);
  const [autocompleteOptions, setAutocompleteOptions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const autocompleteService = new google.maps.places.AutocompleteService();
    if (input.length > 0) {
      autocompleteService.getPlacePredictions({ input }, (predictions) => {
        predictions
          ? setAutocompleteOptions(predictions)
          : setAutocompleteOptions([]);
      });
    }
  };

  const handleGetCoordinatesFromAddress = (address: string) => {
    const getCoordinatesFromAddress = async () => {
      const addressResult = await Geocode.fromAddress(address);
      return addressResult;
    };
    getCoordinatesFromAddress()
      .then((res) => {
        if (res.results[0]?.geometry?.location) {
          const lat = res.results[0]?.geometry?.location.lat as number;
          const lng = res.results[0]?.geometry?.location.lng as number;

          onCoordsChange({
            latitude: lat.toFixed(7).toString(),
            longitude: lng.toFixed(7).toString(),
          });
        } else {
          showSnackbar('error', t('locationDialogSearchCoordinatesError'));
        }
      })
      .catch(() =>
        showSnackbar('error', t('locationDialogSearchCoordinatesError'))
      );
  };

  return (
    <ESMap
      hasBorderRadius={hasBorderRadius}
      center={mapCenter}
      zoom={zoom}
      onMapReady={onMapReady}
      options={{
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
          mapTypeIds: [
            google.maps.MapTypeId.ROADMAP,
            google.maps.MapTypeId.SATELLITE,
          ],
        },
      }}
    >
      <Autocomplete
        sx={{
          position: 'absolute',
          left: 7,
          right: 58,
          top: 7,
          '& .MuiOutlinedInput-root': {
            px: 4,
            borderRadius: hasBorderRadius ? 3 : 0,
            '& .MuiAutocomplete-input': {
              pl: 1,
              borderRadius: hasBorderRadius ? 3 : 0,
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: hasBorderRadius ? 3 : 0,
          },
        }}
        freeSolo
        options={autocompleteOptions.map((prediction) =>
          prediction.description.toString()
        )}
        // Popper container to open only if there are predictions available.
        // If Popper is empty container, shadow appears becuase of default mui styling.
        PopperComponent={(props) => (
          <Popper
            placeholder={null}
            {...props}
            open={autocompleteOptions.length > 0}
          />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={tShared('search')}
            InputProps={{
              ...params.InputProps,
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  // When there is no Predictions in Autocomplete and pressing Enter,
                  // default behaviour is to send automatically "save" button click,
                  // which we want to prevent from happening
                  e.preventDefault();
                }
              },
              sx: {
                '& .MuiOutlinedInput-root': {
                  px: 0,
                },
              },
              startAdornment: (
                <InputAdornment position="start">
                  <IconSearch stroke={1.5} size={spacing(4.5)} />
                </InputAdornment>
              ),
            }}
            onChange={handleInputChange}
          />
        )}
        onChange={(_, newValue: string | null) => {
          newValue && handleGetCoordinatesFromAddress(newValue);
        }}
      />

      <MarkerF
        draggable={true}
        position={mapCenter}
        icon={{
          url: icon ?? csMarkerPOI,
          scaledSize: new window.google.maps.Size(48, 48),
        }}
        onDragEnd={async (e) => {
          const lat = e.latLng?.lat() || 0;
          const lng = e.latLng?.lng() || 0;

          if (lat && lng) {
            // Fixed 7 decimals becuase of the rule on OCPI
            onCoordsChange({
              latitude: String(lat.toFixed(7)),
              longitude: String(lng.toFixed(7)),
            });
          }
        }}
      />
    </ESMap>
  );
};
