import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PaginationState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import {
  ESMenuItem,
  ESTable,
  ESTableBasicConfig,
  ESTableBody,
  ESTableHead,
  ESTablePagination,
  ESTableWrapper,
  useFitRows,
} from '@energy-stacks/core/ui';
import {
  ESTooltip,
  formatDate,
  formatDateTime,
  MoreOptionsMenu,
  NoTableData,
  TimeDistance,
} from '@energy-stacks/shared';
import {
  CreateReportButton,
  MonthYearPicker,
} from '@energy-stacks/obelis/shared';
import {
  SystemProcessedReport,
  SystemProcessedReportModel,
} from '@energy-stacks/obelis/feature-system-reports-data';

interface SystemProcessedReportsTableProps
  extends ESTableBasicConfig<SystemProcessedReport> {
  systemProcessedReports?: SystemProcessedReportModel;
  testId?: string;
  date: Date;
  setDate: (date: Date) => void;
  pagination: PaginationState;
  onPaginationChange: Dispatch<SetStateAction<PaginationState>>;
  sorting: SortingState;
  onSortingChange: Dispatch<SetStateAction<SortingState>>;
}

export const SystemProcessedReportsTable: React.FC<
  SystemProcessedReportsTableProps
> = ({
  systemProcessedReports,
  testId = 'systemProcessedReports',
  date,
  setDate,
  pagination,
  onPaginationChange,
  sorting,
  onSortingChange,
}) => {
  const { t } = useTranslation('systemReports');
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<SystemProcessedReport>();

  const columns = [
    columnHelper.accessor('reportType', {
      header: () => t('reportType'),
      footer: (props) => props.column.id,
      cell: (info) => {
        return t(info.getValue() ?? '');
      },
      enableSorting: true,
    }),
    columnHelper.accessor('reportDate', {
      header: () => t('reportDate'),
      footer: (props) => props.column.id,
      cell: (info) => {
        const value = info.getValue();
        return value ? formatDate(value) : '-';
      },
      enableSorting: true,
    }),
    columnHelper.accessor('reportedBy', {
      header: () => t('reportedBy'),
      footer: (props) => props.column.id,
      cell: (info) => {
        return info.getValue() ?? '-';
      },
      enableSorting: true,
    }),
    columnHelper.accessor('modifiedBy', {
      header: () => t('modifiedBy'),
      footer: (props) => props.column.id,
      cell: (info) => {
        return info.getValue() ?? '-';
      },
      enableSorting: true,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => t('actions'),
      cell: (info) => (
        <MoreOptionsMenu testId={info.row.original.uid + testId}>
          <ESMenuItem
            testId={`${info.row.original.uid}MenuItemEditButton`}
            onClick={() =>
              navigate(`${info.row.original.uid}/edit`, {
                state: { reportType: info.row.original.reportType },
              })
            }
          >
            {t('editMenuItem')}
          </ESMenuItem>
        </MoreOptionsMenu>
      ),
    }),
  ];

  const instance = useReactTable({
    data: systemProcessedReports?.content ?? [],
    columns,
    state: {
      pagination,
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange,
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange,
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    pageCount: systemProcessedReports?.totalPages,
    enableColumnResizing: false,
  });

  const { rowsPerPageOptions } = useFitRows(
    instance,
    systemProcessedReports?.totalElements ?? 0
  );

  const rows = instance.getRowModel().rows;
  const hasRows = rows.length !== 0;

  const lastUpdated = systemProcessedReports?.content.length
    ? systemProcessedReports.content.reduce((a, b) => {
        return new Date(a.lastModifiedDate ?? '-').getTime() >
          new Date(b.lastModifiedDate ?? '-').getTime()
          ? a
          : b;
      })
    : null;

  return (
    <>
      <Box display="flex" mb={6}>
        <Stack direction="row" gap={3} alignItems="center">
          <MonthYearPicker value={date} onChange={setDate} />
        </Stack>
        <Stack direction="row" gap={4} alignItems="center" ml="auto">
          {lastUpdated && (
            <ESTooltip
              title={formatDateTime(lastUpdated.lastModifiedDate ?? '')}
            >
              <Box display="flex" alignItems="center">
                <Typography sx={{ marginRight: 1 }} fontSize={12}>
                  {t('lastUpdated')}:
                </Typography>
                <TimeDistance date={lastUpdated.lastModifiedDate ?? ''} />
              </Box>
            </ESTooltip>
          )}
          <CreateReportButton selectedMonth={date} />
        </Stack>
      </Box>
      <ESTableWrapper>
        <ESTable>
          <ESTableHead testId={testId} instance={instance} />
          <ESTableBody testId={testId} rows={rows} />
        </ESTable>
        {!hasRows ? <NoTableData message={t('noReports')} /> : null}
        <ESTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          instance={instance}
          count={systemProcessedReports?.totalElements}
        />
      </ESTableWrapper>
    </>
  );
};
