import { appendZToDateString } from '@energy-stacks/shared';
import {
  ActivitiesDto,
  AdditionalInformationDto,
  RawDefectReportDto,
  StatusDto,
  TypeDto,
} from '../rawDefectReportDto';
import {
  TypeModel,
  StatusModel,
  ActivitiesModel,
  AdditionalInformationModel,
  RawDefectReportModel,
} from '../rawDefectReportModel';

export const rawDefectReportNormalizer = (
  report: RawDefectReportDto
): RawDefectReportModel => {
  const {
    activities,
    additionalInformation,
    chargingHubUid,
    chargingPointUid,
    evseId,
    emergenceDate,
    fixDate,
    forecastDate,
    status,
    type,
    uid,
  } = report;

  return {
    activities: activitiesDtoToModelMap[activities],
    additionalInformation: additionalInformation
      ? additionalInformationDtoToModelMap[additionalInformation]
      : null,
    chargingHubUid,
    chargingPointUid,
    evseId: evseId || null,
    emergenceDate: appendZToDateString(emergenceDate),
    fixDate: appendZToDateString(fixDate) || null,
    forecastDate: appendZToDateString(forecastDate) || null,
    status: statusDtoToModelMap[status],
    type: typeDtoToModelMap[type],
    uid,
  };
};

export const typeDtoToModelMap: Record<TypeDto, TypeModel> = {
  DAMAGE: 'damage',
  HARDWARE_FAULT: 'hardwareFault',
  SOFTWARE_FAULT: 'softwareFault',
  NETWORK_FAILURE: 'networkFailure',
  POWER_FAILURE: 'powerFailure',
  ROUTE_CLOSURE: 'routeClosure',
  SITE_CLOSURE: 'siteClosure',
  OTHER: 'other',
};

export const statusDtoToModelMap: Record<StatusDto, StatusModel> = {
  TECHNICIAN_INFORMED: 'technicianInformed',
  TECHNICIAN_ON_THE_WAY: 'technicianOnTheWay',
  ERROR_SOLVED: 'errorSolved',
};

export const activitiesDtoToModelMap: Record<ActivitiesDto, ActivitiesModel> = {
  REMOTE_ACCESS: 'remoteAccess',
  ON_SITE: 'onSite',
};

export const additionalInformationDtoToModelMap: Record<
  AdditionalInformationDto,
  AdditionalInformationModel
> = {
  DANGEROUS_MALFUNCTION: 'dangerousMalFunction',
  USAGE_IMPOSSIBLE: 'usageImpossible',
  RENEWAL_NECESSARY: 'renewalNecessary',
};
