import { ObelisUserDto } from '../obelisUserDto';
import { ObelisUserModel } from '../obelisUserModel';

export const obelisUserModelNormalizer = (
  obelisUserDto: ObelisUserDto
): ObelisUserModel => ({
  displayName: obelisUserDto.name,
  emailAddress: obelisUserDto.email,
  isActive: true,
  userRole: 'admin',
});
