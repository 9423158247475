export interface RenewedComponentsModel {
  chargePointComponent: ChargePointComponentModel;
  reason: ReasonModel;
}

export const chargePointComponentModel = [
  'cableCoolingUnit',
  'networkingElectronics',
  'powerModule',
  'paymentDevice',
  'vehicleCommunicationUnit',
  'backendCommunicationUnit',
  'display',
  'dcMeter',
  'airFilter',
  'others',
] as const;

export type ChargePointComponentModel =
  (typeof chargePointComponentModel)[number];

export const reasonModel = [
  'damage',
  'hardwareFault',
  'softwareFault',
  'retrofitting',
  'others',
] as const;

export type ReasonModel = (typeof reasonModel)[number];
