import { createApi } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { renewalReportNormalizer } from './renewal-reports/normalizers/renewalReportNormalizer';
import { RawRenewalReportDto } from './renewal-reports/rawRenewalReportsDto';
import { RenewalReportModel } from './renewal-reports/renewalReportModel';
import { RawRatingReportDto } from './rating-reports/rawRatingReportDto';
import { RawRatingReportModel } from './rating-reports/rawRatingReportModel';
import { rawRatingReportsNormalizer } from './rating-reports/normalizers/rawRatingReportsNormalizer';
import { rawDefectReportNormalizer } from './defect-reports/normalizers/rawDefectReportNormalizer';
import { RawDefectReportDto } from './defect-reports/rawDefectReportDto';
import { RawDefectReportModel } from './defect-reports/rawDefectReportModel';

export const chargingStationRawReportsApi = createApi({
  reducerPath: 'chargingStationRawReportsApi',
  baseQuery: createBaseQuery(
    `${environment.ocppServiceUrl}/raw-reports/charging-stations`
  ),
  tagTypes: ['rawRenewalReport', 'rawDefectReport', 'rawRatingReport'],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getRawRenewalReport: builder.query<
      RenewalReportModel,
      { identityKey: string }
    >({
      query: ({ identityKey }) => ({
        url: `/${identityKey}/charging-points/renewal/latest`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'rawRenewalReport',
          id: args.identityKey,
        },
      ],
      transformResponse: (response: RawRenewalReportDto) => {
        return renewalReportNormalizer(response);
      },
    }),
    getRawDefectReport: builder.query<
      RawDefectReportModel,
      { identityKey: string }
    >({
      query: ({ identityKey }) => ({
        url: `/${identityKey}/charging-points/defects/latest`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'rawDefectReport',
          id: args.identityKey,
        },
      ],
      transformResponse: (response: RawDefectReportDto) => {
        return rawDefectReportNormalizer(response);
      },
    }),
    getRawRatingReports: builder.query<
      RawRatingReportModel[],
      { identityKey: string; monthYear: string }
    >({
      query: ({ identityKey, monthYear }) => ({
        url: `/${identityKey}/charging-points/ratings/${monthYear}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'rawRatingReport',
          id: args.identityKey,
        },
      ],
      transformResponse: (response: RawRatingReportDto[]) => {
        return rawRatingReportsNormalizer(response);
      },
    }),
  }),
});

export const {
  useGetRawRenewalReportQuery,
  useGetRawRatingReportsQuery,
  useGetRawDefectReportQuery,
} = chargingStationRawReportsApi;
