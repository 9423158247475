import { FC, useMemo, useState } from 'react';
import { IconButton, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import {
  addMonths,
  endOfDay,
  isAfter,
  isBefore,
  lastDayOfMonth,
  subMonths,
} from 'date-fns';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { formatMonthYear } from '@energy-stacks/shared';
import { debounce as lodashDebounce } from 'lodash-es';

const MIN_DATE = new Date(2024, 0, 1, 0, 0, 0);
const MAX_DATE = endOfDay(lastDayOfMonth(new Date()));

type MonthYearPickerProps = {
  value: Date;
  onChange: (newValue: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  debounce?: number;
};

export const MonthYearPicker: FC<MonthYearPickerProps> = ({
  value: initialValue,
  onChange,
  minDate = MIN_DATE,
  maxDate = MAX_DATE,
  debounce = 0,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(initialValue);
  const prevMonth = subMonths(value, 1);
  const nextMonth = addMonths(value, 1);

  const debouncedOnChange = useMemo(
    () =>
      lodashDebounce((date) => {
        onChange(date);
      }, debounce),
    [onChange, debounce]
  );

  return (
    <DatePicker
      openTo="month"
      views={['month', 'year']}
      value={value}
      onChange={(value) => {
        if (value) {
          onChange(value);
          setValue(value);
        }
      }}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      minDate={minDate}
      maxDate={maxDate}
      renderInput={(params) => (
        <TextField
          value={formatMonthYear(value)}
          onClick={() => setIsOpen(true)}
          {...params}
          onKeyDown={(e) => e.preventDefault()}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <IconButton
                disabled={isBefore(prevMonth, minDate)}
                onClick={(e) => {
                  e.stopPropagation();
                  setValue(prevMonth);
                  debouncedOnChange(prevMonth);
                }}
              >
                <IconChevronLeft stroke={1.5} />
              </IconButton>
            ),
            endAdornment: (
              <IconButton
                disabled={isAfter(nextMonth, maxDate)}
                onClick={(e) => {
                  e.stopPropagation();
                  setValue(nextMonth);
                  debouncedOnChange(nextMonth);
                }}
              >
                <IconChevronRight stroke={1.5} />
              </IconButton>
            ),
          }}
          sx={({ spacing }) => ({
            input: {
              cursor: 'pointer',
              textAlign: 'center',
              fontWeight: 400,
              fontSize: spacing(3.5),
              lineHeight: spacing(5),
              textTransform: 'uppercase',
              padding: 3.5,
              caretColor: 'transparent',
            },
            '& .MuiOutlinedInput-root': {
              cursor: 'pointer',
            },
          })}
        />
      )}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [8, 0],
            },
          },
        ],
      }}
    />
  );
};
