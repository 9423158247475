import { AdditionalCostsReportDto } from '../additionalCostsReportDto';
import { AdditionalCostsReportModel } from '../additionalCostsReportModel';

export const additionalCostsReportDtoToModelNormalizer = (
  additionalCosts: AdditionalCostsReportDto[]
): AdditionalCostsReportModel[] => {
  return additionalCosts.map((additionalCost) => {
    const { cost, description } = additionalCost;
    return {
      description: description,
      cost: String(cost),
    };
  });
};

export const additionalCostsReportToPayload = (
  additionalCosts: AdditionalCostsReportModel[]
): AdditionalCostsReportDto[] => {
  return additionalCosts.map((additionalCost) => {
    const { cost, description } = additionalCost;
    return {
      description: description,
      cost: Number(cost),
    };
  });
};
