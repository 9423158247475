import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import {
  Controller,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ESTextField, ESVirtualizedAutocomplete } from '@energy-stacks/core/ui';
import {
  connectorFormats,
  connectorPowerTypes,
  ConnectorStandard,
} from '@energy-stacks/data';
import { stringEnumToArray } from '@energy-stacks/shared';

type EvseFormConnectorCardProps = {
  connectorIndex: number;
  onRemove: UseFieldArrayRemove;
  isLast: boolean;
};

export const EvseFormConnectorCard: FC<EvseFormConnectorCardProps> = ({
  connectorIndex,
  onRemove,
  isLast,
}) => {
  const [t] = useTranslation('chargingStations');
  const [tSharedChargingStations] = useTranslation('sharedChargingStations');
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box
      sx={{
        borderColor: 'grey.300',
        borderRadius: 3,
        borderStyle: 'solid',
        borderWidth: 0.25,
        overflowY: 'hidden',
      }}
    >
      <Stack
        sx={({ spacing }) => ({
          alignItems: 'center',
          backgroundColor: 'grey.100',
          borderBottomColor: 'grey.300',
          borderBottomStyle: 'solid',
          borderBottomWidth: 0.25,
          flexDirection: 'row',
          height: spacing(10.5),
          justifyContent: 'space-between',
          pl: 4.25,
          pr: 2,
        })}
      >
        <Typography variant="h4" fontWeight={700}>{`${t(
          'evseFormConnectorCardHeader'
        )} ${connectorIndex + 1}`}</Typography>
        {!isLast && (
          <IconButton onClick={() => onRemove(connectorIndex)} size="small">
            <IconX />
          </IconButton>
        )}
      </Stack>
      <Grid
        container
        spacing={4}
        sx={{
          px: 4,
          py: 6,
        }}
      >
        <Grid item xs={6}>
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <ESVirtualizedAutocomplete
                  value={{
                    value: value.value ?? '',
                    label: value.label ?? '',
                  }}
                  label={t('evseFormLabels.connector.standard') + '*'}
                  options={stringEnumToArray(ConnectorStandard).map((v) => ({
                    label: tSharedChargingStations(`connectorTypes.${v}`),
                    value: v,
                  }))}
                  onBlur={onBlur}
                  onChange={(_, value) => {
                    if (Array.isArray(value)) {
                      return;
                    }
                    if (value) {
                      onChange({
                        value: value.value ?? '',
                        label: value.label ?? '',
                      });
                    }
                  }}
                  noOptionsText={t('noConnectorStandard')}
                  error={Boolean(
                    errors['connectors']?.[connectorIndex]?.connectorStandard
                  )}
                  helperText={
                    Boolean(
                      errors['connectors']?.[connectorIndex]?.connectorStandard
                    ) &&
                    t(
                      `evseFormErrors.${errors['connectors']?.[connectorIndex]?.connectorStandard?.value?.message}`,
                      { fieldName: 'Connector standard' }
                    )
                  }
                />
              );
            }}
            name={`connectors.${connectorIndex}.connectorStandard`}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <ESVirtualizedAutocomplete
                  value={{ value: value?.value ?? '', label: value.label }}
                  label={t('evseFormLabels.connector.format') + '*'}
                  options={connectorFormats.map((v) => ({
                    value: v,
                    label: tSharedChargingStations(`connectorFormats.${v}`),
                  }))}
                  onBlur={onBlur}
                  onChange={(_, value) => {
                    if (Array.isArray(value)) {
                      return;
                    }
                    if (value) {
                      onChange({
                        value: value.value ?? '',
                        label: value.label ?? '',
                      });
                    }
                  }}
                  noOptionsText={t('noConnectorFormat')}
                  error={Boolean(
                    errors['connectors']?.[connectorIndex]?.connectorFormat
                  )}
                  helperText={
                    Boolean(
                      errors['connectors']?.[connectorIndex]?.connectorFormat
                    ) &&
                    t(
                      `evseFormErrors.${errors['connectors']?.[connectorIndex]?.connectorFormat?.value?.message}`,
                      { fieldName: 'Connector format' }
                    )
                  }
                />
              );
            }}
            name={`connectors.${connectorIndex}.connectorFormat`}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <ESVirtualizedAutocomplete
                  value={{ value: value.value, label: value.label }}
                  label={t('evseFormLabels.connector.powerType') + '*'}
                  options={connectorPowerTypes.map((type) => ({
                    label: tSharedChargingStations(`powerTypes.${type}`),
                    value: type,
                  }))}
                  onBlur={onBlur}
                  onChange={(_, value) => {
                    if (Array.isArray(value)) {
                      return;
                    }
                    if (value) {
                      onChange({
                        value: value.value ?? '',
                        label: value.label ?? '',
                      });
                    }
                  }}
                  noOptionsText={t('noConnectorPowerType')}
                  error={Boolean(
                    errors['connectors']?.[connectorIndex]?.powerType
                  )}
                  helperText={
                    Boolean(
                      errors['connectors']?.[connectorIndex]?.powerType
                    ) &&
                    t(
                      `evseFormErrors.${errors['connectors']?.[connectorIndex]?.powerType?.value?.message}`,
                      {
                        fieldName: 'Power type',
                      }
                    )
                  }
                />
              );
            }}
            name={`connectors.${connectorIndex}.powerType`}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <ESTextField
                  label={t('evseFormLabels.connector.maxVoltage') + '* [V]'}
                  type="text"
                  onChange={(event) => {
                    const value = Number(event?.target.value);
                    if (event === undefined || !isNaN(value)) {
                      onChange(event);
                    }
                  }}
                  onBlur={onBlur}
                  error={Boolean(
                    errors['connectors']?.[connectorIndex]?.maxVoltage
                  )}
                  helperText={
                    Boolean(
                      errors['connectors']?.[connectorIndex]?.maxVoltage
                    ) &&
                    t(
                      `evseFormErrors.${errors['connectors']?.[connectorIndex]?.maxVoltage?.message}`,
                      {
                        fieldName: 'Max. voltage',
                      }
                    )
                  }
                  value={value}
                  InputProps={{
                    style: {
                      paddingBottom: 2.5,
                      paddingTop: 2.5,
                    },
                  }}
                />
              );
            }}
            name={`connectors.${connectorIndex}.maxVoltage`}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <ESTextField
                  label={t('evseFormLabels.connector.maxAmperage') + '* [A]'}
                  type="text"
                  onChange={(event) => {
                    const value = Number(event?.target.value);
                    if (event === undefined || !isNaN(value)) {
                      onChange(event);
                    }
                  }}
                  onBlur={onBlur}
                  error={Boolean(
                    errors['connectors']?.[connectorIndex]?.maxAmperage
                  )}
                  helperText={
                    Boolean(
                      errors['connectors']?.[connectorIndex]?.maxAmperage
                    ) &&
                    t(
                      `evseFormErrors.${errors['connectors']?.[connectorIndex]?.maxAmperage?.message}`,
                      {
                        fieldName: 'Max. amperage',
                      }
                    )
                  }
                  value={value}
                  InputProps={{
                    style: {
                      paddingBottom: 2.5,
                      paddingTop: 2.5,
                    },
                  }}
                />
              );
            }}
            name={`connectors.${connectorIndex}.maxAmperage`}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <ESTextField
                  label={t('evseFormLabels.connector.maxPower') + '* [W]'}
                  type="text"
                  onChange={(event) => {
                    const value = Number(event?.target.value);
                    if (event === undefined || !isNaN(value)) {
                      onChange(event);
                    }
                  }}
                  onBlur={onBlur}
                  error={Boolean(
                    errors['connectors']?.[connectorIndex]?.maxPower
                  )}
                  helperText={
                    Boolean(errors['connectors']?.[connectorIndex]?.maxPower) &&
                    t(
                      `evseFormErrors.${errors['connectors']?.[connectorIndex]?.maxPower?.message}`,
                      {
                        fieldName: 'Max. power',
                      }
                    )
                  }
                  value={value}
                  InputProps={{
                    style: {
                      paddingBottom: 2.5,
                      paddingTop: 2.5,
                    },
                  }}
                />
              );
            }}
            name={`connectors.${connectorIndex}.maxPower`}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
