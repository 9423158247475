import { NoData, RefetchButton } from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';

interface NoDetailsProps {
  onRefetch: () => void;
}

export const NoChargingHubDetails: React.FC<NoDetailsProps> = ({
  onRefetch,
}) => {
  const { t } = useTranslation('chargingHubs');

  return (
    <NoData
      title={t('noChargingHubDetailsTitle')}
      subtitle={t('noChargingHubDetailsSubtitle')}
      ActionComponent={<RefetchButton onRefetch={onRefetch} />}
    />
  );
};
