import { ChargingHubModel } from '@energy-stacks/obelis/feature-charging-hubs-data';
import { Box, Typography, useTheme } from '@mui/material';
import { InfoWindowF } from '@react-google-maps/api';
import { IconChargingPile } from '@tabler/icons-react';

interface ChargingHubMapPopupProps {
  chargingHub: ChargingHubModel;
  onClose: () => void;
}

export const ChargingHubMapPopup: React.FC<ChargingHubMapPopupProps> = ({
  chargingHub,
  onClose,
}) => {
  const { spacing } = useTheme();

  return (
    <InfoWindowF
      position={{
        lat: Number(chargingHub.coordinates.latitude),
        lng: Number(chargingHub.coordinates.longitude),
      }}
      options={{ pixelOffset: new google.maps.Size(0, -60) }}
      onCloseClick={onClose}
    >
      <Box sx={{ padding: 0.5 }}>
        <Box
          sx={{
            mb: 1,
            mr: 2,
            maxWidth: spacing(60),
          }}
        >
          <Typography
            variant="caption"
            sx={{ color: 'text.dark', fontWeight: 700 }}
          >
            {chargingHub.name}
          </Typography>
          <Box mt={2} display="flex" alignItems="center">
            <Typography variant="caption">
              {chargingHub.chargingStations}
            </Typography>
            <Typography variant="caption">
              <IconChargingPile />
            </Typography>
          </Box>
        </Box>
      </Box>
    </InfoWindowF>
  );
};
