import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { addEvseValidationScheme } from './addEvseValidationScheme';
import {
  ESButton,
  ESDialogActionButton,
  ESDialogActions,
  ESTextField,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { IconPlus } from '@tabler/icons-react';
import {
  capabilities,
  CapabilityModel,
  capabilitiesToPayload,
  useAddEvseMutation,
  chargingStationsApiErrors,
} from '@energy-stacks/obelis/feature-charging-stations-data';
import { EvseFormConnectorCard } from '../EvseFormConnectorCard';
import { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  ConnectorFormat,
  connectorFormatModelToDtoMap,
  ConnectorPowerType,
  connectorPowerTypeModelToDtoMap,
  ConnectorStandard,
  connectorStandardToDtoMap,
} from '@energy-stacks/data';

export type AddEvseFormData = {
  evseId: string;
  capabilities: CapabilityModel[];
  connectors: {
    connectorStandard: {
      label: string;
      value: ConnectorStandard | undefined;
    };
    connectorFormat: {
      label: string;
      value: ConnectorFormat | undefined;
    };
    powerType: {
      label: string;
      value: ConnectorPowerType | undefined;
    };
    maxPower: string;
    maxVoltage: string;
    maxAmperage: string;
  }[];
};

const connectorDefaultValues = {
  connectorStandard: {
    label: '',
    value: undefined,
  },
  connectorFormat: {
    label: '',
    value: undefined,
  },
  powerType: {
    label: '',
    value: undefined,
  },
  maxPower: '',
  maxVoltage: '',
  maxAmperage: '',
};

const addEvseDefaultValues = {
  evseId: '',
  capabilities: [],
  connectors: [connectorDefaultValues],
};

type AddEvseFormProps = {
  setIsFormDirty: (isFormDirty: boolean) => void;
  closeDialog: () => void;
};

export const AddEvseForm: FC<AddEvseFormProps> = ({
  setIsFormDirty,
  closeDialog,
}) => {
  const [t] = useTranslation('chargingStations');
  const { spacing } = useTheme();
  const { id: chargingStationId } = useParams();
  const { showSnackbar } = useESSnackbar();
  const [addEvse, { isLoading }] = useAddEvseMutation();

  const methods = useForm<AddEvseFormData>({
    defaultValues: addEvseDefaultValues,
    mode: 'onTouched',
    resolver: yupResolver(addEvseValidationScheme),
  });

  const {
    formState: { isDirty, isValid, errors },
    control,
    handleSubmit,
    trigger,
  } = methods;

  const {
    fields: connectors,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'connectors',
  });

  const onSubmit: SubmitHandler<AddEvseFormData> = useCallback(
    (data) => {
      if (!chargingStationId) {
        return;
      }

      addEvse({
        chargingStationId: chargingStationId,
        body: {
          evseId: data.evseId,
          capabilities: capabilitiesToPayload(data.capabilities),
          connectors: data.connectors.map((c) => ({
            connectorStandard:
              connectorStandardToDtoMap[
                c.connectorStandard.value as ConnectorStandard
              ],
            connectorFormat:
              connectorFormatModelToDtoMap[
                c.connectorFormat.value as ConnectorFormat
              ],
            powerType:
              connectorPowerTypeModelToDtoMap[
                c.powerType.value as ConnectorPowerType
              ],
            maxVoltage: Number(c.maxVoltage),
            maxAmperage: Number(c.maxAmperage),
            maxPower: Number(c.maxPower),
          })),
        },
      })
        .unwrap()
        .then(() => {
          showSnackbar('success', 'evseAddedSuccessfully', 'chargingStations');
          closeDialog();
        })
        .catch((error) => {
          const errorCode = error.data?.errorCode;
          showSnackbar(
            'error',
            errorCode
              ? `chargingStationsApiErrors.${chargingStationsApiErrors[errorCode]}`
              : undefined,
            'chargingStations'
          );
        });
    },
    [addEvse, chargingStationId, showSnackbar, closeDialog]
  );

  useEffect(() => {
    setIsFormDirty(isDirty);
  }, [setIsFormDirty, isDirty]);

  return (
    <FormProvider {...methods}>
      <Box sx={{ pb: 31 }}>
        <Stack
          sx={{
            backgroundColor: 'grey.50',
            borderRadius: 3,
            padding: 6,
          }}
        >
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <ESTextField
                type="text"
                required
                label={t('evseFormLabels.evseId')}
                placeholder={t('evseIdPlaceholder')}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={Boolean(errors['evseId'])}
                helperText={
                  Boolean(errors['evseId']) &&
                  t(`evseFormErrors.${errors['evseId']?.message}`, {
                    fieldName: 'EVSE ID',
                  })
                }
                InputProps={{
                  style: {
                    width: 486,
                  },
                }}
              />
            )}
            name="evseId"
          />
          <Grid
            container
            rowSpacing={2}
            marginBottom={6}
            marginTop={4}
            marginX="auto"
            width="100%"
          >
            <Grid item xs={12}>
              <Stack>
                <Typography variant="caption">
                  {t('evseFormLabels.capabilities') + '*'}
                </Typography>
                {errors?.capabilities && (
                  <Typography variant="caption" color="error.main">
                    {t('evseFormErrors.atLeastOneCapability')}
                  </Typography>
                )}
              </Stack>
            </Grid>
            {capabilities.map((option) => (
              <Grid key={option} item xs={6} sm={6} md={4} lg={4} xl={3}>
                <Controller
                  control={control}
                  render={({
                    field: { value: checkedCapabilities, onChange },
                  }) => (
                    <FormControlLabel
                      label={
                        <Typography variant="body1" lineHeight={spacing(5)}>
                          {t(`evseFormLabels.capabilityOptions.${option}`)}
                        </Typography>
                      }
                      control={
                        <Checkbox
                          checked={checkedCapabilities.includes(option)}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            const capabilitiesCount =
                              checkedCapabilities.length;
                            if (isChecked) {
                              onChange([...checkedCapabilities, option]);
                            } else {
                              onChange(
                                checkedCapabilities.filter((c) => c !== option)
                              );
                            }

                            if (
                              (!isChecked && capabilitiesCount === 1) ||
                              (isChecked && capabilitiesCount === 0)
                            ) {
                              trigger('capabilities');
                            }
                          }}
                        />
                      }
                      sx={{
                        height: spacing(6),
                      }}
                    />
                  )}
                  name="capabilities"
                />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={6}>
            {connectors.map((connector, i) => (
              <Grid key={connector.id} item sm={12} md={12} lg={6} xl={4}>
                <EvseFormConnectorCard
                  connectorIndex={i}
                  onRemove={remove}
                  isLast={connectors.length === 1}
                />
              </Grid>
            ))}
          </Grid>
          <ESButton
            variant="outlined"
            onClick={() => append(connectorDefaultValues)}
            startIcon={<IconPlus width={spacing(4)} />}
            sx={{
              mr: 'auto',
              mt: 6,
              '&.MuiButton-outlined': {
                borderColor: 'primary.main',
                borderRadius: 1,
                fontSize: spacing(3),
                height: 34,
                '&:hover': {
                  backgroundColor: 'primary.light',
                  borderColor: 'primary.main',
                },
              },
            }}
          >
            {t('addEvseAddConnectorButton')}
          </ESButton>
        </Stack>
      </Box>
      <ESDialogActions
        sx={{
          backgroundColor: 'background.paper',
          bottom: 0,
          right: 0,
          padding: 10,
          position: 'absolute',
          width: '100%',
          zIndex: 1,
        }}
      >
        <ESDialogActionButton
          variant="contained"
          disabled={!isValid}
          loading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {t('addEvseDialogButton')}
        </ESDialogActionButton>
      </ESDialogActions>
    </FormProvider>
  );
};
