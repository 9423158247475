import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, RadioGroup, Typography } from '@mui/material';
import { SystemReportTypeModel } from '@energy-stacks/obelis/feature-system-reports-data';
import { SystemReportTypeRadioButton } from './SystemReportTypeRadioButton';

interface SystemReportOptionsRadioGroupProps {
  selectedReportType: SystemReportTypeModel | null;
  setSelectedReportType: (value: SystemReportTypeModel) => void;
}

const systemReportRadioGroupOptions = [
  'hotlineRating',
  'nonDiscriminatoryAccess',
] as const;

export const SystemReportOptionsRadioGroup = ({
  selectedReportType,
  setSelectedReportType,
}: SystemReportOptionsRadioGroupProps) => {
  const { t } = useTranslation('systemReports');
  const [selectedValue, setSelectedValue] =
    useState<SystemReportTypeModel | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as SystemReportTypeModel;
    setSelectedReportType(value);
    setSelectedValue(value);
  };

  return (
    <Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: '500',
            mb: 6,
          }}
        >
          {t('chooseOneTypeOfReport')}:
        </Typography>
      </Box>

      <RadioGroup
        aria-label="options"
        name="options"
        value={selectedReportType}
        onChange={handleChange}
        sx={{
          display: 'flex',
          gap: '12px',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        {systemReportRadioGroupOptions.map((option) => (
          <SystemReportTypeRadioButton
            key={option}
            option={option}
            selectedValue={selectedValue ?? null}
          />
        ))}
      </RadioGroup>
    </Grid>
  );
};
