import { appendZToDateString } from '@energy-stacks/shared';
import { ChargingHubModel } from '../chargingHubModel';
import { ChargingHubDto } from '../chargingHubsDto';

export const chargingHubsNormalizer = (
  chargingHub: ChargingHubDto
): ChargingHubModel => {
  return {
    id: chargingHub.chargingHubUid,
    address: chargingHub.address,
    chargingStations: chargingHub.chargingStations,
    city: chargingHub.city,
    coordinates: chargingHub.coordinates,
    lastModifiedDate: appendZToDateString(chargingHub.lastModifiedDate),
    name: chargingHub.name,
  };
};
