import { Box, Typography } from '@mui/material';
import { useChargingStationDetailsTabs } from './useChargingStationDetailsTabs';
import { TabbedScreen } from '@energy-stacks/core/ui';
import { Outlet } from 'react-router-dom';
import { useChargingStationDetails } from './useChargingStationDetails';
import { ChargingStationDetailsGeneralSkeleton } from './ChargingStationDetailsGeneralSkeleton';
import { NotFound, RefetchOnError } from '@energy-stacks/shared';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';

export const ChargingStationDetails = () => {
  const { config } = useChargingStationDetailsTabs();
  const { chargingStationDetails, isLoading, error, refetch } =
    useChargingStationDetails();

  if (isLoading) {
    return <ChargingStationDetailsGeneralSkeleton />;
  }

  if (error) {
    if ('data' in error) {
      if (error.status === 404) {
        return (
          <NotFound
            subtitleText="goToChargingStations"
            buttonText="goToChargingStationsButtonText"
            navigateLink={ObelisRoutes.ChargingStations}
          />
        );
      }
    } else {
      return (
        <Box sx={{ mt: 50 }}>
          <RefetchOnError onRefetch={refetch} />
        </Box>
      );
    }
  }

  return (
    <>
      <Box pt={6} pb={4} px={8}>
        <Typography variant="h3" color="grey.900">
          {chargingStationDetails?.name ?? ''}
        </Typography>
      </Box>
      <TabbedScreen config={config} />
      <Outlet />
    </>
  );
};
