import { Status } from '@energy-stacks/obelis/feature-charging-stations-data';
import { Chip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { statusColorsMap } from './statusColorsMap';

type ChargingStationStatusChipProps = {
  status: Status;
};

export const ChargingStationStatusChip: FC<ChargingStationStatusChipProps> = ({
  status,
}) => {
  const [t] = useTranslation('chargingStations');
  const { bgColor, color } = statusColorsMap[status];

  return (
    <Chip
      label={t(`statuses.${status}`)}
      sx={{
        backgroundColor: bgColor,
        color: color,
      }}
    />
  );
};
