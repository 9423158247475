import { useParams } from 'react-router-dom';
import { useGetRawFacilityDamagesReportQuery } from '@energy-stacks/obelis/feature-charging-hub-reports-data';

export const useFacilityDamagesRawReport = (isEditMode: boolean) => {
  const { id } = useParams<{ id: string }>();

  const { data, isLoading, error, isSuccess, refetch, isError } =
    useGetRawFacilityDamagesReportQuery(
      {
        chargingHubUid: id ?? '',
      },
      {
        skip: isEditMode,
      }
    );

  return {
    data,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  };
};
