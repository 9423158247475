import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconChevronRight, IconX } from '@tabler/icons-react';
import { IconButton, useTheme } from '@mui/material';
import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
  ESFullScreenDialog,
} from '@energy-stacks/core/ui';
import { useCloseDialogPrompt } from '@energy-stacks/shared';
import { ChargingStationReportOptionsRadioGroup } from './renewal-reports/ChargingStationReportOptionsRadioGroup';
import { ProcessedReportTypeModel } from '@energy-stacks/obelis/feature-charging-station-reports-data';
import { ChargingStationDefectReportForm } from './defect-report/ChargingStationDefectReportForm';
import { ChargingStationRenewalReportForm } from './renewal-reports/ChargingStationRenewalReportForm';
import { ChargingStationRatingReportForm } from './rating-reports/ChargingStationRatingReportForm';

type ChargingStationReportStep = {
  stepComponent: ReactNode;
  actionButtonType: 'reset' | 'button' | 'submit' | undefined;
  actionButtonClickHandler?: () => void;
};

export const ChargingStationReportDialog = () => {
  const { t } = useTranslation('chargingStationReports');
  const [activeStep, setActiveStep] = useState(0);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedReportType, setSelectedReportType] =
    useState<ProcessedReportTypeModel>('rating');
  const { palette } = useTheme();
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  const isEditMode = pathname.split('/').pop() === 'edit';

  useEffect(() => {
    if (isEditMode) {
      setActiveStep(1);
      setSelectedReportType(state);
    }
  }, [isEditMode, selectedReportType, state]);

  const showExitConfirmation = useCloseDialogPrompt({
    shouldPrompt: isFormDirty && activeStep !== 0,
    onClose: () => {
      navigate(-1);
      setIsOpen(false);
    },
  });

  const steps: ChargingStationReportStep[] = [
    {
      stepComponent: (
        <ChargingStationReportOptionsRadioGroup
          selectedReportType={selectedReportType}
          setSelectedReportType={setSelectedReportType}
        />
      ),
      actionButtonType: 'button',
      actionButtonClickHandler: () => {
        setActiveStep((prev) => prev + 1);
      },
    },
    {
      stepComponent: (() => {
        switch (selectedReportType) {
          case 'rating':
            return (
              <ChargingStationRatingReportForm
                setIsRatingFormDirty={setIsFormDirty}
                setIsDisabled={setIsDisabled}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                isEditMode={isEditMode}
              />
            );
          case 'renewal':
            return (
              <ChargingStationRenewalReportForm
                setIsRatingFormDirty={setIsFormDirty}
                setIsDisabled={setIsDisabled}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                isEditMode={isEditMode}
              />
            );
          case 'chargingPointDefect':
            return (
              <ChargingStationDefectReportForm
                setIsRatingFormDirty={setIsFormDirty}
                setIsDisabled={setIsDisabled}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                isEditMode={isEditMode}
              />
            );
          default:
            return null;
        }
      })(),
      actionButtonType: 'button',
    },
  ];

  return (
    <ESFullScreenDialog isOpen={isOpen}>
      <ESDialogTitle
        sx={{
          borderBottom: `1px solid ${palette.grey[300]}`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: 4,
          fontWeight: 700,
          lineHeight: '28px',
        }}
        justifyContent="space-between"
      >
        {t(
          `${
            isEditMode
              ? 'editChargingStationReportDialogTitle'
              : 'createChargingStationReportDialogTitle'
          }`
        )}
        <IconButton
          data-testid="createChargingStationReportDialogClose"
          onClick={showExitConfirmation}
          sx={{ padding: 0 }}
          disabled={isDisabled}
        >
          <IconX />
        </IconButton>
      </ESDialogTitle>
      <ESDialogContent sx={{ padding: '40px !important', overflow: 'hidden' }}>
        {steps[activeStep].stepComponent}
      </ESDialogContent>
      <ESDialogActions
        sx={{
          display: activeStep + 1 !== steps.length ? 'flex' : 'none',
        }}
      >
        <ESDialogActionButton
          variant="contained"
          type="button"
          onClick={() => setActiveStep((prev) => prev + 1)}
        >
          {t(`createReportStepActionButtonLabels.${0}`)}
          <IconChevronRight style={{ marginLeft: 12, height: 20 }} />
        </ESDialogActionButton>
      </ESDialogActions>
    </ESFullScreenDialog>
  );
};
