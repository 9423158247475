import {
  ConnectorFormat,
  ConnectorPowerType,
  ConnectorStandard,
} from '@energy-stacks/data';
import { Status } from './chargingStationsModel';

export type ChargingStationDetails = {
  id: string;
  name: string;
  chargingHub: {
    id: string;
    name: string;
  };
  coordinates: {
    latitude: string;
    longitude: string;
  };
  vendorModel: string;
  vendor: string;
  evses: Evse[];
};

export type Evse = {
  chargingPointId?: string;
  evseId: string;
  status: Status | undefined;
  capabilities: CapabilityModel[];
  connectors: ConnectorDetails[];
};

export type ConnectorDetails = {
  id: string;
  standard: ConnectorStandard | undefined;
  powerType: ConnectorPowerType | undefined;
  format: ConnectorFormat | undefined;
  maxVoltage: number | undefined;
  maxAmperage: number | undefined;
  maxPower: number | undefined;
  lastUpdated: string;
};

export const capabilities = [
  'chargingProfileCapable',
  'chargingPreferencesCapable',
  'chipCardSupport',
  'contactlessCardSupport',
  'creditCardPayable',
  'debitCardPayable',
  'pedTerminal',
  'remoteStartStopCapable',
  'reservable',
  'rfidReader',
  'tokenGroupCapable',
  'unlockCapable',
  'startSessionConnectorRequired',
] as const;
export type CapabilityModel = (typeof capabilities)[number];
