import { useParams } from 'react-router-dom';
import { useGetChargingHubDetailsQuery } from '@energy-stacks/obelis/feature-charging-hubs-data';

export const useChargingHubDetails = () => {
  const { id } = useParams<{ id: string }>();

  const {
    data: chargingHubDetails,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  } = useGetChargingHubDetailsQuery(id ?? '');

  return {
    chargingHubDetails,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  };
};
