import { Box, Stack, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EditChargingHubFormData } from './EditChargingHubForm';
import { ChangeEvent } from 'react';
import { handleAuxiliaryFacilityCheckboxChange } from '../shared/handleAuxiliaryFacilityCheckboxChange';
import { ChargingHubAuxiliaryFacilityCheckbox } from '../shared/ChargingHubAuxiliaryFacilityCheckbox';

const locationOptions: EditChargingHubFormData['auxiliaryFacilities'] = [
  'roof',
  'foodService',
  'seating',
  'sanitation',
];

const vehicleSupplies: EditChargingHubFormData['auxiliaryFacilities'] = [
  'airPressure',
  'windshieldWiperSystem',
  'vacuumCleaner',
];

const sustainability: EditChargingHubFormData['auxiliaryFacilities'] = [
  'greenRoof',
  'photovoltaicSystem',
];

export const EditChargingHubAuxiliaryFacilitiesForm = () => {
  const [t] = useTranslation('chargingHubs');
  const { control, getValues, trigger } = useFormContext();
  const { auxiliaryFacilities } = getValues();

  return (
    <Controller
      control={control}
      render={({ field: { onChange } }) => {
        return (
          <>
            <Box mb={6}>
              <Typography mb={2} fontSize={12}>
                {t('location')}
              </Typography>
              <Stack flexDirection="row">
                {locationOptions.map((option) => {
                  return (
                    <ChargingHubAuxiliaryFacilityCheckbox
                      key={option}
                      option={option}
                      checked={auxiliaryFacilities.includes(option)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleAuxiliaryFacilityCheckboxChange(
                          e.target.checked,
                          auxiliaryFacilities,
                          option,
                          onChange
                        );
                        trigger('auxiliaryFacilities');
                      }}
                    />
                  );
                })}
              </Stack>
            </Box>
            <Stack flexDirection="row">
              <Box mr={12}>
                <Typography mb={2} fontSize={12}>
                  {t('vehicleSupplies')}
                </Typography>
                <Stack flexDirection="row">
                  {vehicleSupplies.map((option) => {
                    return (
                      <ChargingHubAuxiliaryFacilityCheckbox
                        key={option}
                        option={option}
                        checked={auxiliaryFacilities.includes(option)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleAuxiliaryFacilityCheckboxChange(
                            e.target.checked,
                            auxiliaryFacilities,
                            option,
                            onChange
                          );
                          trigger('auxiliaryFacilities');
                        }}
                      />
                    );
                  })}
                </Stack>
              </Box>
              <Box>
                <Typography mb={2} fontSize={12}>
                  {t('sustainability')}
                </Typography>
                <Stack flexDirection="row">
                  {sustainability.map((option) => {
                    return (
                      <ChargingHubAuxiliaryFacilityCheckbox
                        key={option}
                        option={option}
                        checked={auxiliaryFacilities.includes(option)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleAuxiliaryFacilityCheckboxChange(
                            e.target.checked,
                            auxiliaryFacilities,
                            option,
                            onChange
                          );
                          trigger('auxiliaryFacilities');
                        }}
                      />
                    );
                  })}
                </Stack>
              </Box>
            </Stack>
          </>
        );
      }}
      name="auxiliaryFacilities"
    />
  );
};
