import { createApi } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { renewalReportNormalizer } from './renewal-reports/normalizers/renewalReportNormalizer';
import { RawRenewalReportDto } from './renewal-reports/rawRenewalReportsDto';
import { CreateRenewalReportRequestPayload } from './renewal-reports/createRenewalReportRequestPayload';
import { EditRenewalReportRequestPayload } from './renewal-reports/editRenewalReportRequestPayload';
import { RenewalReportModel } from './renewal-reports/renewalReportModel';
import { CreateRatingReportRequestPayload } from './rating-reports/createRatingReportRequestPayload';
import { EditRatingReportRequestPayload } from './editRatingReportRequestPayload';
import { RawRatingReportDto } from './rating-reports/rawRatingReportDto';
import { RawRatingReportModel } from './rating-reports/rawRatingReportModel';
import { processedRatingReportNormalizer } from './rating-reports/normalizers/processedRatingReportNormalizer';
import { CreateDefectReportRequestPayload } from './defect-reports/createDefectReportRequestPayload';
import { EditDefectReportRequestPayload } from './defect-reports/editDefectReportRequestPayload';
import { RawDefectReportModel } from './defect-reports/rawDefectReportModel';
import { rawDefectReportNormalizer } from './defect-reports/normalizers/rawDefectReportNormalizer';
import { RawDefectReportDto } from './defect-reports/rawDefectReportDto';

export const chargingStationProcessedReportsApi = createApi({
  reducerPath: 'chargingStationProcessedReportsApi',
  baseQuery: createBaseQuery(
    `${environment.ocppServiceUrl}/processed-reports/charging-stations/charging-points`
  ),
  tagTypes: [
    'processedRenewalReport',
    'processedDefectReport',
    'processedRatingReport',
  ],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    createProcessedRenewalReport: builder.mutation<
      void,
      CreateRenewalReportRequestPayload
    >({
      query: (body) => ({
        url: `/renewal`,
        method: 'POST',
        body,
      }),
    }),
    getProcessedRenewalReportByUid: builder.query<
      RenewalReportModel,
      { uid: string }
    >({
      query: ({ uid }) => ({
        url: `/renewal/${uid}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'processedRenewalReport',
          id: args.uid,
        },
      ],
      transformResponse: (response: RawRenewalReportDto) => {
        return renewalReportNormalizer(response);
      },
    }),
    editProcessedRenewalReport: builder.mutation<
      void,
      { body: EditRenewalReportRequestPayload; uid: string }
    >({
      query: ({ uid, body }) => ({
        url: `/renewal/${uid}`,
        method: 'PUT',
        body,
      }),
    }),
    createProcessedDefectReport: builder.mutation<
      void,
      CreateDefectReportRequestPayload
    >({
      query: (body) => ({
        url: `/defects`,
        method: 'POST',
        body,
      }),
    }),
    editProcessedDefectReport: builder.mutation<
      void,
      { body: EditDefectReportRequestPayload; uid: string }
    >({
      query: ({ uid, body }) => ({
        url: `/defects/${uid}`,
        method: 'PUT',
        body,
      }),
    }),
    getProcessedDefectReportByUid: builder.query<
      RawDefectReportModel,
      { uid: string }
    >({
      query: ({ uid }) => ({
        url: `/defects/${uid}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'processedDefectReport',
          id: args.uid,
        },
      ],
      transformResponse: (response: RawDefectReportDto) => {
        return rawDefectReportNormalizer(response);
      },
    }),
    createProcessedRatingReport: builder.mutation<
      void,
      CreateRatingReportRequestPayload
    >({
      query: (body) => ({
        url: `/ratings`,
        method: 'POST',
        body,
      }),
    }),
    editProcessedRatingReport: builder.mutation<
      void,
      { body: EditRatingReportRequestPayload; uid: string }
    >({
      query: ({ body, uid }) => ({
        url: `/ratings/${uid}`,
        method: 'PUT',
        body,
      }),
    }),
    getProcessedRatingReportByUid: builder.query<
      RawRatingReportModel,
      { uid: string }
    >({
      query: ({ uid }) => ({
        url: `/ratings/${uid}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'processedRatingReport',
          id: args.uid,
        },
      ],
      transformResponse: (response: RawRatingReportDto) => {
        return processedRatingReportNormalizer(response);
      },
    }),
  }),
});

export const {
  useCreateProcessedRenewalReportMutation,
  useGetProcessedRenewalReportByUidQuery,
  useEditProcessedRenewalReportMutation,
  useCreateProcessedRatingReportMutation,
  useEditProcessedRatingReportMutation,
  useGetProcessedRatingReportByUidQuery,
  useCreateProcessedDefectReportMutation,
  useEditProcessedDefectReportMutation,
  useGetProcessedDefectReportByUidQuery,
} = chargingStationProcessedReportsApi;
