import * as yup from 'yup';
import { isAfter, isValid } from 'date-fns';
import { numberValidityTest } from '@energy-stacks/shared';

const additionalCostsValidationSchema = yup.object().shape({
  cost: numberValidityTest.required('additionalCostIsRequired'),
  description: yup.string().required('additionalCostDescriptionIsRequired'),
});

export const chargingHubAdHocTariffReportValidationSchema = yup.object().shape({
  monthYear: yup.date().required('monthIsRequired'),
  cost: numberValidityTest.required('costFieldIsRequired'),
  validFrom: yup
    .date()
    .nullable()
    .typeError('invalidValidFromDate')
    .required('validFromDateFieldIsRequired'),
  validTo: yup.date().when('validFrom', {
    is: (value: Date | null) => isValid(value),
    then: yup
      .date()
      .nullable()
      .typeError('invalidValidToDate')
      .required('validToDateFieldIsRequired')
      .test(
        'isAfterValidFrom',
        'validToDateIsBeforeOrEqualToValidFrom',
        (validTo, { parent: { validFrom } }) => {
          if (!validTo) {
            return true;
          }
          return isAfter(validTo, validFrom);
        }
      ),
    otherwise: yup
      .date()
      .nullable()
      .typeError('invalidValidToDate')
      .required('validToDateFieldIsRequired'),
  }),
  additionalCosts: yup
    .array()
    .of(additionalCostsValidationSchema)
    .notRequired(),
});
