import { ESScreenDrawer } from '@energy-stacks/feature-screen-drawer';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';
import { useNavigate } from 'react-router-dom';
import { ChargingStationDetails } from './ChargingStationDetails';

export const ChargingStationDetailsSlider = () => {
  const navigate = useNavigate();

  return (
    <ESScreenDrawer
      fullScreen
      closeButtonTopPosition={50}
      onClose={() => navigate(ObelisRoutes.ChargingStations)}
    >
      <ChargingStationDetails />
    </ESScreenDrawer>
  );
};
