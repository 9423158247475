import { createApi } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { SystemProcessedReportModel } from './processed-reports/systemProcessedReportModel';
import { SystemProcessedReportsPageEntry } from './processed-reports/systemProcessedReportsPageEntry';
import { systemProcessedReportsNormalizer } from './processed-reports/normalizers/systemProcessedReportsNormalizer';
import {
  processedHotlineRatingReportCreateDataToPayload,
  processedHotlineRatingReportEditDataToPayload,
} from './hotline-rating-reports/processed-reports/processedHotlineRatingReportToPayload';
import { ProcessedHotlineRatingReportModel } from './hotline-rating-reports/processed-reports/processedHotlineRatingReportModel';
import { ProcessedHotlineRatingReportDto } from './hotline-rating-reports/processed-reports/processedHotlineRatingsReportDto';
import { processedHotlineRatingReportNormalizer } from './hotline-rating-reports/processed-reports/normalizers/processedHotlineRatingReportNormalizer';
import {
  processedNonDiscriminatoryAccessReportCreateDataToPayload,
  processedNonDiscriminatoryAccessReportEditDataToPayload,
} from './non-discriminatory-access-reports/processed-reports/processedNonDiscriminatoryAccessReportToPayload';
import { ProcessedNonDiscriminatoryAccessReportModel } from './non-discriminatory-access-reports/processed-reports/processedNonDiscriminatoryAccessReportModel';
import { ProcessedNonDiscriminatoryAccessReportDto } from './non-discriminatory-access-reports/processed-reports/processedNonDiscriminatoryAccessReportDto';
import { processedNonDiscriminatoryAccessReportNormalizer } from './non-discriminatory-access-reports/processed-reports/normalizers/processedNonDiscriminatoryAccessReportNormalizer';
import { GetSystemProcessedReportBody } from './processed-reports/getSystemProcessedReportBody';
import { HotlineReportRatingModel } from './hotline-rating-reports/hotlineReportRatingModel';
import { AdditionalCostsReportModel } from './non-discriminatory-access-reports/additionalCostsReportModel';
import { NonDiscriminatoryAccessReportContractualBasisModel } from './non-discriminatory-access-reports/nonDiscriminatoryAccessReportContractualBasisModel';

export type ProcessedHotlineRatingReportBody = {
  monthYear: Date;
  hotlineCalls: string;
  firstCallResolutionRate: string;
  averageSpeedOfAnswer: string;
  rating: HotlineReportRatingModel;
};

export interface ProcessedNonDiscriminatoryAccessReportBody {
  monthYear: Date;
  providerGroup: string;
  cost: string;
  additionalCosts: AdditionalCostsReportModel[] | undefined;
  validFrom: Date;
  validTo: Date;
  contractualBasis: NonDiscriminatoryAccessReportContractualBasisModel;
  reasoningDifferentCosts: string | undefined;
}

export const systemProcessedReportsApi = createApi({
  reducerPath: 'systemProcessedReportsApi',
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/processed-reports`),
  keepUnusedDataFor: 0,
  tagTypes: [
    'SystemProcessedReports',
    'SystemProcessedReport',
    'SystemProcessedReportsHotlineRatings',
    'SystemProcessedReportsHotlineRating',
    'SystemProcessedReportsNonDiscriminatoryAccess',
    'SystemProcessedReportNonDiscriminatoryAccess',
  ],
  endpoints: (builder) => ({
    getSystemProcessedReports: builder.query<
      SystemProcessedReportModel,
      GetSystemProcessedReportBody
    >({
      query: ({ monthYear, ...rest }) => ({
        url: `/month/${monthYear}`,
        method: 'GET',
        params: {
          monthYear,
          ...rest,
        },
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'SystemProcessedReports',
          id: args.monthYear,
        },
      ],
      transformResponse: (
        systemProcessedReports: SystemProcessedReportsPageEntry
      ) => {
        const { totalElements, totalPages, content } = systemProcessedReports;

        return {
          totalElements: totalElements ?? 0,
          totalPages: totalPages ?? 0,
          content: systemProcessedReportsNormalizer(content ?? []),
        };
      },
    }),
    createProcessedHotlineRatingReport: builder.mutation<
      void,
      ProcessedHotlineRatingReportBody
    >({
      query: (body) => ({
        url: `hotline-ratings`,
        method: 'POST',
        body: processedHotlineRatingReportCreateDataToPayload(body),
      }),
      invalidatesTags: ['SystemProcessedReportsHotlineRatings'],
    }),
    getProcessedHotlineRatingReportByReportUid: builder.query<
      ProcessedHotlineRatingReportModel,
      { uid: string }
    >({
      query: ({ uid }) => ({
        url: `hotline-ratings/${uid}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'SystemProcessedReportsHotlineRatings',
          id: args.uid,
        },
      ],
      transformResponse: (response: ProcessedHotlineRatingReportDto) => {
        return processedHotlineRatingReportNormalizer(response);
      },
    }),
    editProcessedHotlineRatingReport: builder.mutation<
      void,
      { body: ProcessedHotlineRatingReportBody; uid: string }
    >({
      query: ({ body, uid }) => ({
        url: `hotline-ratings/${uid}`,
        method: 'PUT',
        body: processedHotlineRatingReportEditDataToPayload(body),
      }),
      invalidatesTags: (_result, error, args) =>
        !error
          ? [
              'SystemProcessedReportsHotlineRatings',
              {
                type: 'SystemProcessedReportsHotlineRating',
                id: args.uid,
              },
            ]
          : [],
    }),
    createSystemProcessedNonDiscriminatoryAccessReport: builder.mutation<
      void,
      ProcessedNonDiscriminatoryAccessReportBody
    >({
      query: (body) => ({
        url: `nondiscriminatory-access`,
        method: 'POST',
        body: processedNonDiscriminatoryAccessReportCreateDataToPayload(body),
      }),
      invalidatesTags: ['SystemProcessedReportsNonDiscriminatoryAccess'],
    }),
    getSystemProcessedNonDiscriminatoryAccessReportByReportUid: builder.query<
      ProcessedNonDiscriminatoryAccessReportModel,
      { uid: string }
    >({
      query: ({ uid }) => ({
        url: `nondiscriminatory-access/${uid}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'SystemProcessedReportsNonDiscriminatoryAccess',
          id: args.uid,
        },
      ],
      transformResponse: (
        response: ProcessedNonDiscriminatoryAccessReportDto
      ) => {
        return processedNonDiscriminatoryAccessReportNormalizer(response);
      },
    }),
    editSystemProcessedNonDiscriminatoryAccessReport: builder.mutation<
      void,
      { body: ProcessedNonDiscriminatoryAccessReportBody; uid: string }
    >({
      query: ({ body, uid }) => ({
        url: `nondiscriminatory-access/${uid}`,
        method: 'PUT',
        body: processedNonDiscriminatoryAccessReportEditDataToPayload(body),
      }),
      invalidatesTags: (_result, error, args) =>
        !error
          ? [
              'SystemProcessedReportsNonDiscriminatoryAccess',
              {
                type: 'SystemProcessedReportNonDiscriminatoryAccess',
                id: args.uid,
              },
            ]
          : [],
    }),
  }),
});

export const {
  useGetSystemProcessedReportsQuery,
  useCreateProcessedHotlineRatingReportMutation,
  useGetProcessedHotlineRatingReportByReportUidQuery,
  useEditProcessedHotlineRatingReportMutation,
  useCreateSystemProcessedNonDiscriminatoryAccessReportMutation,
  useGetSystemProcessedNonDiscriminatoryAccessReportByReportUidQuery,
  useEditSystemProcessedNonDiscriminatoryAccessReportMutation,
} = systemProcessedReportsApi;
