import { ReportRatingDto } from '../reportRatingDto';
import { ReportRatingModel } from '../reportRatingModel';

const reportRatingDtoToModelMap: Record<ReportRatingDto, ReportRatingModel> = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
} as const;

const reportRatingModelToDtoMap: Record<ReportRatingModel, ReportRatingDto> = {
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  '6': 6,
} as const;

export const convertReportRatingDtoToModel = (
  reportRatingDto: ReportRatingDto
): ReportRatingModel => {
  return reportRatingDtoToModelMap[reportRatingDto];
};

export const convertReportRatingModelToDto = (
  reportRatingDto: ReportRatingModel
): ReportRatingDto => {
  return reportRatingModelToDtoMap[reportRatingDto];
};
