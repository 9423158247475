import { createApi } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { rawRatingReportsNormalizer } from './rating-reports/raw-reports/normalizers/rawRatingReportsNormalizer';
import { RawRatingReportDto } from './rating-reports/raw-reports/rawRatingReportDto';
import { RawRatingReportModel } from './rating-reports/raw-reports/rawRatingReportModel';
import { RawFacilityDamagesReportModel } from './facility-damages-reports/raw-reports/rawFacilityDamagesReportModel';
import { RawFacilityDamagesReportDto } from './facility-damages-reports/raw-reports/rawFacilityDamagesReportDto';
import { rawFacilityDamagesReportsNormalizer } from './facility-damages-reports/raw-reports/normalizers/rawFacilityDamagesReportsNormalizer';
import { AdHocTariffRawReportModel } from './ad-hoc-tariff-reports/raw-reports/adHocTariffRawReportModel';
import { adHocTariffRawReportNormalizer } from './ad-hoc-tariff-reports/raw-reports/normalizers/adHocTariffRawReportNormalizer';
import { AdHocTariffRawReportDto } from './ad-hoc-tariff-reports/raw-reports/adHocTariffRawReportDto';

interface GetRawRatingReportBody {
  chargingHubUid: string;
  monthYear: string;
}

interface GetRawAdHocTariffReportBody {
  chargingHubUid: string;
  monthYear: string;
}

export const chargingHubRawReportsApi = createApi({
  reducerPath: 'rawReportsApi',
  baseQuery: createBaseQuery(
    `${environment.ocppServiceUrl}/raw-reports/charging-hub`
  ),
  keepUnusedDataFor: 0,
  tagTypes: ['RawReport'],
  endpoints: (builder) => ({
    getRawRatingReport: builder.query<
      RawRatingReportModel,
      GetRawRatingReportBody
    >({
      query: ({ chargingHubUid, monthYear }) => ({
        url: `${chargingHubUid}/ratings/${monthYear}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'RawReport',
          id: args.chargingHubUid,
        },
      ],
      transformResponse: (response: RawRatingReportDto) => {
        return rawRatingReportsNormalizer(response);
      },
    }),
    getRawFacilityDamagesReport: builder.query<
      RawFacilityDamagesReportModel,
      { chargingHubUid: string }
    >({
      query: ({ chargingHubUid }) => ({
        url: `${chargingHubUid}/facility-damages/latest`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'RawReport',
          id: args.chargingHubUid,
        },
      ],
      transformResponse: (response: RawFacilityDamagesReportDto) => {
        return rawFacilityDamagesReportsNormalizer(response);
      },
    }),
    getRawAdHocTariffReport: builder.query<
      AdHocTariffRawReportModel,
      GetRawAdHocTariffReportBody
    >({
      query: ({ chargingHubUid, monthYear }) => ({
        url: `${chargingHubUid}/ad-hoc-tariff/${monthYear}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'RawReport',
          id: args.chargingHubUid,
        },
      ],
      transformResponse: (response: AdHocTariffRawReportDto) => {
        return adHocTariffRawReportNormalizer(response);
      },
    }),
  }),
});

export const {
  useGetRawRatingReportQuery,
  useGetRawFacilityDamagesReportQuery,
  useGetRawAdHocTariffReportQuery,
} = chargingHubRawReportsApi;
