import { ESDialogTitle, showSnackbar } from '@energy-stacks/core/ui';
import { useCloseDialogPrompt } from '@energy-stacks/shared';
import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { resetStepper, useAppSelector } from '@energy-stacks/store';
import { editChargingHubSteps } from './editChargingHubSteps';
import {
  EditChargingHubForm,
  EditChargingHubFormData,
} from './EditChargingHubForm';
import { useLocation, useNavigate } from 'react-router-dom';
import { useChargingHubDetails } from '../details/useChargingHubDetails';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';

export const EditChargingHub = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation('chargingHubs');
  const activeStep = useAppSelector((state) => state.stepper.stepperActiveStep);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { chargingHubDetails, isError, isSuccess } = useChargingHubDetails();

  useEffect(() => {
    return () => {
      dispatch(resetStepper());
    };
  }, [dispatch]);

  const defaultValues: EditChargingHubFormData | undefined = useMemo(() => {
    return chargingHubDetails && isSuccess
      ? {
          id: chargingHubDetails.id,
          name: chargingHubDetails.name,
          address: chargingHubDetails.address,
          postalCode: chargingHubDetails.postalCode,
          city: chargingHubDetails.city,
          state: {
            label: chargingHubDetails.state,
            value: chargingHubDetails.state,
          },
          coordinates: {
            longitude: chargingHubDetails.coordinates.longitude,
            latitude: chargingHubDetails.coordinates.latitude,
          },
          auxiliaryFacilities: chargingHubDetails.auxiliaryFacilities,
        }
      : undefined;
  }, [chargingHubDetails, isSuccess]);

  const closeDialog = useCallback(() => {
    if (location.state?.from) {
      navigate(-1);
    } else {
      navigate(ObelisRoutes.ChargingHubs);
    }
  }, [location.state, navigate]);

  const handleCloseDialog = useCloseDialogPrompt({
    shouldPrompt: isFormDirty,
    onClose: closeDialog,
  });

  useEffect(() => {
    if (isError) {
      showSnackbar('error', 'chargingHubDetailsFetchingError', 'chargingHubs');
      closeDialog();
    }
  }, [isError, closeDialog]);

  return (
    <Dialog open onClose={handleCloseDialog} maxWidth="lg" fullWidth>
      <ESDialogTitle
        data-testid="editChargingHubDialogTitle"
        sx={{
          borderBottom: `1px solid ${palette.grey[300]}`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: 4,
        }}
        justifyContent="space-between"
      >
        {t('editChargingHub')}
        <IconButton
          data-testid="editStationCloseModalButton"
          onClick={handleCloseDialog}
        >
          <IconX />
        </IconButton>
      </ESDialogTitle>
      {defaultValues && isSuccess ? (
        <EditChargingHubForm
          display={editChargingHubSteps[activeStep].name}
          closeDialog={closeDialog}
          activeStep={activeStep}
          onFormDirty={setIsFormDirty}
          defaultValues={defaultValues}
        />
      ) : (
        <Box
          height={565}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={50} />
        </Box>
      )}
    </Dialog>
  );
};
