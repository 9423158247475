export const processedReportsApiErrors: Record<string, string> = {
  MONTH_ON_REPORT_NOT_VALID: 'monthOnReportNotValid',
  RATING_REPORT_ALREADY_EXISTS: 'ratingReportAlreadyExists',
  REPORT_EMERGENCE_DATE_IS_LATER_THAN_FORECAST_DATE:
    'reportEmergenceDateIsLaterThanForecastDate',
  REPORT_EMERGENCE_DATE_IS_LATER_THAN_FIX_DATE:
    'reportEmergenceDateIsLaterThanFixDate',
  CHARGING_HUB_DOES_NOT_EXIST: 'chargingHubDoesNotExist',
  PROCESSED_FACILITY_DAMAGES_UID_NUMBER_NOT_FOUND:
    'processedFacilityDamagesUidNumberNotFound',
  VALID_TO_DATE_IS_BEFORE_OR_EQUAL_TO_VALID_FROM:
    'validToDateIsBeforeOrEqualToValidFrom',
  RAW_AD_HOC_TARIFF_UID_NUMBER_NOT_FOUND: 'rawAdHocTariffUidNumberNotFound',
  AD_HOC_TARIFF_REPORT_ALREADY_EXISTS: 'adHocTariffReportAlreadyExists',
  PROCESSED_RATING_UID_NOT_FOUND: 'processedRatingUidNotFound',
  PROCESSED_AD_HOC_TARIFF_UID_NUMBER_NOT_FOUND:
    'processedAdHocTariffUidNumberNotFound',
  FACILITY_DAMAGES_REPORT_ALREADY_EXISTS: 'facilityDamagesReportAlreadyExists',
  OBELIS_EXTERNAL_API_REQUEST_FAILED_429: 'awsRateLimitExceeded',
};
