import { AuxiliaryFacilityModel } from '@energy-stacks/obelis/shared';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface ChargingHubAuxiliaryFacilityCheckboxProps {
  option: AuxiliaryFacilityModel;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}

export const ChargingHubAuxiliaryFacilityCheckbox: React.FC<
  ChargingHubAuxiliaryFacilityCheckboxProps
> = ({ option, onChange, checked }) => {
  const [t] = useTranslation('chargingHubs');

  return (
    <Box
      sx={{
        backgroundColor: checked ? 'primary.light' : 'transparent',
        pr: 2,
        py: 2,
        pl: 4,
        borderRadius: (theme) => theme.spacing(3),
        mr: 3,
        border: '1px solid',
        borderColor: checked ? 'primary.main' : 'gray.300',
      }}
    >
      <FormControlLabel
        value={option}
        label={t(option)}
        control={<Checkbox checked={checked} onChange={onChange} />}
      />
    </Box>
  );
};
