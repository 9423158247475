import { ProcessedHotlineRatingReportDto } from '../processedHotlineRatingsReportDto';
import { ProcessedHotlineRatingReportModel } from '../processedHotlineRatingReportModel';
import { hotlineReportRatingDtoToModelMap } from '../../hotlineReportRatingDtoToModelMap';

export const processedHotlineRatingReportNormalizer = (
  processedHotlineRatingReportDto: ProcessedHotlineRatingReportDto
): ProcessedHotlineRatingReportModel => {
  const {
    uid,
    monthYear,
    hotlineCalls,
    firstCallResolutionRate,
    averageSpeedOfAnswer,
    rating,
  } = processedHotlineRatingReportDto;

  return {
    uid: uid ?? '',
    monthYear: monthYear,
    hotlineCalls: String(hotlineCalls),
    firstCallResolutionRate: String(firstCallResolutionRate * 100),
    averageSpeedOfAnswer: String(averageSpeedOfAnswer),
    rating: hotlineReportRatingDtoToModelMap[rating],
  };
};
