import { ESTextField } from './ESTextField';
import { DateTimePicker } from '@mui/x-date-pickers';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import { PickersActionBarAction } from '@mui/x-date-pickers/PickersActionBar/PickersActionBar';
type ESDateTimePickerProps = {
  label?: string;
  value: Date | null;
  minDateTime?: Date;
  maxDateTime?: Date;
  onBlur: () => void;
  onChange: (e: Date | null) => void;
  onClose?: () => void;
  error?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  actions?: PickersActionBarAction[];
};

export const ESDateTimePicker: React.FC<ESDateTimePickerProps> = ({
  label,
  value,
  minDateTime,
  maxDateTime,
  onBlur,
  onChange,
  onClose,
  error,
  helperText,
  disabled,
  required,
  actions = [],
}) => {
  return (
    <DateTimePicker
      inputFormat="dd/MM/yyyy HH:mm"
      label={label || ''}
      ampm={false}
      value={value}
      minDateTime={minDateTime}
      maxDateTime={maxDateTime}
      components={{
        OpenPickerIcon: DateRangeRoundedIcon,
      }}
      componentsProps={{
        actionBar: {
          actions,
        },
      }}
      onClose={onClose}
      onChange={(e) => {
        onChange(e);
        onBlur();
      }}
      disabled={disabled}
      renderInput={(props) => (
        <ESTextField
          {...props}
          onChange={(e) => {
            if (!e || !props.onChange) {
              return;
            }
            props.onChange(e);
          }}
          value={props.value as string | undefined}
          error={error}
          onBlur={onBlur}
          helperText={helperText}
          required={required}
        />
      )}
    />
  );
};
