import { GreyCheckboxIcon } from '@energy-stacks/obelis/shared';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const AddChargingHubOverview = () => {
  const [t] = useTranslation('chargingHubs');
  const { getValues } = useFormContext();

  const values = getValues();

  return (
    <Box display="flex" justifyContent="center">
      <Paper
        sx={() => ({
          backgroundColor: 'grey.50',
          px: 14,
          pt: 6,
        })}
      >
        <Grid
          spacing={6}
          container
          data-testid="addChargingHubOverviewContainer"
          minWidth={600}
        >
          <Grid item xs={12} sm={7}>
            <OverviewBox
              text={t('id')}
              formValue={values['id']}
              testId="chargingSiteOverViewBox"
            />
            <OverviewBox
              text={t('name')}
              formValue={values['name']}
              testId="chargingSiteOverViewBox"
            />
            <OverviewBox
              text={t('streetAndNumber')}
              formValue={values['address']}
              testId="chargingSiteOverViewBox"
            />
            <OverviewBox
              text={t('postalCode')}
              formValue={values['postalCode']}
              testId="chargingSiteOverViewBox"
            />
            <OverviewBox
              text={t('city')}
              formValue={values['city']}
              testId="chargingSiteOverViewBox"
            />
            <OverviewBox
              text={t('state')}
              formValue={values['state'].label}
              testId="chargingSiteOverViewBox"
            />
            <OverviewBox
              text={t('country')}
              formValue={t('germany')}
              testId="chargingSiteOverViewBox"
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <AuxiliaryOverviewBox
              text={t('location')}
              formValue={values['locations']}
              testId="chargingSiteOverViewBox"
              icon={values['locations'].length ? <GreyCheckboxIcon /> : null}
            />
            <AuxiliaryOverviewBox
              text={t('vehicleSupplies')}
              formValue={values['vehicleSupplies']}
              testId="chargingSiteOverViewBox"
              icon={
                values['vehicleSupplies'].length ? <GreyCheckboxIcon /> : null
              }
            />
            <AuxiliaryOverviewBox
              text={t('sustainability')}
              formValue={values['sustainability']}
              testId="chargingSiteOverViewBox"
              icon={
                values['sustainability'].length ? <GreyCheckboxIcon /> : null
              }
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

interface OverviewBoxProps {
  text: string;
  formValue: string;
  testId?: string;
}

const OverviewBox: React.FC<OverviewBoxProps> = ({
  text,
  formValue,
  testId,
}) => {
  return (
    <Box mb={6} data-testid={testId}>
      <Typography mb={1} variant="body1" color="grey.500">
        {text}
      </Typography>
      <Typography variant="subtitle1">{formValue}</Typography>
    </Box>
  );
};

interface AuxiliaryOverviewBoxProps {
  text: string;
  formValue: string[];
  testId?: string;
  icon?: ReactElement | null;
}

const AuxiliaryOverviewBox: React.FC<AuxiliaryOverviewBoxProps> = ({
  text,
  formValue,
  testId,
  icon,
}) => {
  const [t] = useTranslation('chargingHubs');
  const [tShared] = useTranslation('shared');

  return (
    <Box mb={6} data-testid={testId}>
      <Typography mb={1} variant="body1" color="grey.500">
        {text}
      </Typography>
      {formValue.length ? (
        formValue.map((value) => {
          return (
            <Box display="flex" alignItems="center" key={value}>
              {icon ? (
                <Typography
                  fontSize={12}
                  color="gray[500]"
                  mr={2}
                  fontWeight={400}
                >
                  {icon}
                </Typography>
              ) : null}
              <Typography
                fontSize={14}
                color="gray[600]"
                fontWeight={500}
                noWrap
              >
                {t(value)}
              </Typography>
            </Box>
          );
        })
      ) : (
        <Typography fontSize={14} color="gray[600]" fontWeight={500} noWrap>
          {tShared('notApplicable')}
        </Typography>
      )}
    </Box>
  );
};
