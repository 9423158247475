import { environment } from '@energy-stacks/feature-config';
import { Shell } from './Shell';
import { createRoot } from 'react-dom/client';

if (environment.type === 'local') {
  window.onerror = (event, source, lineno, colno, err) => {
    // must be within function call because that's when the element is defined for sure.
    const ErrorOverlay = customElements.get('vite-error-overlay');
    // don't open outside vite environment
    if (!ErrorOverlay) {
      return;
    }
    const overlay = new ErrorOverlay(err);
    document.body.appendChild(overlay);
  };
}

if (environment.type !== 'local') {
  // After hard reload service worker gets unregistered, so we need to reload the page again to get it registered.
  // For reference: https://github.com/AxaFrance/react-oidc/issues/861#issuecomment-1249359476
  navigator.serviceWorker?.getRegistration()?.then(function (reg) {
    // There's an active SW, but no controller for this tab.
    if (reg) {
      if (reg.active && !navigator.serviceWorker.controller) {
        // Perform a soft reload to load everything from the SW and get
        // a consistent set of resources.
        window.location.reload();
      }
    }
  });
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<Shell />);
