import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Box, Button } from '@mui/material';
import { AdditionalCost } from './AdditionalCost';
import { ProcessedNonDiscriminatoryAccessReportFormData } from './SystemNonDiscriminatoryAccessReportForm';
import { useEffect, useRef, useState } from 'react';

interface AdditionalCostsProps {
  isDisabled: boolean;
}

export const AdditionalCosts: React.FC<AdditionalCostsProps> = ({
  isDisabled,
}) => {
  const { t } = useTranslation('systemReports');
  const defaultAdditionalCost = {
    description: '',
    cost: '',
    isNew: true,
  };

  const { control } =
    useFormContext<ProcessedNonDiscriminatoryAccessReportFormData>();

  const {
    fields: additionalCosts,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'additionalCosts',
  });

  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);

  const addCostButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (shouldScrollToBottom && addCostButtonRef?.current) {
      addCostButtonRef.current.scrollIntoView({ behavior: 'smooth' });
      setShouldScrollToBottom(false);
    }
  }, [shouldScrollToBottom]);

  return (
    <Box>
      <Box
        sx={{
          position: 'static',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {additionalCosts.map((additionalCost, index) => (
          <AdditionalCost
            key={additionalCost.id}
            additionalCostIndex={index}
            isDisabled={isDisabled}
            onRemove={remove}
            additionalCost={additionalCost}
          />
        ))}
      </Box>

      <Button
        ref={addCostButtonRef}
        disabled={isDisabled}
        sx={{
          ml: 4,
          my: 4,
        }}
        onClick={() => {
          append(defaultAdditionalCost);
          setShouldScrollToBottom(true);
        }}
        variant="text"
        startIcon={<AddRoundedIcon />}
      >
        {t('addCostButton')}
      </Button>
    </Box>
  );
};
