import * as yup from 'yup';
import { numberValidityTest } from '@energy-stacks/shared';

export const systemHotlineRatingsReportValidationSchema = yup.object().shape({
  monthYear: yup.date().required('monthIsRequired'),
  hotlineCalls: numberValidityTest.required('hotlineCallsIsRequired'),
  firstCallResolutionRate: yup
    .string()
    .required('firstCallResolutionRateIsRequired')
    .matches(
      // this regex matches an number between 0 and 100, allowing for up to two decimal places
      /^(100(\.0{1,2})?|[0-9]{1,2}(\.[0-9]{0,2})?)?$/,
      'firstCallResolutionRateMustBeANumberBetween0And100WithMost2DecimalPlaces'
    )
    .test(
      'is-valid-number',
      'firstCallResolutionRateMustBeANumberBetween0And100WithMost2DecimalPlaces',
      (value) => {
        if (value === '' || value === '0' || value === null) return true;
        const numValue = parseFloat(value ?? '');
        return numValue >= 0 && numValue <= 100;
      }
    ),
  averageSpeedOfAnswer: numberValidityTest.required(
    'averageSpeedOfAnswerIsRequired'
  ),
  rating: yup
    .number()
    .required('ratingIsRequired')
    .typeError('ratingMustBeANumber'),
});
