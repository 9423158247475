import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, RadioGroup, Typography } from '@mui/material';
import { ProcessedReportTypeModel } from '@energy-stacks/obelis/feature-charging-hub-reports-data';
import { ChargingHubReportTypeRadioButton } from './ChargingHubReportTypeRadioButton';

interface ChargingHubReportOptionsRadioGroupProps {
  selectedReportType: ProcessedReportTypeModel | null;
  setSelectedReportType: (value: ProcessedReportTypeModel) => void;
}

const chargingHubReportRadioGroupOptions = [
  'adHocTariff',
  'facilityDamages',
  'rating',
] as const;

export const ChargingHubReportOptionsRadioGroup = ({
  selectedReportType,
  setSelectedReportType,
}: ChargingHubReportOptionsRadioGroupProps) => {
  const { t } = useTranslation('chargingHubReports');
  const [selectedValue, setSelectedValue] =
    useState<ProcessedReportTypeModel | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as ProcessedReportTypeModel;
    setSelectedReportType(value);
    setSelectedValue(value);
  };

  return (
    <Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: '500',
            mb: 6,
          }}
        >
          {t('chooseOneTypeOfReport')}:
        </Typography>
      </Box>

      <RadioGroup
        aria-label="options"
        name="options"
        value={selectedReportType}
        onChange={handleChange}
        sx={{
          display: 'flex',
          gap: '12px',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        {chargingHubReportRadioGroupOptions.map((option) => (
          <ChargingHubReportTypeRadioButton
            key={option}
            option={option}
            selectedValue={selectedValue ?? null}
          />
        ))}
      </RadioGroup>
    </Grid>
  );
};
