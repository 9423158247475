import { Grid } from '@mui/material';
import { ChargingHubDetailsGeneralCard } from './ChargingHubDetailsGeneralCard';
import { ChargingHubDetailsMap } from './ChargingHubDetailsMap';
import { useAppSelector } from '@energy-stacks/store';

export const ChargingHubDetailsGeneral = () => {
  const isSidebarOpened = useAppSelector(
    (state) => state.sidebar.sidebarOpened
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={isSidebarOpened ? 12 : 5} xl={5}>
        <ChargingHubDetailsGeneralCard />
      </Grid>
      <Grid item xs={12} lg={isSidebarOpened ? 12 : 7} xl={7}>
        <ChargingHubDetailsMap />
      </Grid>
    </Grid>
  );
};
