import { CdrDimensionTypeDto } from '../dtos/cdrDimensionTypeDto';
import { ChargingPeriodDto } from '../dtos/chargingPeriodDto';
import { CdrDimensionTypeModel } from '../models/cdrDimensionTypeModel';
import { ChargingPeriodModel } from '../models/chargingPeriodModel';

export const chargingPeriodNormalizer = (
  chargingPeriod: ChargingPeriodDto
): ChargingPeriodModel => {
  return {
    startDate: chargingPeriod.start,
    dimensions: chargingPeriod.dimensions.map((dimension) => {
      return {
        type: cdrDimensionTypeDtoToModelMap[dimension.type],
        value: dimension.value,
      };
    }),
  };
};

export const cdrDimensionTypeDtoToModelMap: Record<
  CdrDimensionTypeDto,
  CdrDimensionTypeModel
> = {
  CURRENT: 'current',
  ENERGY: 'energy',
  ENERGY_EXPORT: 'energyExport',
  ENERGY_IMPORT: 'energyImport',
  MAX_CURRENT: 'maxCurrent',
  MIN_CURRENT: 'minCurrent',
  MAX_POWER: 'maxPower',
  MIN_POWER: 'minPower',
  PARKING_TIME: 'parkingTime',
  POWER: 'power',
} as const;
