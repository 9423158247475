import { hotlineReportRatingDtoToModelMap } from '../../hotlineReportRatingDtoToModelMap';
import { RawHotlineRatingReportDto } from '../rawHotlineRatingReportDto';
import { RawHotlineRatingReportModel } from '../rawHotlineRatingReportModel';

export const rawHotlineRatingReportNormalizer = (
  rawRatingReportDto: RawHotlineRatingReportDto
): RawHotlineRatingReportModel => {
  const {
    uid,
    monthYear,
    hotlineCalls,
    firstCallResolutionRate,
    averageSpeedOfAnswer,
    rating,
  } = rawRatingReportDto;

  return {
    uid: uid ?? '',
    monthYear: monthYear,
    hotlineCalls: String(hotlineCalls),
    firstCallResolutionRate: String(firstCallResolutionRate * 100),
    averageSpeedOfAnswer: String(averageSpeedOfAnswer),
    rating: hotlineReportRatingDtoToModelMap[rating],
  };
};
