import { useTranslation } from 'react-i18next';
import { IconProgressBolt } from '@tabler/icons-react';
import { SidebarGroup } from '@energy-stacks/core/sidebar';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';

export const ChargingSessionsDrawerItem = () => {
  const { t } = useTranslation('chargingSessions');
  const itemLabel = t('sidebarItem');

  return (
    <SidebarGroup
      testId="chargingHubsSidebarButton"
      groupInitialPath={ObelisRoutes.ChargingSessions}
      groupIcon={<IconProgressBolt />}
      groupLabel={itemLabel}
    ></SidebarGroup>
  );
};
