import { CreateProcessedHotlineRatingReportPayload } from './createProcessedHotlineRatingReportPayload';
import { EditProcessedHotlineRatingReportPayload } from './editProcessedHotlineRatingReportPayload';
import { hotlineReportRatingModelToDtoMap } from '../hotlineReportRatingModelToDtoMap';
import { ProcessedHotlineRatingReportBody } from '../../systemProcessedReportsApi';
import { formatMonthYearToPayload } from '@energy-stacks/shared';

export const processedHotlineRatingReportCreateDataToPayload = (
  hotlineRating: ProcessedHotlineRatingReportBody
): CreateProcessedHotlineRatingReportPayload => {
  const {
    monthYear,
    hotlineCalls,
    firstCallResolutionRate,
    averageSpeedOfAnswer,
    rating,
  } = hotlineRating;

  return {
    monthYear: formatMonthYearToPayload(monthYear),
    hotlineCalls: Number(hotlineCalls),
    firstCallResolutionRate: Number(
      (Number(firstCallResolutionRate) / 100).toFixed(4)
    ),
    averageSpeedOfAnswer: Number(averageSpeedOfAnswer),
    rating: hotlineReportRatingModelToDtoMap[rating],
  };
};

export const processedHotlineRatingReportEditDataToPayload = (
  hotlineRating: ProcessedHotlineRatingReportBody
): EditProcessedHotlineRatingReportPayload => {
  const {
    monthYear,
    hotlineCalls,
    firstCallResolutionRate,
    averageSpeedOfAnswer,
    rating,
  } = hotlineRating;

  return {
    monthYear: formatMonthYearToPayload(monthYear),
    hotlineCalls: Number(hotlineCalls),
    firstCallResolutionRate: Number(
      (Number(firstCallResolutionRate) / 100).toFixed(4)
    ),
    averageSpeedOfAnswer: Number(averageSpeedOfAnswer),
    rating: hotlineReportRatingModelToDtoMap[rating],
  };
};
