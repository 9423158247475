import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { TabbedScreen, useESSnackbar } from '@energy-stacks/core/ui';
import { ErrorApiResponse, NotFound } from '@energy-stacks/shared';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';
import { chargingHubsApiErrors } from '@energy-stacks/obelis/feature-charging-hubs-data';
import { ChargingHubDetailsGeneralSkeleton } from './ChargingHubDetailsGeneralSkeleton';
import { useChargingHubDetailsTabs } from './useChargingHubDetailsTabs';
import { useChargingHubDetails } from './useChargingHubDetails';

export const ChargingHubDetails = () => {
  const { chargingHubDetails, error, isLoading } = useChargingHubDetails();
  const { config } = useChargingHubDetailsTabs();
  const { showSnackbar } = useESSnackbar();

  useEffect(() => {
    if (error) {
      if ('data' in error) {
        const response = error.data as ErrorApiResponse;
        response
          ? showSnackbar(
              'error',
              chargingHubsApiErrors[response.errorCode],
              'chargingHubs'
            )
          : showSnackbar('error');
      } else {
        showSnackbar('error');
      }
    }
  }, [error, showSnackbar]);

  if (isLoading) {
    return <ChargingHubDetailsGeneralSkeleton />;
  }

  return (
    <>
      {error ? (
        <NotFound
          subtitleText="goToChargingHubs"
          buttonText="goToChargingHubsButtonText"
          navigateLink={ObelisRoutes.ChargingHubs}
        />
      ) : (
        <>
          <Box pt={6} pb={4} px={8}>
            <Typography variant="h3" color="grey.900">
              {chargingHubDetails?.name ?? ''}
            </Typography>
          </Box>
          <TabbedScreen config={config} />
          <Outlet />
        </>
      )}
    </>
  );
};
