import { NoData, RefetchButton } from '@energy-stacks/shared';
import { useTranslation } from 'react-i18next';

interface NoDetailsProps {
  onRefetch: () => void;
}

export const NoChargingStationDetails: React.FC<NoDetailsProps> = ({
  onRefetch,
}) => {
  const { t } = useTranslation('chargingStations');

  return (
    <NoData
      title={t('noChargingStationDetailsTitle')}
      subtitle={t('noChargingStationDetailsSubtitle')}
      ActionComponent={<RefetchButton onRefetch={onRefetch} />}
    />
  );
};
