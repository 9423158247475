import { ESDialogContent } from '@energy-stacks/core/ui';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddChargingStationForm } from './AddChargingStationForm';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCloseDialogPrompt } from '@energy-stacks/shared';

export const AddChargingStation = () => {
  const [t] = useTranslation('chargingStations');
  const navigate = useNavigate();
  const location = useLocation();
  const [isFormDirty, setIsFormDirty] = useState(false);

  const closeDialog = useCallback(() => {
    if (location.state?.from) {
      navigate(-1);
    } else {
      navigate(ObelisRoutes.ChargingStations);
    }
  }, [location.state, navigate]);

  const handleCloseDialog = useCloseDialogPrompt({
    shouldPrompt: isFormDirty,
    onClose: closeDialog,
  });

  return (
    <Dialog open onClose={handleCloseDialog} maxWidth="lg" fullWidth>
      <Box
        sx={{
          alignItems: 'center',
          borderBottomColor: 'grey.200',
          borderBottomStyle: 'solid',
          borderBottomWidth: 0.25,
          display: 'flex',
          justifyContent: 'space-between',
          padding: 10,
          pb: 4,
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: 700 }}>
          {t('addChargingStationDialogTitle')}
        </Typography>
        <IconButton
          data-testid="addStationCloseModalButton"
          onClick={handleCloseDialog}
        >
          <IconX />
        </IconButton>
      </Box>
      <ESDialogContent>
        <AddChargingStationForm
          onFormDirty={setIsFormDirty}
          closeDialog={closeDialog}
        />
      </ESDialogContent>
    </Dialog>
  );
};
