import { useGetChargingStationDetailsQuery } from '@energy-stacks/obelis/feature-charging-stations-data';
import { useParams } from 'react-router-dom';

export const useChargingStationDetails = () => {
  const { id } = useParams<{ id: string }>();

  const {
    data: chargingStationDetails,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  } = useGetChargingStationDetailsQuery(id ?? '');

  return {
    chargingStationDetails,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  };
};
