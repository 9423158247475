import { appendZToDateString } from '@energy-stacks/shared';
import {
  ChargingStationDto,
  ConnectorDto,
  EvseStatusDto,
} from '../chargingStationsDto';
import { connectorStandardDtoToTypeMap } from '@energy-stacks/data';
import { ChargingStation, Connector } from '../chargingStationsModel';

export const chargingStationsNormalizer = (
  chargingStationsDto: ChargingStationDto[]
): ChargingStation[] => {
  return chargingStationsDto.map((chargingStationDto) => ({
    id: chargingStationDto.identityKey,
    name: chargingStationDto.name,
    model: chargingStationDto.model,
    lastUpdated: appendZToDateString(chargingStationDto.lastUpdated),
    status: calculateChargingStationStatus(
      (chargingStationDto.evse ?? []).map((evse) => evse.status)
    ),
    evses: (chargingStationDto.evse ?? []).map((evse) => {
      return {
        id: evse.evseId,
        connectors: connectorsNormalizer(evse?.connectors ?? [], evse.status),
      };
    }),
  }));
};

const calculateChargingStationStatus = (
  evseStatuses: Array<EvseStatusDto | undefined>
) => {
  if (evseStatuses.includes('AVAILABLE')) {
    return 'AVAILABLE' as const;
  }
  if (evseStatuses.includes('CHARGING')) {
    return 'CHARGING' as const;
  }
  if (evseStatuses.includes('RESERVED')) {
    return 'RESERVED' as const;
  }
  if (evseStatuses.includes('BLOCKED')) {
    return 'BLOCKED' as const;
  }
  if (evseStatuses.includes('INOPERATIVE')) {
    return 'INOPERATIVE' as const;
  }
  if (evseStatuses.includes('OUTOFORDER')) {
    return 'OUTOFORDER' as const;
  }
  if (evseStatuses.includes('PLANNED')) {
    return 'PLANNED' as const;
  }
  if (evseStatuses.includes('REMOVED')) {
    return 'REMOVED' as const;
  }
  if (evseStatuses.includes('UNKNOWN')) {
    return 'UNKNOWN' as const;
  }
  return undefined;
};

const connectorsNormalizer = (
  connectorDtos: ConnectorDto[],
  evseStatus: EvseStatusDto | undefined
): Connector[] => {
  return connectorDtos.map((c) => {
    return {
      id: c.connectorUid ?? '',
      status: evseStatus,
      type: c.standard ? connectorStandardDtoToTypeMap[c.standard] : undefined,
    };
  });
};
