import { useTranslation } from 'react-i18next';
import { IconBolt } from '@tabler/icons-react';
import { SidebarGroup } from '@energy-stacks/core/sidebar';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';

export const ChargingHubsDrawerItem = () => {
  const { t } = useTranslation('chargingHubs');
  const itemLabel = t('sidebarItem');

  return (
    <SidebarGroup
      testId="chargingHubsSidebarButton"
      groupInitialPath={ObelisRoutes.ChargingHubs}
      groupIcon={<IconBolt />}
      groupLabel={itemLabel}
    ></SidebarGroup>
  );
};
