import { useParams } from 'react-router-dom';
import { useGetSystemProcessedNonDiscriminatoryAccessReportByReportUidQuery } from '@energy-stacks/obelis/feature-system-reports-data';

export const useSystemNonDiscriminatoryAccessProcessedReport = (
  isEditMode: boolean
) => {
  const { uid } = useParams<{ uid: string }>();

  const { data, isLoading, error, isSuccess, refetch, isError } =
    useGetSystemProcessedNonDiscriminatoryAccessReportByReportUidQuery(
      {
        uid: uid ?? '',
      },
      {
        skip: !isEditMode,
      }
    );

  return {
    data,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  };
};
