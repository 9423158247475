import { useGetRawRatingReportsQuery } from '@energy-stacks/obelis/feature-charging-station-reports-data';
import { useParams } from 'react-router-dom';

export const useRawRatingReports = (isEditMode: boolean) => {
  const { id, selectedMonth } = useParams<{
    id: string;
    selectedMonth: string;
  }>();

  const {
    data: rawReports,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  } = useGetRawRatingReportsQuery(
    {
      identityKey: id || '',
      monthYear: selectedMonth ?? '',
    },
    {
      skip: isEditMode,
    }
  );

  return {
    rawReports,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  };
};
