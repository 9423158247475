import { RawRatingReportDto } from '../rawRatingReportDto';
import { RawRatingReportModel } from '../rawRatingReportModel';
import { convertReportRatingDtoToModel } from './reportRatingNormalizer';

export const processedRatingReportNormalizer = (
  report: RawRatingReportDto
): RawRatingReportModel => {
  const {
    chargingHubUid,
    chargingPointUid,
    evseId,
    monthYear,
    rating,
    totalComplaints,
    uid,
  } = report;

  return {
    chargingHubUid,
    chargingPointUid: chargingPointUid || null,
    evseId: evseId || null,
    monthYear,
    rating: convertReportRatingDtoToModel(rating),
    totalComplaints: totalComplaints ? totalComplaints.toString() : null,
    uid: uid || null,
  };
};
