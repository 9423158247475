import { RefetchOnError } from '@energy-stacks/shared';
import { Grid, useTheme } from '@mui/material';
import { NoChargingStationDetails } from './NoChargingStationDetails';
import { ChargingStationDetailsGeneralCard } from './ChargingStationDetailsGeneralCard';
import { ChargingStationDetailsEvseCard } from './ChargingStationDetailsEvseCard';
import { useChargingStationDetails } from './useChargingStationDetails';
import { ESButton } from '@energy-stacks/core/ui';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { AddEvse } from './add-evse/AddEvse';
import { IconPlus } from '@tabler/icons-react';
import { useAppSelector } from '@energy-stacks/store';

export const ChargingStationDetailsGeneralTab = () => {
  const [t] = useTranslation('chargingStations');
  const { spacing } = useTheme();
  const { chargingStationDetails, isError, isSuccess, refetch } =
    useChargingStationDetails();
  const [isAddEvseDialogOpen, setIsAddEvseDialogOpen] = useState(false);

  const isSidebarOpened = useAppSelector(
    (state) => state.sidebar.sidebarOpened
  );

  return (
    <>
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {!chargingStationDetails && isSuccess ? (
        <NoChargingStationDetails onRefetch={refetch} />
      ) : null}
      {chargingStationDetails ? (
        <Grid container spacing={5}>
          <Grid item sm={12} md={12} lg={isSidebarOpened ? 12 : 5} xl={5}>
            <ChargingStationDetailsGeneralCard
              chargingStationDetails={chargingStationDetails}
            />
          </Grid>
          <Grid
            item
            sm={12}
            md={12}
            lg={isSidebarOpened ? 12 : 7}
            xl={7}
            sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}
          >
            {chargingStationDetails?.evses.map((evse) => {
              return (
                <ChargingStationDetailsEvseCard key={evse.evseId} evse={evse} />
              );
            })}
            <ESButton
              variant="outlined"
              onClick={() => setIsAddEvseDialogOpen(true)}
              startIcon={
                <IconPlus
                  width={spacing(4)}
                  stroke={2.5}
                  style={{ marginRight: 3 }}
                />
              }
              sx={{
                mr: 'auto',
                '&.MuiButton-outlined': {
                  borderColor: 'primary.main',
                  borderRadius: 1,
                  px: 4,
                  py: 2,
                  '&:hover': {
                    backgroundColor: 'primary.light',
                    borderColor: 'primary.main',
                  },
                },
              }}
            >
              {t('addEvseButton')}
            </ESButton>
            <AddEvse
              isOpen={isAddEvseDialogOpen}
              onClose={() => setIsAddEvseDialogOpen(false)}
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
