import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { Coords } from './map/coords';
import { MapMarkerWrapper } from './map/MapMarkerWrapper';

export type BusinessAccountLocationType = 'degassed' | 'raw';

interface TankMarkerProps extends Coords {
  tankType: BusinessAccountLocationType;
  onClick: () => void;
  style?: React.CSSProperties;
}

export const TankMarker: React.FC<TankMarkerProps> = ({
  tankType,
  onClick,
  style,
}) => {
  const { palette } = useTheme();

  const colors = useMemo(
    () => ({
      raw: palette.cyan[600],
      degassed: palette.warning[800],
    }),
    [palette]
  );

  return (
    <MapMarkerWrapper onClick={onClick} style={style}>
      <svg
        width="24"
        height="28"
        viewBox="0 0 24 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="POI">
          <path
            id="Ellipse 1"
            d="M12 26L11.5146 26.5717C11.7945 26.8094 12.2055 26.8094 12.4854 26.5717L12 26ZM12 26C12.4854 26.5717 12.4856 26.5716 12.4857 26.5715L12.4863 26.571L12.488 26.5695L12.4941 26.5643L12.5171 26.5447C12.537 26.5276 12.5661 26.5025 12.6038 26.4698C12.6791 26.4045 12.7888 26.3086 12.9278 26.185C13.2059 25.9379 13.6019 25.5799 14.0765 25.1332C15.0248 24.2408 16.2915 22.9906 17.5606 21.5629C18.827 20.1381 20.1107 18.52 21.0816 16.8919C22.0423 15.2811 22.75 13.5738 22.75 11.9909C22.75 6.05825 17.9364 1.25 12 1.25C6.06358 1.25 1.25 6.05825 1.25 11.9909C1.25 13.5738 1.95774 15.2811 2.91835 16.8919C3.88934 18.52 5.17296 20.1381 6.43944 21.5629C7.7085 22.9906 8.97523 24.2408 9.9235 25.1332C10.3981 25.5799 10.7941 25.9379 11.0722 26.185C11.2112 26.3086 11.3209 26.4045 11.3962 26.4698C11.4339 26.5025 11.463 26.5276 11.4829 26.5447L11.5059 26.5643L11.512 26.5695L11.5137 26.571L11.5143 26.5715C11.5144 26.5716 11.5146 26.5717 12 26Z"
            fill={colors[tankType]}
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            id="Ellipse 215"
            cx="12"
            cy="11.8906"
            r="3"
            fill="white"
            stroke={palette.background.paper}
          />
        </g>
      </svg>
    </MapMarkerWrapper>
  );
};
