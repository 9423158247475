import { ConnectorStandardDto } from '../dtos/connectorStandardDto';
import { ConnectorStandard } from '../models/connectorStandardModel';

export const connectorStandardToDtoMap: Record<
  ConnectorStandard,
  ConnectorStandardDto
> = {
  CHADEMO: ConnectorStandardDto.CHADEMO,
  DOMESTIC_F: ConnectorStandardDto.DOMESTIC_F,
  IEC_62196_T1: ConnectorStandardDto.IEC_62196_T1,
  IEC_62196_T1_COMBO: ConnectorStandardDto.IEC_62196_T1_COMBO,
  IEC_62196_T2: ConnectorStandardDto.IEC_62196_T2,
  IEC_62196_T2_COMBO: ConnectorStandardDto.IEC_62196_T2_COMBO,
  IEC_62196_T3C: ConnectorStandardDto.IEC_62196_T3C,
  UNKNOWN: ConnectorStandardDto.UNKNOWN,
};
