export const chargingStationsApiErrors: Record<string, string> = {
  CHARGING_STATION_HAS_ASSOCIATED_STATIONS:
    'chargingStationHasAssociatedChargingPoints',
  CHARGING_HUB_DOES_NOT_EXIST: 'chargingHubDoesNotExist',
  CHARGING_STATION_IDENTITY_KEY_ALREADY_EXISTS:
    'chargingStationIdentityKeyAlreadyExists',
  EVSE_ID_ALREADY_EXISTS: 'evseIdAlreadyExists',
  CHARGING_STATION_WITH_IDENTITY_KEY_WAS_NOT_FOUND: 'chargingStationNotFound',
  DUPLICATED_CONNECTOR_UIDS: 'duplicatedConnectorUids',
  CHARGING_POINT_FOR_CHARGING_STATION_DOES_NOT_EXISTS:
    'chargingPointForChargingStationDoesNotExist',
  CONNECTORS_SIZE_DO_NOT_MATCH: 'connectorSizeDoNotMatch',
  CHARGING_HUB_FOR_CHARGING_STATION_DOES_NOT_EXISTS:
    'chargingHubForChargingStationDoesNotExist',
  CANT_CHANGE_CHARGING_HUB_OF_AN_ALREADY_CREATED_STATION:
    'cantChangeChargingHubOfAnAlreadyCreatedStation',
  OBELIS_EXTERNAL_API_REQUEST_FAILED_429: 'awsRateLimitExceeded',
};
