import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import {
  ESVirtualizedAutocomplete,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import {
  processedReportsApiErrors,
  ReportRatingModel,
  reportRatingModel,
  useCreateProcessedRatingReportMutation,
  useEditProcessedRatingReportMutation,
} from '@energy-stacks/obelis/feature-charging-hub-reports-data';
import { chargingHubsApi } from '@energy-stacks/obelis/feature-charging-hubs-data';
import { useAppDispatch } from '@energy-stacks/store';
import { ErrorApiResponse } from '@energy-stacks/shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRatingsRawReport } from './useRatingsRawReport';
import { useProcessedRatingReport } from './useProcessedRatingReport';
import { chargingHubRatingReportValidationSchema } from './chargingHubRatingReportValidationSchema';
import { ChargingHubReportContainer } from '../ChargingHubReportContainer';
import {
  getReportDefaultDate,
  MonthYearPicker,
} from '@energy-stacks/obelis/shared';
import { endOfDay, lastDayOfMonth, subMonths } from 'date-fns';

type CreateProcessedRatingReportFormData = {
  monthYear: Date;
  rating: ReportRatingModel;
};

const lastMonth = endOfDay(lastDayOfMonth(subMonths(new Date(), 1)));

export const ChargingHubRatingReportForm = ({
  setIsRatingFormDirty,
  setIsDisabled,
  activeStep,
  setActiveStep,
  isEditMode,
}: {
  setIsRatingFormDirty: (isRatingFormDirty: boolean) => void;
  setIsDisabled: (isDisabled: boolean) => void;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isEditMode: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('chargingHubReports');
  const [createProcessedRatingReport, { isLoading: isCreating }] =
    useCreateProcessedRatingReportMutation();
  const [editProcessedRatingReport, { isLoading: isEditing }] =
    useEditProcessedRatingReportMutation();
  const {
    data: rawReportData,
    isLoading: isRatingsRawReportLoading,
    error: rawDataError,
  } = useRatingsRawReport(isEditMode);
  const {
    data: processedReportData,
    isLoading: isProcessedRatingReportLoading,
    error: processedDataError,
  } = useProcessedRatingReport(isEditMode);
  const { uid: reportUid, id: chargingHubUid, selectedMonth } = useParams();
  const dispatch = useAppDispatch();
  const { showSnackbar } = useESSnackbar();

  const reportData = isEditMode ? processedReportData : rawReportData;

  const defaultRatingValues = useMemo(() => {
    return {
      monthYear: getReportDefaultDate(
        reportData?.monthYear,
        selectedMonth,
        lastMonth
      ),
      rating: reportData?.rating,
    };
  }, [reportData, selectedMonth]);

  const formMethods = useForm<CreateProcessedRatingReportFormData>({
    defaultValues: defaultRatingValues,
    mode: 'onTouched',
    resolver: yupResolver(chargingHubRatingReportValidationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { isValid: isFormValid, isDirty: isFormDirty, errors },
    reset: resetForm,
  } = formMethods;

  const handleClose = useCallback(() => {
    resetForm();
    navigate(-1);
  }, [navigate, resetForm]);

  const isDisabled =
    isProcessedRatingReportLoading ||
    isRatingsRawReportLoading ||
    isEditing ||
    isCreating;

  const submitChargingHubRatingReportForm: SubmitHandler<CreateProcessedRatingReportFormData> =
    useCallback(
      (data) => {
        if ((!isEditMode && !chargingHubUid) || (isEditMode && !reportUid)) {
          return;
        }
        const { monthYear, rating } = data;
        const successMessage = isEditMode
          ? 'updateChargingHubReportRatingSuccess'
          : 'createChargingHubReportRatingSuccess';

        const reportHandler = isEditMode
          ? editProcessedRatingReport({
              uid: reportUid as string,
              body: {
                monthYear,
                rating,
              },
            })
          : createProcessedRatingReport({
              chargingHubUid: chargingHubUid as string,
              monthYear,
              rating,
            });

        reportHandler
          .unwrap()
          .then(() => {
            showSnackbar('success', successMessage, 'chargingHubReports');
            dispatch(
              chargingHubsApi.util.invalidateTags(['ChargingHubReports'])
            );
            handleClose();
          })
          .catch((error) => {
            showSnackbar(
              'error',
              processedReportsApiErrors[error.data?.errorCode],
              'chargingHubReports'
            );
          });
      },
      [
        chargingHubUid,
        createProcessedRatingReport,
        editProcessedRatingReport,
        handleClose,
        isEditMode,
        reportUid,
        dispatch,
        showSnackbar,
      ]
    );

  useEffect(() => {
    setIsRatingFormDirty && setIsRatingFormDirty(isFormDirty);
    setIsDisabled(isDisabled);
  }, [isFormDirty, isDisabled, setIsRatingFormDirty, setIsDisabled]);

  useEffect(() => {
    resetForm(defaultRatingValues);
  }, [resetForm, defaultRatingValues]);

  useEffect(() => {
    if (rawDataError) {
      if ('status' in rawDataError && rawDataError.status === 404) {
        return;
      } else {
        showSnackbar('error');
      }
    }
  }, [rawDataError, showSnackbar]);

  useEffect(() => {
    if (processedDataError) {
      if ('data' in processedDataError) {
        const response = processedDataError.data as ErrorApiResponse;
        response
          ? showSnackbar(
              'error',
              processedReportsApiErrors[response.errorCode],
              'chargingHubReports'
            )
          : showSnackbar('error');
      } else {
        showSnackbar('error');
      }
      handleClose();
    }
  }, [processedDataError, showSnackbar, handleClose]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(submitChargingHubRatingReportForm)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 124px)',
          justifyContent: 'space-between',
          overflow: 'hidden',
        }}
      >
        <ChargingHubReportContainer
          title={t('rating')}
          isFormValid={isFormValid}
          isFormDirty={isFormDirty}
          isLoading={isEditing || isCreating}
          activeStep={activeStep ?? 1}
          setActiveStep={setActiveStep}
          isEditMode={isEditMode}
        >
          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('month') + ' *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.monthYear ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="monthYear"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <MonthYearPicker
                      value={value}
                      onChange={onChange}
                      maxDate={lastMonth}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
              marginBottom: 4,
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('rating') + ' *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.rating ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="rating"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESVirtualizedAutocomplete
                      label=""
                      placeholder={reportData?.rating ?? '-'}
                      disabled={isDisabled}
                      value={{
                        label: value ?? '',
                        value: value ?? '',
                      }}
                      options={reportRatingModel.map((curr) => ({
                        label: curr,
                        value: curr,
                      }))}
                      noOptionsText={t(
                        'createChargingHubRatingReportNoOptions'
                      )}
                      onBlur={onBlur}
                      blurOnSelect
                      onChange={(_, value) => {
                        if (Array.isArray(value)) {
                          return;
                        }
                        if (value) {
                          onChange(value.value ?? '');
                        }
                      }}
                      error={Boolean(errors['rating'])}
                      helperText={
                        Boolean(errors['rating']) &&
                        t(`${errors['rating']?.message}`)
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </ChargingHubReportContainer>
      </form>
    </FormProvider>
  );
};
