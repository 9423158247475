import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import {
  ESTextField,
  ESVirtualizedAutocomplete,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { useAppDispatch } from '@energy-stacks/store';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  HotlineReportRatingModel,
  hotlineReportRatingModel,
  systemProcessedReportsApi,
  systemProcessedReportsApiErrors,
  useCreateProcessedHotlineRatingReportMutation,
  useEditProcessedHotlineRatingReportMutation,
} from '@energy-stacks/obelis/feature-system-reports-data';
import { ErrorApiResponse } from '@energy-stacks/shared';
import { useSystemHotlineRatingRawReport } from './useSystemHotlineRatingRawReport';
import { useSystemHotlineRatingProcessedReport } from './useSystemHotlineRatingProcessedReport';
import { systemHotlineRatingsReportValidationSchema } from './systemHotlineRatingReportValidationSchema';
import { SystemReportContainer } from '../SystemReportContainer';
import {
  getReportDefaultDate,
  MonthYearPicker,
} from '@energy-stacks/obelis/shared';
import { endOfDay, lastDayOfMonth, subMonths } from 'date-fns';

type ProcessedHotlineRatingReportFormData = {
  monthYear: Date;
  hotlineCalls: string;
  firstCallResolutionRate: string;
  averageSpeedOfAnswer: string;
  rating: HotlineReportRatingModel;
};

const lastMonth = endOfDay(lastDayOfMonth(subMonths(new Date(), 1)));

export const SystemHotlineRatingReportForm = ({
  setIsHotlineRatingFormDirty,
  setIsDisabled,
  activeStep,
  setActiveStep,
  isEditMode,
}: {
  setIsHotlineRatingFormDirty: (isHotlineRatingFormDirty: boolean) => void;
  setIsDisabled: (isDisabled: boolean) => void;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isEditMode: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('systemReports');
  const [createProcessedHotlineRatingsReport, { isLoading: isCreating }] =
    useCreateProcessedHotlineRatingReportMutation();
  const [editProcessedHotlineRatingsReport, { isLoading: isEditing }] =
    useEditProcessedHotlineRatingReportMutation();
  const {
    data: rawReportData,
    isLoading: isRatingsRawReportLoading,
    error: rawReportDataError,
  } = useSystemHotlineRatingRawReport(isEditMode);
  const {
    data: processedReportData,
    isLoading: isProcessedRatingReportLoading,
    error: processedReportDataError,
  } = useSystemHotlineRatingProcessedReport(isEditMode);
  const { uid: reportUid, selectedMonth } = useParams();
  const dispatch = useAppDispatch();
  const { showSnackbar } = useESSnackbar();

  const reportData = isEditMode ? processedReportData : rawReportData;

  const defaultHotlineRatingsValues = useMemo(() => {
    return {
      monthYear: getReportDefaultDate(
        reportData?.monthYear,
        selectedMonth,
        lastMonth
      ),
      hotlineCalls: reportData?.hotlineCalls ?? '',
      firstCallResolutionRate: reportData?.firstCallResolutionRate ?? '',
      averageSpeedOfAnswer: reportData?.averageSpeedOfAnswer ?? '',
      rating: reportData?.rating,
    };
  }, [reportData, selectedMonth]);

  const formMethods = useForm<ProcessedHotlineRatingReportFormData>({
    defaultValues: defaultHotlineRatingsValues,
    mode: 'onTouched',
    resolver: yupResolver(systemHotlineRatingsReportValidationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { isValid: isFormValid, isDirty: isFormDirty, errors },
    reset: resetForm,
  } = formMethods;

  const handleClose = useCallback(() => {
    resetForm();
    navigate(-1);
  }, [navigate, resetForm]);

  const isDisabled =
    isProcessedRatingReportLoading ||
    isRatingsRawReportLoading ||
    isEditing ||
    isCreating;

  const submitChargingHubRatingReportForm: SubmitHandler<ProcessedHotlineRatingReportFormData> =
    useCallback(
      (data) => {
        const {
          monthYear,
          hotlineCalls,
          firstCallResolutionRate,
          averageSpeedOfAnswer,
          rating,
        } = data;
        const successMessage = isEditMode
          ? 'updateSystemHotlineRatingsReportSuccess'
          : 'createSystemHotlineRatingsReportSuccess';

        const reportHandler = isEditMode
          ? editProcessedHotlineRatingsReport({
              uid: reportUid ?? '',
              body: {
                monthYear,
                hotlineCalls,
                firstCallResolutionRate,
                averageSpeedOfAnswer,
                rating,
              },
            })
          : createProcessedHotlineRatingsReport({
              monthYear,
              hotlineCalls,
              firstCallResolutionRate,
              averageSpeedOfAnswer,
              rating,
            });

        reportHandler
          .unwrap()
          .then(() => {
            showSnackbar('success', successMessage, 'systemReports');
            dispatch(
              systemProcessedReportsApi.util.invalidateTags([
                'SystemProcessedReports',
              ])
            );
            handleClose();
          })
          .catch((error) => {
            showSnackbar(
              'error',
              systemProcessedReportsApiErrors[error.data?.errorCode],
              'systemReports'
            );
          });
      },
      [
        createProcessedHotlineRatingsReport,
        editProcessedHotlineRatingsReport,
        handleClose,
        isEditMode,
        reportUid,
        dispatch,
        showSnackbar,
      ]
    );

  useEffect(() => {
    setIsHotlineRatingFormDirty && setIsHotlineRatingFormDirty(isFormDirty);
    setIsDisabled(isDisabled);
  }, [isFormDirty, isDisabled, setIsHotlineRatingFormDirty, setIsDisabled]);

  useEffect(() => {
    resetForm(defaultHotlineRatingsValues);
  }, [resetForm, defaultHotlineRatingsValues]);

  useEffect(() => {
    if (rawReportDataError) {
      if ('status' in rawReportDataError && rawReportDataError.status === 404) {
        return;
      } else {
        showSnackbar('error');
      }
    }
  }, [rawReportDataError, showSnackbar]);

  useEffect(() => {
    if (processedReportDataError) {
      if ('data' in processedReportDataError) {
        const response = processedReportDataError.data as ErrorApiResponse;
        response
          ? showSnackbar(
              'error',
              systemProcessedReportsApiErrors[response.errorCode],
              'systemReports'
            )
          : showSnackbar('error');
      } else {
        showSnackbar('error');
      }
      handleClose();
    }
  }, [processedReportDataError, showSnackbar, handleClose]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(submitChargingHubRatingReportForm)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 124px)',
          justifyContent: 'space-between',
          overflow: 'hidden',
        }}
      >
        <SystemReportContainer
          title={t('hotlineRating')}
          isFormValid={isFormValid}
          isFormDirty={isFormDirty}
          isLoading={isEditing || isCreating}
          activeStep={activeStep ?? 1}
          setActiveStep={setActiveStep}
          isEditMode={isEditMode}
        >
          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('month') + ' *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.monthYear ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="monthYear"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <MonthYearPicker
                      value={value}
                      onChange={onChange}
                      maxDate={lastMonth}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
              marginBottom: 4,
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('amountOfHotlineCalls') + ' *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.hotlineCalls ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="hotlineCalls"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESTextField
                      value={value}
                      onChange={(event) => {
                        const inputValue = event?.target.value;
                        if (
                          inputValue === '' ||
                          // this regex matches a string that consists entirely of one or more digits, with no other characters before or after
                          /^\d+$/.test(inputValue ?? '')
                        ) {
                          onChange(inputValue);
                        }
                      }}
                      onBlur={onBlur}
                      error={Boolean(errors['hotlineCalls'])}
                      helperText={
                        Boolean(errors['hotlineCalls']) &&
                        t(`${errors['hotlineCalls']?.message}`)
                      }
                      disabled={isDisabled}
                      placeholder={reportData?.hotlineCalls ?? '-'}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
              marginBottom: 4,
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('firstCallResolutionRate') + ' [%] *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.firstCallResolutionRate ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="firstCallResolutionRate"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESTextField
                      value={value}
                      onChange={(event) => {
                        const newValue = event?.target.value || '';
                        if (
                          newValue === '' ||
                          // this regex matches an number between 0 and 100, allowing for up to two decimal places
                          /^(100(\.0{1,2})?|[0-9]{1,2}(\.[0-9]{0,2})?)?$/.test(
                            newValue
                          )
                        ) {
                          onChange(newValue);
                        }
                      }}
                      onBlur={onBlur}
                      error={Boolean(errors['firstCallResolutionRate'])}
                      helperText={
                        Boolean(errors['firstCallResolutionRate']) &&
                        t(`${errors['firstCallResolutionRate']?.message}`)
                      }
                      disabled={isDisabled}
                      placeholder={reportData?.firstCallResolutionRate ?? '-'}
                      inputProps={{
                        step: '0.01',
                        min: '0',
                        max: '100',
                      }}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
              marginBottom: 4,
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('averageSpeedOfAnswerInSeconds') + ' [s] *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.averageSpeedOfAnswer ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="averageSpeedOfAnswer"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESTextField
                      value={value}
                      type="number"
                      onChange={onChange}
                      onBlur={onBlur}
                      error={Boolean(errors['averageSpeedOfAnswer'])}
                      helperText={
                        Boolean(errors['averageSpeedOfAnswer']) &&
                        t(`${errors['averageSpeedOfAnswer']?.message}`)
                      }
                      disabled={isDisabled}
                      placeholder={reportData?.averageSpeedOfAnswer ?? '-'}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              padding: 4,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
              marginBottom: 4,
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('rating') + ' *'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.rating ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="rating"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESVirtualizedAutocomplete
                      label=""
                      placeholder={reportData?.rating ?? '-'}
                      disabled={isDisabled}
                      value={{
                        label: value ?? '',
                        value: value ?? '',
                      }}
                      options={hotlineReportRatingModel.map((curr) => ({
                        label: curr,
                        value: curr,
                      }))}
                      noOptionsText={t(
                        'createSystemHotlineRatingsReportNoOptions'
                      )}
                      onBlur={onBlur}
                      blurOnSelect
                      onChange={(_, value) => {
                        if (Array.isArray(value)) {
                          return;
                        }
                        if (value) {
                          onChange(value.value ?? '');
                        }
                      }}
                      error={Boolean(errors['rating'])}
                      helperText={
                        Boolean(errors['rating']) &&
                        t(`${errors['rating']?.message}`)
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </SystemReportContainer>
      </form>
    </FormProvider>
  );
};
