import { FacilityTypeDto } from './facilityTypeDto';
import { FacilityTypeModel } from './facilityTypeModel';

export const reportFacilityTypeDtoToModelMap: Record<
  FacilityTypeDto,
  FacilityTypeModel
> = {
  ROOF: 'roof',
  FOOD_SERVICE: 'foodService',
  SEATING: 'seating',
  SANITATION: 'sanitation',
  VEHICLE_SUPPLIES_AIR_PRESSURE: 'airPressure',
  VEHICLE_SUPPLIES_WINDSHIELD_WIPER_SYSTEM: 'windshieldWiperSystem',
  VEHICLE_SUPPLIES_VACUUM_CLEANER: 'vacuumCleaner',
  GREEN_ROOF: 'greenRoof',
  PHOTOVOLTAIC_SYSTEM: 'photovoltaicSystem',
} as const;
