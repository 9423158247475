import { DEFAULT_PAGINATION_CONFIG } from '@energy-stacks/core/ui';
import {
  ReasonModel,
  reasonsModelToDtoMap,
  TariffTypeModel,
  tariffTypesModelToDtoMap,
  useGetChargingSessionsQuery,
} from '@energy-stacks/obelis/feature-charging-sessions-data';
import { toPayloadDate } from '@energy-stacks/shared';

interface GetChargingSessionsConfig {
  search: string;
  filterDate: {
    startDateTime: Date | undefined;
    endDateTime: Date | undefined;
  };
  reason: ReasonModel | undefined;
  tariffType: TariffTypeModel | undefined;
  pagination: {
    page: number;
    size: number;
  };
}

export const useChargingSessions = (config?: GetChargingSessionsConfig) => {
  const {
    data: chargingSessionsResponse,
    isLoading,
    refetch,
    isError,
  } = useGetChargingSessionsQuery({
    searchValue: config?.search ? config.search : undefined,
    startDateTime: config?.filterDate.startDateTime
      ? toPayloadDate(config?.filterDate.startDateTime)
      : undefined,
    endDateTime: config?.filterDate.endDateTime
      ? toPayloadDate(config?.filterDate.endDateTime)
      : undefined,
    reason: config?.reason ? reasonsModelToDtoMap[config.reason] : undefined,
    tariffType: config?.tariffType
      ? tariffTypesModelToDtoMap[config.tariffType]
      : undefined,
    pageNumber: config?.pagination?.page ?? DEFAULT_PAGINATION_CONFIG.page,
    pageSize: config?.pagination?.size ?? DEFAULT_PAGINATION_CONFIG.size,
  });

  return {
    chargingSessionsResponse,
    isLoading,
    refetch,
    isError,
  };
};
