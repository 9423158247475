import { useParams } from 'react-router-dom';
import { useGetSystemNonDiscriminatoryAccessRawReportQuery } from '@energy-stacks/obelis/feature-system-reports-data';

export const useSystemNonDiscriminatoryAccessRawReport = (
  isEditMode: boolean
) => {
  const { selectedMonth } = useParams<{ selectedMonth: string }>();

  const { data, isLoading, error, isSuccess, refetch, isError } =
    useGetSystemNonDiscriminatoryAccessRawReportQuery(
      {
        monthYear: selectedMonth ?? '',
      },
      {
        skip: isEditMode,
      }
    );

  return {
    data,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  };
};
