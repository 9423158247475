import { Box, Stack, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddChargingHubFormData } from './AddChargingHubForm';
import { ChangeEvent } from 'react';
import { handleAuxiliaryFacilityCheckboxChange } from '../shared/handleAuxiliaryFacilityCheckboxChange';
import { ChargingHubAuxiliaryFacilityCheckbox } from '../shared/ChargingHubAuxiliaryFacilityCheckbox';

const locationOptions: AddChargingHubFormData['locations'] = [
  'roof',
  'foodService',
  'seating',
  'sanitation',
];

const vehicleSupplies: AddChargingHubFormData['vehicleSupplies'] = [
  'airPressure',
  'windshieldWiperSystem',
  'vacuumCleaner',
];

const sustainability: AddChargingHubFormData['sustainability'] = [
  'greenRoof',
  'photovoltaicSystem',
];

export const AddChargingHubAuxiliaryFacilitiesForm = () => {
  const [t] = useTranslation('chargingHubs');
  const { control } = useFormContext();

  return (
    <>
      <Box mb={6}>
        <Typography mb={2} fontSize={12}>
          {t('location')}
        </Typography>
        <Stack flexDirection="row">
          {locationOptions.map((option) => {
            return (
              <Controller
                key={option}
                control={control}
                render={({ field: { onChange, value: locations } }) => {
                  return (
                    <ChargingHubAuxiliaryFacilityCheckbox
                      option={option}
                      checked={locations.includes(option)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleAuxiliaryFacilityCheckboxChange(
                          e.target.checked,
                          locations,
                          option,
                          onChange
                        );
                      }}
                    />
                  );
                }}
                name="locations"
              />
            );
          })}
        </Stack>
      </Box>
      <Stack flexDirection="row">
        <Box mr={12}>
          <Typography mb={2} fontSize={12}>
            {t('vehicleSupplies')}
          </Typography>
          <Stack flexDirection="row">
            {vehicleSupplies.map((option) => {
              return (
                <Controller
                  key={option}
                  control={control}
                  render={({ field: { onChange, value: vehicleSupplies } }) => {
                    return (
                      <ChargingHubAuxiliaryFacilityCheckbox
                        option={option}
                        checked={vehicleSupplies.includes(option)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleAuxiliaryFacilityCheckboxChange(
                            e.target.checked,
                            vehicleSupplies,
                            option,
                            onChange
                          );
                        }}
                      />
                    );
                  }}
                  name="vehicleSupplies"
                />
              );
            })}
          </Stack>
        </Box>
        <Box>
          <Typography mb={2} fontSize={12}>
            {t('sustainability')}
          </Typography>
          <Stack flexDirection="row">
            {sustainability.map((option) => {
              return (
                <Controller
                  key={option}
                  control={control}
                  render={({ field: { onChange, value: sustainability } }) => {
                    return (
                      <ChargingHubAuxiliaryFacilityCheckbox
                        option={option}
                        checked={sustainability.includes(option)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleAuxiliaryFacilityCheckboxChange(
                            e.target.checked,
                            sustainability,
                            option,
                            onChange
                          );
                        }}
                      />
                    );
                  }}
                  name="sustainability"
                />
              );
            })}
          </Stack>
        </Box>
      </Stack>
    </>
  );
};
