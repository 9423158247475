import { FormStepperModel } from '@energy-stacks/form-stepper';

export const addChargingHubSteps: AddChargingHubSteps = {
  0: { name: 'generalDetails', optional: false },
  1: { name: 'addAuxiliaryFacilities', optional: true },
  2: { name: 'overview', optional: false },
};

export type AddChargingHubFormStepName =
  | 'generalDetails'
  | 'addAuxiliaryFacilities'
  | 'overview';

export type AddChargingHubSteps = FormStepperModel<AddChargingHubFormStepName>;
