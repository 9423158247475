import { convertReportRatingDtoToModel } from '../../normalizers/reportRatingNormalizer';
import { RawRatingReportDto } from '../rawRatingReportDto';
import { RawRatingReportModel } from '../rawRatingReportModel';

export const rawRatingReportsNormalizer = (
  rawRatingReportDto: RawRatingReportDto
): RawRatingReportModel => {
  const { uid, monthYear, chargingHubUid, rating } = rawRatingReportDto;

  return {
    uid,
    monthYear,
    chargingHubUid,
    rating: convertReportRatingDtoToModel(rating),
  };
};
