import * as translationsEn from './lib/translations.en.json';
import * as translationsDe from './lib/translations.de.json';
import { ReactComponent as PigIcon } from './lib/icons/pig.svg';

export { translationsDe, translationsEn };
export { PigIcon };
export * from './lib/FleetRoutes';
export * from './lib/Stepper';
export * from './lib/types/healthStatus';
export * from './lib/types/product';
export * from './lib/types/geolocation';
export * from './lib/types/supplier';
export * from './lib/types/geolocation';
export * from './lib/types/geolocationDto';
export * from './lib/types/routePoint';
export * from './lib/types/productDto';
export * from './lib/types/routePointDto';
export * from './lib/FleetLogoOverlay';
export * from './lib/types/workingHoursDto';
export * from './lib/types/vehicleStatusDto';
export * from './lib/types/routePoint';
export * from './lib/types/routePointDto';
export * from './lib/normalizers/routePointNormalizer';
export * from './lib/normalizers/productNormalizer';
export * from './lib/types/weekDays';
export * from './lib/useNavigateBack';
export * from './lib/ProductIcon';
export * from './lib/map/Map';
export * from './lib/map/coords';
export * from './lib/map/MapWrapper';
export * from './lib/map/PlantMarker';
export * from './lib/map/test/TestMap';
export * from './lib/map/test/TestMapContainer';
export * from './lib/map/mapDefaultProps';
export * from './lib/map/offsetPoints';
export * from './lib/map/MapMarkerWrapper';
export * from './lib/FleetLocationContext';
export * from './lib/FleetPage';
export * from './lib/types/jobStatusesDto';
export * from './lib/types/jobStatuses';
export * from './lib/normalizers/jobStatusMap';
export * from './lib/JobStatusChip';
export * from './lib/formatDurationInSeconds';
export * from './lib/formatIntervalDuration';
export * from './lib/JobStatusChip';
export * from './lib/ContainerIcon';
export * from './lib/getDataByPlantId';
export * from './lib/TankMarker';
export * from './lib/TimeWindowWarningPopover';
