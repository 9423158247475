import {
  ESDialogContent,
  ESFullScreenDialog,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { useCloseDialogPrompt } from '@energy-stacks/shared';
import { DialogTitle, IconButton, Stack } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditEvseForm } from './EditEvseForm';
import { useGetChargingStationDetailsQuery } from '@energy-stacks/obelis/feature-charging-stations-data';
import { useParams } from 'react-router-dom';

type EditEvseDialogProps = {
  evseId: string;
  onClose: () => void;
};

export const EditEvseDialog: FC<EditEvseDialogProps> = ({
  evseId,
  onClose,
}) => {
  const [t] = useTranslation('chargingStations');
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { id: chargingStationId } = useParams();
  const { showSnackbar } = useESSnackbar();
  const { evseDetails } = useGetChargingStationDetailsQuery(
    chargingStationId ?? '',
    {
      selectFromResult: ({ data }) => ({
        evseDetails: data?.evses.find((evse) => evse.evseId === evseId),
      }),
    }
  );

  const handleCloseDialog = useCloseDialogPrompt({
    shouldPrompt: isFormDirty,
    onClose: onClose,
  });

  useEffect(() => {
    if (!evseDetails) {
      showSnackbar(
        'error',
        t('chargingStationsApiErrors.chargingStationNotFound'),
        'chargingStations'
      );
      onClose();
    }
  }, [evseDetails, showSnackbar, t, onClose]);

  if (!evseDetails) {
    return null;
  }

  return (
    <ESFullScreenDialog isOpen={true}>
      <Stack
        sx={{
          alignItems: 'center',
          borderBottomColor: 'grey.200',
          borderBottomStyle: 'solid',
          borderBottomWidth: 0.25,
          flexDirection: 'row',
          justifyContent: 'space-between',
          pl: 4,
          pr: 4,
          pt: 6,
        }}
      >
        <DialogTitle>{t('editEvseDialogTitle')}</DialogTitle>
        <IconButton
          data-testid="addStationCloseModalButton"
          onClick={handleCloseDialog}
        >
          <IconX />
        </IconButton>
      </Stack>
      <ESDialogContent sx={{ pt: 2 }}>
        <EditEvseForm
          evseDetails={evseDetails}
          setIsFormDirty={setIsFormDirty}
          closeDialog={onClose}
        />
      </ESDialogContent>
    </ESFullScreenDialog>
  );
};
