export const tariffTypesModel = [
  'addHocPayment',
  'profileCheap',
  'profileFast',
  'profileGreen',
  'regular',
  'nonBillable',
] as const;

export type TariffTypeModel = (typeof tariffTypesModel)[number];
