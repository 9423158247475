import { ESPopover } from '@energy-stacks/core/ui';
import { Stack, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

interface TimeWindowWarningPopoverProps extends PropsWithChildren {
  message: string;
}

export const TimeWindowWarningPopover: React.FC<
  TimeWindowWarningPopoverProps
> = ({ children, message }) => {
  const [t] = useTranslation('fleetShared');

  return (
    <ESPopover
      content={
        <Stack padding={1.5} gap={1}>
          <Typography fontWeight={500} color="grey.900">
            {t('timeWindowWarningTitle')}
          </Typography>
          <Typography color="grey.500">{message}</Typography>
        </Stack>
      }
      breakWord
    >
      {children}
    </ESPopover>
  );
};
