import { useGetRawRenewalReportQuery } from '@energy-stacks/obelis/feature-charging-station-reports-data';
import { useParams } from 'react-router-dom';

export const useRenewalRawReport = (isEditMode: boolean) => {
  const { id } = useParams<{ id: string }>();

  const {
    data: rawReport,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  } = useGetRawRenewalReportQuery(
    {
      identityKey: id || '',
    },
    {
      skip: isEditMode,
    }
  );

  return {
    rawReport,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  };
};
