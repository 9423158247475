import { additionalCostsReportDtoToModelNormalizer } from '../../normalizers/additionalCostsReportToPayload';
import { AdHocTariffRawReportDto } from '../adHocTariffRawReportDto';
import { AdHocTariffRawReportModel } from '../adHocTariffRawReportModel';

export const adHocTariffRawReportNormalizer = (
  addHocTariffRawReportDto: AdHocTariffRawReportDto
): AdHocTariffRawReportModel => {
  const {
    uid,
    monthYear,
    chargingHubUid,
    cost,
    additionalCosts,
    validFrom,
    validTo,
  } = addHocTariffRawReportDto;

  return {
    uid: uid ?? undefined,
    monthYear: monthYear ?? '',
    chargingHubUid: chargingHubUid ?? '',
    cost: cost !== null ? String(cost) : '',
    additionalCosts: additionalCostsReportDtoToModelNormalizer(
      additionalCosts ?? []
    ),
    validFrom: validFrom ? validFrom : undefined,
    validTo: validTo ? validTo : undefined,
  };
};
