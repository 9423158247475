import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { createApi } from '@reduxjs/toolkit/query/react';
import { chargingSessionsNormalizer } from './normalizers/chargingSessionsNormalizer';
import { ChargingSessionsModel } from './models/chargingSessionsModel';
import { GetChargingSessionsBody } from './getChargingSessionsBody';
import { ChargingSessionsPageEntry } from './chargingSessionsPageEntry';

export const chargingSessionsApi = createApi({
  reducerPath: 'chargingSessionsApi',
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/charging-sessions`),
  tagTypes: ['chargingSessions', 'chargingStationSessions'],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getChargingSessions: builder.query<
      ChargingSessionsModel,
      GetChargingSessionsBody
    >({
      query: (searchParams) => ({
        url: `/search`,
        method: 'GET',
        params: {
          ...searchParams,
          searchValue: searchParams.searchValue,
          startDateTime: searchParams.startDateTime,
          endDateTime: searchParams.endDateTime,
          reason: searchParams.reason,
          tariffType: searchParams.tariffType,
        },
      }),
      providesTags: ['chargingSessions'],
      transformResponse: (chargingSessions: ChargingSessionsPageEntry) => {
        return {
          totalElements: chargingSessions.totalElements ?? 0,
          totalPages: chargingSessions.totalPages ?? 0,
          chargingSessions: chargingSessions.content?.map((item) => {
            return chargingSessionsNormalizer(item);
          }),
        };
      },
    }),
    getChargingStationSessions: builder.query<
      ChargingSessionsModel,
      GetChargingSessionsBody
    >({
      query: (searchParams) => ({
        url: `/search`,
        method: 'GET',
        params: {
          ...searchParams,
          identityKey: searchParams.identityKey,
          searchValue: searchParams.searchValue,
          startDateTime: searchParams.startDateTime,
          endDateTime: searchParams.endDateTime,
          reason: searchParams.reason,
          tariffType: searchParams.tariffType,
        },
      }),
      providesTags: (_result, _error, arg) => [
        {
          type: 'chargingStationSessions',
          id: arg.identityKey,
        },
      ],
      transformResponse: (chargingSessions: ChargingSessionsPageEntry) => {
        return {
          totalElements: chargingSessions.totalElements ?? 0,
          totalPages: chargingSessions.totalPages ?? 0,
          chargingSessions: chargingSessions.content?.map((item) => {
            return chargingSessionsNormalizer(item);
          }),
        };
      },
    }),
  }),
});

export const {
  useGetChargingSessionsQuery,
  useGetChargingStationSessionsQuery,
} = chargingSessionsApi;
