import { ConnectorPowerTypeDto } from '../dtos/connectorPowerTypeDto';
import { ConnectorPowerType } from '../models/connectorPowerTypeModel';

export const connectorPowerTypeDtoToModelMap: Record<
  ConnectorPowerTypeDto,
  ConnectorPowerType
> = {
  AC_1_PHASE: 'AC_1_PHASE',
  AC_2_PHASE: 'AC_2_PHASE',
  AC_2_PHASE_SPLIT: 'AC_2_PHASE_SPLIT',
  AC_3_PHASE: 'AC_3_PHASE',
  DC: 'DC',
};

export const connectorPowerTypeModelToDtoMap: Record<
  ConnectorPowerType,
  ConnectorPowerTypeDto
> = {
  AC_1_PHASE: 'AC_1_PHASE',
  AC_2_PHASE: 'AC_2_PHASE',
  AC_2_PHASE_SPLIT: 'AC_2_PHASE_SPLIT',
  AC_3_PHASE: 'AC_3_PHASE',
  DC: 'DC',
};
