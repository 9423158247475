import { formatMonthYearToPayload } from '@energy-stacks/shared';
import { toPayloadDateObelis } from '@energy-stacks/shared';
import { CreateProcessedAdHocTariffReportPayload } from './processed-reports/createProcessedAdHocTariffReportPayload';
import { EditProcessedAdHocTariffReportPayload } from './processed-reports/editProcessedAdHocTariffReportPayload';
import { additionalCostsReportToPayload } from './normalizers/additionalCostsReportToPayload';
import { ProcessedAdHocTariffReportBody } from '../chargingHubProcessedReportsApi';

export const adHocTariffProcessedReportCreateDataToPayload = (
  adHocTariff: ProcessedAdHocTariffReportBody
): CreateProcessedAdHocTariffReportPayload => {
  const {
    monthYear,
    chargingHubUid,
    cost,
    additionalCosts,
    validFrom,
    validTo,
  } = adHocTariff;

  return {
    monthYear: formatMonthYearToPayload(monthYear),
    chargingHubUid: chargingHubUid,
    cost: Number(cost),
    additionalCosts: additionalCostsReportToPayload(additionalCosts ?? []),
    validFrom: toPayloadDateObelis(validFrom),
    validTo: toPayloadDateObelis(validTo),
  };
};

export const adHocTariffProcessedReportEditDataToPayload = (
  adHocTariff: Omit<ProcessedAdHocTariffReportBody, 'chargingHubUid'>
): EditProcessedAdHocTariffReportPayload => {
  const { monthYear, cost, additionalCosts, validFrom, validTo } = adHocTariff;

  return {
    monthYear: formatMonthYearToPayload(monthYear),
    cost: cost ? Number(cost) : undefined,
    additionalCosts: additionalCostsReportToPayload(additionalCosts ?? []),
    validFrom: toPayloadDateObelis(validFrom),
    validTo: toPayloadDateObelis(validTo),
  };
};
