import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  ESPage,
  ESPageLoadingIndicator,
  RefetchOnError,
  useDocumentTitle,
} from '@energy-stacks/shared';
import { usePagination, useSort } from '@energy-stacks/core/ui';
import { SystemProcessedReportsTable } from './SystemProcessedReportsTable';
import { useSystemProcessedReports } from './useSystemProcessedReports';

export const SystemReportsPage = () => {
  const { sorting, setSorting } = useSort();
  const { pagination, setPagination } = usePagination();
  const { t } = useTranslation('systemReports');
  useDocumentTitle(t('sidebarItem'));
  const [monthYearFilterValue, setMonthYearFilterValue] = useState(new Date());

  const { systemProcessedReports, refetch, isLoading, isError } =
    useSystemProcessedReports({
      monthYear: format(monthYearFilterValue, 'MM-yyyy'),
      pagination: {
        page: pagination.pageIndex,
        size: pagination.pageSize,
      },
    });

  return (
    <ESPage title={t('sidebarItem')}>
      {isLoading && <ESPageLoadingIndicator />}
      {isError && <RefetchOnError onRefetch={refetch} />}
      {!isLoading && !isError ? (
        <SystemProcessedReportsTable
          systemProcessedReports={systemProcessedReports}
          onPaginationChange={setPagination}
          sorting={sorting}
          pagination={pagination}
          onSortingChange={setSorting}
          date={monthYearFilterValue}
          setDate={setMonthYearFilterValue}
        />
      ) : null}

      <Outlet />
    </ESPage>
  );
};
