import { toPayloadDateObelis } from '@energy-stacks/shared';
import { reportFacilityTypeModelToDtoMap } from './reportFacilityTypeModelToDtoMap';
import { CreateProcessedFacilityDamagesReportPayload } from './processed-reports/createProcessedFacilityDamagesReportPayload';
import { EditProcessedFacilityDamagesReportPayload } from './processed-reports/editProcessedFacilityDamagesReportPayload';
import {
  CreateProcessedFacilityDamagesReportBody,
  EditProcessedFacilityDamagesReportBody,
} from '../chargingHubProcessedReportsApi';

export const mapFacilityDamagesReportCreateDataToPayload = (
  facilityDamage: CreateProcessedFacilityDamagesReportBody
): CreateProcessedFacilityDamagesReportPayload => {
  const {
    chargingHubUid,
    uid,
    facilityType,
    emergenceDate,
    forecastDate,
    fixDate,
  } = facilityDamage;

  return {
    chargingHubUid: chargingHubUid,
    uid: uid ?? undefined,
    facilityType: reportFacilityTypeModelToDtoMap[facilityType],
    emergenceDate: toPayloadDateObelis(emergenceDate),
    forecastDate: forecastDate ? toPayloadDateObelis(forecastDate) : '',
    fixDate: fixDate ? toPayloadDateObelis(fixDate) : '',
  };
};

export const mapFacilityDamagesReportEditDataToPayload = (
  facilityDamage: EditProcessedFacilityDamagesReportBody
): EditProcessedFacilityDamagesReportPayload => {
  const { facilityType, emergenceDate, forecastDate, fixDate } = facilityDamage;

  return {
    facilityType: reportFacilityTypeModelToDtoMap[facilityType],
    emergenceDate: toPayloadDateObelis(emergenceDate),
    forecastDate: forecastDate ? toPayloadDateObelis(forecastDate) : '',
    fixDate: fixDate ? toPayloadDateObelis(fixDate) : '',
  };
};
