export const GreyCheckboxIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 3H5.5C4.39 3 3.5 3.9 3.5 5V19C3.5 20.1 4.39 21 5.5 21H19.5C20.61 21 21.5 20.1 21.5 19V5C21.5 3.9 20.61 3 19.5 3ZM10.5 17L5.5 12L6.91 10.59L10.5 14.17L18.09 6.58L19.5 8L10.5 17Z"
        fill="#697586"
      />
    </svg>
  );
};
