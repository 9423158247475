import { useGetSystemProcessedReportsQuery } from '@energy-stacks/obelis/feature-system-reports-data';

interface GetSystemProcessedReportsConfig {
  monthYear: string;
  pagination: {
    page: number;
    size: number;
  };
}

export const useSystemProcessedReports = (
  config: GetSystemProcessedReportsConfig
) => {
  const {
    data: systemProcessedReports,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  } = useGetSystemProcessedReportsQuery({
    monthYear: config.monthYear,
    pageNumber: config.pagination.page,
    pageSize: config.pagination.size,
  });

  return {
    systemProcessedReports,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  };
};
