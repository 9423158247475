import { Box, Grid, Paper, Skeleton } from '@mui/material';
import { range } from 'lodash-es';

export const ChargingStationDetailsGeneralSkeleton = () => {
  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        border: 'none',
        borderRadius: (theme) => theme.spacing(3),
        width: '100%',
        height: '50%',
      }}
    >
      <Grid container display="flex" p={8} paddingTop={3}>
        <Grid container display="flex" direction="column">
          <Box>
            <Skeleton
              variant="text"
              sx={{
                width: 250,
                height: 50,
                marginBottom: 2.5,
              }}
            />
          </Box>
          <Grid display="flex">
            <Box>
              <Skeleton
                variant="text"
                sx={{
                  width: 60,
                  height: 35,
                  marginRight: 8,
                }}
              />
            </Box>
            <Box>
              <Skeleton
                variant="text"
                sx={{
                  width: 130,
                  height: 35,
                  marginRight: 8,
                }}
              />
            </Box>
            <Box>
              <Skeleton
                variant="text"
                sx={{
                  width: 60,
                  height: 35,
                  marginRight: 8,
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 10 }}>
          <Grid item sm={12} md={12} lg={5} xl={4} paddingRight={6}>
            {range(0, 6).map((e, i) => {
              return (
                <Box
                  key={e}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Skeleton
                    variant="text"
                    sx={{
                      marginBottom: 5,
                      width: i === 0 ? '50%' : '100%',
                    }}
                  />
                </Box>
              );
            })}
          </Grid>
          <Grid item sm={12} md={12} lg={7} xl={8}>
            <Skeleton variant="rectangular" animation="wave" height={365} />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
