import { useTranslation } from 'react-i18next';
import { Dialog } from '@mui/material';
import {
  ESButton,
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import { FC, useCallback } from 'react';
import {
  ChargingHubModel,
  chargingHubsApiErrors,
  useDeleteChargingHubMutation,
} from '@energy-stacks/obelis/feature-charging-hubs-data';

type DeleteChargingHubDialogProps = {
  chargingHubDetails: {
    chargingHubId: ChargingHubModel['id'];
    chargingHubName: ChargingHubModel['name'];
    assignedChargingStationsCount: ChargingHubModel['chargingStations'];
  };
  onClose: () => void;
};

export const DeleteChargingHubDialog: FC<DeleteChargingHubDialogProps> = ({
  chargingHubDetails,
  onClose,
}) => {
  const { chargingHubId, chargingHubName, assignedChargingStationsCount } =
    chargingHubDetails;
  const [t] = useTranslation('chargingHubs');
  const [deleteChargingHub, { isLoading }] = useDeleteChargingHubMutation();
  const { showSnackbar } = useESSnackbar();

  const handleDeleteChargingHub = useCallback(() => {
    return deleteChargingHub(chargingHubId)
      .unwrap()
      .then(() => {
        showSnackbar('success', 'deleteChargingHubSuccess', 'chargingHubs');
        onClose();
      })
      .catch((error) => {
        showSnackbar(
          'error',
          chargingHubsApiErrors[error.data?.errorCode],
          'chargingHubs'
        );
      });
  }, [chargingHubId, deleteChargingHub, showSnackbar, onClose]);

  return assignedChargingStationsCount > 0 ? (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <ESDialogTitle>
        {t('cannotDeleteChargingHubTitle', { name: chargingHubName })}
      </ESDialogTitle>
      <ESDialogContent>{t('cannotDeleteChargingHubMessage')}</ESDialogContent>
      <ESDialogActions>
        <ESButton
          data-testid="cannotDeleteChargingHubButton"
          variant="contained"
          onClick={onClose}
        >
          {t('cannotDeleteChargingHubButton')}
        </ESButton>
      </ESDialogActions>
    </Dialog>
  ) : (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <ESDialogTitle>
        {t('confirmDeleteChargingHubTitle', { name: chargingHubName })}
      </ESDialogTitle>
      <ESDialogContent>{t('confirmDeleteChargingHubMessage')}</ESDialogContent>
      <ESDialogActions>
        <ESDialogActionButton
          data-testid="deleteChargingHubCancelButton"
          color="error"
          disabled={isLoading}
          onClick={onClose}
        >
          {t('cancelDeleteChargingHubButton')}
        </ESDialogActionButton>
        <ESDialogActionButton
          data-testid="deleteChargingHubDeleteButton"
          variant="contained"
          color="error"
          disabled={isLoading}
          onClick={handleDeleteChargingHub}
        >
          {t('confirmDeleteChargingHubButton')}
        </ESDialogActionButton>
      </ESDialogActions>
    </Dialog>
  );
};
