import { reportFacilityTypeDtoToModelMap } from '../../reportFacilityTypeDtoToModelMap';
import { ProcessedFacilityDamagesReportDto } from '../processedFacilityDamagesReportDto';
import { ProcessedFacilityDamagesReportModel } from '../processedFacilityDamagesReportModel';

export const processedFacilityDamagesReportsNormalizer = (
  processedFacilityDamagesReportDto: ProcessedFacilityDamagesReportDto
): ProcessedFacilityDamagesReportModel => {
  const { uid, facilityType, emergenceDate, fixDate, forecastDate } =
    processedFacilityDamagesReportDto;

  return {
    uid: uid ?? undefined,
    facilityType: reportFacilityTypeDtoToModelMap[facilityType],
    emergenceDate: emergenceDate,
    fixDate: fixDate ?? undefined,
    forecastDate: forecastDate ?? undefined,
  };
};
