import { Box, Grid, IconButton, Typography } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import {
  Controller,
  FieldArrayWithId,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form';
import { ESTextField } from '@energy-stacks/core/ui';
import { ProcessedNonDiscriminatoryAccessReportFormData } from './SystemNonDiscriminatoryAccessReportForm';

interface AdditionalCostProps {
  additionalCostIndex: number;
  onRemove: UseFieldArrayRemove;
  isDisabled: boolean;
  additionalCost: FieldArrayWithId<
    ProcessedNonDiscriminatoryAccessReportFormData,
    'additionalCosts',
    'id'
  >;
}

export const AdditionalCost: React.FC<AdditionalCostProps> = ({
  additionalCostIndex,
  onRemove,
  isDisabled,
  additionalCost,
}) => {
  const { t } = useTranslation('systemReports');
  const {
    control,
    formState: { errors: formErrors },
  } = useFormContext();

  const errors = formErrors['additionalCosts']?.[additionalCostIndex];

  return (
    <Box
      sx={{
        marginTop: additionalCostIndex === 0 ? 0 : 4,
        backgroundColor: 'grey.100',
        borderRadius: 4,
        marginX: 4,
        paddingX: 4,
        paddingTop: 6,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Grid
        container
        sx={{
          alignItems: 'center',
        }}
      >
        <Grid item xs={3.9} paddingY={8}>
          <Typography variant="body1" fontWeight={500} color={'grey.600'}>
            {t('additionalCostDescription') + ' *'}
          </Typography>
        </Grid>

        <Grid item xs={4.15} paddingY={8}>
          <Typography variant="body1" fontWeight={500} color={'grey.600'}>
            {additionalCost.description && !additionalCost.isNew
              ? additionalCost.description
              : '-'}
          </Typography>
        </Grid>

        <Grid item xs={3.95}>
          <Controller
            name={`additionalCosts.${additionalCostIndex}.description`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <ESTextField
                placeholder={
                  additionalCost.description && !additionalCost.isNew
                    ? additionalCost.description
                    : '-'
                }
                InputProps={{
                  sx: { height: '53px' },
                }}
                disabled={isDisabled}
                required
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={Boolean(errors?.description)}
                helperText={
                  Boolean(errors?.description) &&
                  errors?.description?.message &&
                  t(errors?.description?.message)
                }
              />
            )}
          />
        </Grid>

        <Grid
          container
          sx={{
            alignItems: 'center',
          }}
        >
          <Grid item xs={3.9} paddingY={8}>
            <Typography variant="body1" fontWeight={500} color={'grey.600'}>
              {`${t('additionalCost')} [${t('ctPerHourWithoutVat')}] *`}
            </Typography>
          </Grid>

          <Grid item xs={4.15} paddingY={8}>
            <Typography variant="body1" fontWeight={500} color={'grey.600'}>
              {additionalCost.cost && !additionalCost.isNew
                ? additionalCost.cost
                : '-'}
            </Typography>
          </Grid>

          <Grid item xs={3.95}>
            <Controller
              name={`additionalCosts.${additionalCostIndex}.cost`}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <ESTextField
                  placeholder={
                    additionalCost.cost && !additionalCost.isNew
                      ? additionalCost.cost
                      : '-'
                  }
                  value={value}
                  disabled={isDisabled}
                  onChange={(event) => {
                    const inputValue = event?.target.value;
                    // this regex matches a string that consists entirely of one or more digits, with no other characters before or after
                    if (inputValue === '' || /^\d+$/.test(inputValue ?? '')) {
                      onChange(inputValue);
                    }
                  }}
                  onBlur={onBlur}
                  error={Boolean(errors?.cost)}
                  helperText={
                    Boolean(errors?.cost) &&
                    errors?.cost?.message &&
                    t(`${errors?.cost?.message}`)
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item mb={35}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            height: 0,
            position: 'absolute',
            top: 16,
            right: 16,
          }}
        >
          <IconButton
            disableRipple
            disabled={isDisabled}
            size="small"
            edge="end"
            onClick={() => onRemove(additionalCostIndex)}
            sx={{
              padding: 0.2,
              color: 'grey.500',
            }}
          >
            <IconX stroke={1.5} />
          </IconButton>
        </Box>
      </Grid>
    </Box>
  );
};
