import { RefetchOnError } from '@energy-stacks/shared';
import { ChargingHubDetailsGeneral } from './ChargingHubDetailsGeneral';
import { useChargingHubDetails } from './useChargingHubDetails';
import { NoChargingHubDetails } from './NoChargingHubDetails';

export const ChargingHubDetailsGeneralTab = () => {
  const { chargingHubDetails, isSuccess, isError, refetch } =
    useChargingHubDetails();

  return (
    <>
      {isError ? <RefetchOnError onRefetch={refetch} /> : null}
      {!chargingHubDetails && isSuccess ? (
        <NoChargingHubDetails onRefetch={refetch} />
      ) : (
        <ChargingHubDetailsGeneral />
      )}
    </>
  );
};
