import { isAfter, isValid } from 'date-fns';
import * as yup from 'yup';

const renewedComponent = yup.object().shape({
  chargePointComponent: yup
    .string()
    .nullable()
    .required('renewedComponentRequired'),
  reason: yup.string().nullable().required('renewedComponentRequired'),
});

export const editChargingStationRenewalReportSchema = yup.object().shape({
  renewedComponents: yup
    .array()
    .of(renewedComponent)
    .min(1, 'atLeastOneRenewed'),
  start: yup
    .date()
    .nullable()
    .typeError('invalidStartDate')
    .required('startRequired'),
  end: yup.date().when('start', {
    is: (start: Date | null) => isValid(start),
    then: yup
      .date()
      .nullable()
      .typeError('invalidEndDate')
      .required('endRequired')
      .test(
        'isAfterStart',
        'endNeedsAfterStart',
        (endTime, { parent: { start: startTime } }) => {
          if (!endTime) {
            return true;
          }

          return isAfter(endTime, startTime);
        }
      ),
    otherwise: yup
      .date()
      .nullable()
      .typeError('invalidEndDate')
      .required('endRequired'),
  }),
});
