import * as yup from 'yup';

export const editChargingStationRatingReportSchema = yup.object().shape({
  monthYear: yup.date().required('monthRequired'),
  rating: yup
    .number()
    .required('ratingRequired')
    .typeError('ratingMustBeANumber'),
  totalComplaints: yup
    .string()
    .test('isNumber', 'mustBeNumber', (value) => {
      if (!value) {
        return true;
      }
      return !isNaN(Number(value));
    })
    .test('isPositive', 'mustBePositiveValue', (value) => {
      if (!value) {
        return true;
      }

      return Number(value) >= 0;
    })
    .test('onlyDigits', 'mustBeWholeNumber', (value) => {
      if (!value) {
        return true;
      }

      return /^\d+$/.test(value);
    }),
});
