export const tariffTypesDto = [
  'ADD_HOC_PAYMENT',
  'PROFILE_CHEAP',
  'PROFILE_FAST',
  'PROFILE_GREEN',
  'REGULAR',
  'NON_BILLABLE',
] as const;

export type TariffTypeDto = (typeof tariffTypesDto)[number];
