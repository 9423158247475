import { NonDiscriminatoryAccessReportContractualBasisDto } from '../nonDiscriminatoryAccessReportContractualBasisDto';
import { NonDiscriminatoryAccessReportContractualBasisModel } from '../nonDiscriminatoryAccessReportContractualBasisModel';

export const nonDiscriminatoryAccessReportContractualBasisModelToDto: Record<
  NonDiscriminatoryAccessReportContractualBasisModel,
  NonDiscriminatoryAccessReportContractualBasisDto
> = {
  roamingContract: 'ROAMING_CONTRACT',
  bilateralContract: 'BILATERAL_CONTRACT',
} as const;

export const nonDiscriminatoryAccessReportContractualBasisDtoToModelMap: Record<
  NonDiscriminatoryAccessReportContractualBasisDto,
  NonDiscriminatoryAccessReportContractualBasisModel
> = {
  ROAMING_CONTRACT: 'roamingContract',
  BILATERAL_CONTRACT: 'bilateralContract',
} as const;
