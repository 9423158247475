import { useParams } from 'react-router-dom';
import { useGetProcessedFacilityDamagesReportByReportUidQuery } from '@energy-stacks/obelis/feature-charging-hub-reports-data';

export const useProcessedFacilityDamagesReport = (iEditMode: boolean) => {
  const { uid } = useParams<{ uid: string }>();

  const { data, isLoading, error, isSuccess, refetch, isError } =
    useGetProcessedFacilityDamagesReportByReportUidQuery(
      {
        uid: uid ?? '',
      },
      {
        skip: !iEditMode,
      }
    );

  return {
    data,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  };
};
