import { ProcessedReportDto } from '../processedReportDto';
import { ProcessedReport } from '../processedReportModel';
import { ProcessedReportTypeDto } from '../processedReportTypeDto';
import { ProcessedReportTypeModel } from '../processedReportTypeModel';

export const processedReportsNormalizer = (
  processedReportsDto: ProcessedReportDto[]
): ProcessedReport[] => {
  return processedReportsDto.map((processedReportDto) => {
    const {
      reportType,
      reportDate,
      reportedBy,
      modifiedBy,
      uid,
      lastModifiedDate,
    } = processedReportDto;

    return {
      reportType: processedReportNameDtoToModelMap[reportType],
      reportDate,
      reportedBy,
      modifiedBy,
      uid,
      lastModifiedDate,
    };
  });
};

export const processedReportNameDtoToModelMap: Record<
  ProcessedReportTypeDto,
  ProcessedReportTypeModel
> = {
  RENEWAL: 'renewal',
  CHARGING_POINT_DEFECT: 'chargingPointDefect',
  RATING: 'rating',
} as const;
