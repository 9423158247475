import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@tanstack/react-table';
import { ESTextButton } from '@energy-stacks/core/ui';

interface ClearFiltersButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableInstance: Table<any>;
  // in some cases filter always needs to be set so no need to clear it
  ignoreColumnIds?: string[];
  clearFilters?: () => void;
  onFiltersCleared?: () => void;
}

export const ClearFiltersButton: React.FC<ClearFiltersButtonProps> = ({
  tableInstance,
  ignoreColumnIds,
  clearFilters,
  onFiltersCleared,
}) => {
  const [t] = useTranslation('shared');
  const { columnFilters } = tableInstance.getState();

  const clear = useCallback(() => {
    onFiltersCleared?.();
    tableInstance.setColumnFilters([]);
  }, [onFiltersCleared, tableInstance]);

  const showClear = !ignoreColumnIds
    ? columnFilters.length > 0
    : columnFilters.filter(
        ({ id }) => !ignoreColumnIds.find((ignoreId) => id === ignoreId)
      ).length > 0;

  return showClear ? (
    <ESTextButton onClick={clearFilters || clear}>{t('clearAll')}</ESTextButton>
  ) : null;
};
