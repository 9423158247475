import { isAfter, isPast, isValid } from 'date-fns';
import * as yup from 'yup';

export const editChargingStationDefectReportSchema = yup.object().shape({
  evseId: yup.string().nullable().required('evseIdRequired'),
  type: yup.string().nullable().required('typeRequired'),
  status: yup.string().nullable().required('statusRequired'),
  activities: yup.string().nullable().required('activitiesRequired'),
  additionalInformation: yup.string().nullable(),
  emergenceDate: yup
    .date()
    .nullable()
    .typeError('invalidEmergenceDate')
    .required('emergenceDateFieldIsRequired')
    .test('isInPast', 'emergenceDateMustBeInPast', (emergenceDate) => {
      if (!emergenceDate) {
        return true;
      }
      return isPast(emergenceDate);
    }),
  forecastDate: yup.date().when('emergenceDate', {
    is: (value: Date | null) => isValid(value),
    then: yup
      .date()
      .nullable()
      .typeError('invalidForecastDate')
      .notRequired()
      .test(
        'isAfterEmergenceDate',
        'reportForecastDateIsBeforeOrEqualToEmergenceDate',
        (forecastDate, { parent: { emergenceDate } }) => {
          if (!forecastDate) {
            return true;
          }
          return isAfter(forecastDate, emergenceDate);
        }
      ),
    otherwise: yup
      .date()
      .nullable()
      .typeError('invalidForecastDate')
      .notRequired(),
  }),
  fixDate: yup.date().when('emergenceDate', {
    is: (value: Date | null) => isValid(value),
    then: yup
      .date()
      .nullable()
      .typeError('invalidFixDate')
      .notRequired()
      .test(
        'isAfterEmergenceDate',
        'reportFixDateIsBeforeOrEqualToEmergenceDate',
        (fixDate, { parent: { emergenceDate } }) => {
          if (!fixDate) {
            return true;
          }
          return isAfter(fixDate, emergenceDate);
        }
      ),
    otherwise: yup.date().nullable().typeError('invalidFixDate').notRequired(),
  }),
});
