import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import { useChargingHubDetails } from './useChargingHubDetails';
import { ChargingHubDetailsGeneralCardSkeleton } from './ChargingHubDetailsGeneralCardSkeleton';

type ChargingHubDetailsGeneralCardModel = {
  id: string;
  name: string;
  streetAndNumber: string;
  zip: string;
  city: string;
  state: string;
  country: string;
  auxiliaryFacilities: string[];
};

const NO_VALUE = 'N/A';

export const ChargingHubDetailsGeneralCard: React.FC = () => {
  const { chargingHubDetails, isLoading } = useChargingHubDetails();
  const { palette } = useTheme();
  const { t } = useTranslation('chargingHubs');

  const generalDetailsValues: ChargingHubDetailsGeneralCardModel = useMemo(
    () => ({
      id: chargingHubDetails?.id || NO_VALUE,
      name: chargingHubDetails?.name || NO_VALUE,
      streetAndNumber: chargingHubDetails?.address || NO_VALUE,
      zip: chargingHubDetails?.postalCode || NO_VALUE,
      city: chargingHubDetails?.city || NO_VALUE,
      state: chargingHubDetails?.state || NO_VALUE,
      country: chargingHubDetails?.state ? t('germany') : NO_VALUE,
      auxiliaryFacilities: chargingHubDetails?.auxiliaryFacilities.length
        ? chargingHubDetails?.auxiliaryFacilities.map((af, index, arr) => {
            if (index + 1 !== arr.length) {
              return t(af).concat(' \u2022 ');
            }

            return t(af);
          })
        : [NO_VALUE],
    }),
    [chargingHubDetails, t]
  );

  if (isLoading) {
    return <ChargingHubDetailsGeneralCardSkeleton />;
  }

  return (
    <Paper
      sx={{
        border: 'none',
        height: '100%',
        p: 4,
        borderRadius: 3,
      }}
    >
      <Typography
        variant="h4"
        fontWeight={700}
        sx={{ wordBreak: 'break-all', pb: 4 }}
        color="grey.900"
        textTransform="capitalize"
      >
        {t('chargingHubDetails')}
      </Typography>

      {Object.entries(generalDetailsValues).map((section, index, arr) => {
        const lastItem = index === arr.length - 1;

        return (
          <Box
            key={section[0]}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            sx={{
              paddingY: 2,
              borderBottom: lastItem
                ? 'none'
                : `1px solid ${palette.grey[100]}`,
              flexDirection: !lastItem ? 'row' : 'column',
            }}
          >
            <Typography
              variant="body2"
              lineHeight={1.5}
              color="grey.900"
              marginRight={3}
            >
              {t(section[0])}
            </Typography>

            <Typography
              variant="body2"
              fontWeight={500}
              lineHeight={1.5}
              color="grey.900"
              textAlign="right"
              sx={{
                textAlign: !lastItem ? 'right' : 'left',
                marginTop: !lastItem ? '' : 3,
              }}
            >
              {section[1]}
            </Typography>
          </Box>
        );
      })}
    </Paper>
  );
};
