import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TabConfig } from '@energy-stacks/core/ui';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';
import { ChargingHubDetailsChargingStationsTab } from './ChargingHubDetailsChargingStationsTab';
import { ChargingHubDetailsReportsTab } from './ChargingHubDetailsReportsTab';
import { ChargingHubDetailsGeneralTab } from './ChargingHubDetailsGeneralTab';

export const useChargingHubDetailsTabs = () => {
  const [t] = useTranslation('chargingHubs');

  const config: TabConfig = useMemo(() => {
    return {
      tabRootPath: `${ObelisRoutes.ChargingHubs}/${ObelisRoutes.ChargingHubDetails}`,
      tabs: [
        {
          component: ChargingHubDetailsGeneralTab,
          name: t('generalTab'),
          path: ObelisRoutes.ChargingHubDetailsGeneralTab,
        },
        {
          component: ChargingHubDetailsChargingStationsTab,
          name: t('chargingStationsTab'),
          path: ObelisRoutes.ChargingHubDetailsChargingStationsTab,
        },
        {
          component: ChargingHubDetailsReportsTab,
          name: t('reportsTab'),
          path: ObelisRoutes.ChargingHubDetailsReportsTab,
        },
      ],
    };
  }, [t]);

  return { config };
};
