import {
  chargingStationsApiErrors,
  Evse,
  Status,
  useDeleteEvseMutation,
} from '@energy-stacks/obelis/feature-charging-stations-data';
import { Box, Chip, MenuItem, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { statusColorsMap } from '../statusColorsMap';
import { FC, useCallback, useState } from 'react';
import { ConfirmDeleteDialog, MoreOptionsMenu } from '@energy-stacks/shared';
import { useESMenuContext, useESSnackbar } from '@energy-stacks/core/ui';
import { useParams } from 'react-router-dom';
import { EditEvseDialog } from './edit-evse/EditEvseDialog';

type EvseCardHeaderProps = {
  chargingPointId: Evse['chargingPointId'];
  evseId: Evse['evseId'];
  status: Evse['status'];
};

type EvseHeaderMenuItemProps = {
  label: string;
  onClick: () => void;
};

export const ChargingStationDetailsEvseCardHeader: FC<EvseCardHeaderProps> = ({
  chargingPointId,
  evseId,
  status,
}) => {
  const [t] = useTranslation('chargingStations');
  const { id: chargingStationId } = useParams();
  const { showSnackbar } = useESSnackbar();
  const [isDeleteEvseDialogOpen, setIsDeleteEvseDialogOpen] = useState(false);
  const [isEditEvseDialogOpen, setIsEditEvseDialogOpen] = useState(false);
  const [deleteEvse] = useDeleteEvseMutation();

  const handleDeleteEvse = useCallback(() => {
    if (!chargingStationId || !chargingPointId) {
      return;
    }
    return deleteEvse({
      identityKey: chargingStationId,
      chargingPointUid: chargingPointId,
    })
      .unwrap()
      .then(() => {
        showSnackbar('success', 'deleteEvseSuccessful', 'chargingStations');
      })
      .catch((error) => {
        const errorCode = error.data?.errorCode;
        showSnackbar(
          'error',
          errorCode
            ? `chargingStationsApiErrors.${chargingStationsApiErrors[errorCode]}`
            : undefined,
          'chargingStations'
        );
      });
  }, [chargingStationId, chargingPointId, deleteEvse, showSnackbar]);

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'dark.800',
          display: 'flex',
          justifyContent: 'space-between',
          padding: 4,
        }}
      >
        <Stack>
          <Typography
            sx={({ spacing }) => ({
              color: 'dark.light',
              fontSize: spacing(3),
              fontWeight: 400,
              lineHeight: spacing(5),
              textTransform: 'uppercase',
            })}
          >
            {t('evseCardHeaderIdLabel')}
          </Typography>
          <Typography
            sx={({ spacing }) => ({
              color: 'background.paper',
              fontSize: spacing(4),
              fontWeight: 500,
            })}
          >
            {evseId}
          </Typography>
        </Stack>
        <Stack
          sx={{
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          {status && <EvseStatusChip status={status} />}
          <MoreOptionsMenu>
            <EvseHeaderMenuItem
              label={t('editEvseMenuItem')}
              onClick={() => setIsEditEvseDialogOpen(true)}
            />
            <EvseHeaderMenuItem
              label={t('deleteEvseMenuItem')}
              onClick={() => setIsDeleteEvseDialogOpen(true)}
            />
          </MoreOptionsMenu>
        </Stack>
      </Box>
      {isEditEvseDialogOpen && (
        <EditEvseDialog
          evseId={evseId}
          onClose={() => setIsEditEvseDialogOpen(false)}
        />
      )}
      {isDeleteEvseDialogOpen && (
        <ConfirmDeleteDialog
          title={t('deleteEvseDialogTitle', { evseId: evseId })}
          description={t('deleteEvseDialogDescription')}
          onCancel={() => setIsDeleteEvseDialogOpen(false)}
          onConfirm={handleDeleteEvse}
        />
      )}
    </>
  );
};

const EvseStatusChip = ({ status }: { status: Status }) => {
  const [t] = useTranslation('chargingStations');
  const { bgColor, color } = statusColorsMap[status];

  return (
    <Chip
      label={t(`statuses.${status}`)}
      size="small"
      sx={{
        backgroundColor: bgColor,
        color: color,
        mr: 3,
        px: 1,
      }}
    />
  );
};

const EvseHeaderMenuItem: FC<EvseHeaderMenuItemProps> = ({
  label,
  onClick,
}) => {
  const { closeESMenu } = useESMenuContext();

  return (
    <MenuItem
      onClick={() => {
        onClick();
        closeESMenu();
      }}
    >
      {label}
    </MenuItem>
  );
};
