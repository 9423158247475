import { FormStepperModel } from '@energy-stacks/form-stepper';

export const editChargingHubSteps: EditChargingHubSteps = {
  0: { name: 'generalDetails', optional: false },
  1: { name: 'auxiliaryFacilities', optional: true },
};

export type EditChargingHubFormStepName =
  | 'generalDetails'
  | 'auxiliaryFacilities';

export type EditChargingHubSteps =
  FormStepperModel<EditChargingHubFormStepName>;
