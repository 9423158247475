import { useNavigate } from 'react-router-dom';
import { ESScreenDrawer } from '@energy-stacks/feature-screen-drawer';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';
import { ChargingHubDetails } from './ChargingHubDetails';

export const ChargingHubDetailsSlider: React.FC = () => {
  const navigate = useNavigate();

  return (
    <ESScreenDrawer
      fullScreen
      closeButtonTopPosition={50}
      onClose={() => navigate(ObelisRoutes.ChargingHubs)}
    >
      <ChargingHubDetails />
    </ESScreenDrawer>
  );
};
