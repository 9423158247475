export interface RawDefectReportModel {
  uid: string;
  chargingHubUid: string;
  chargingPointUid: string;
  evseId: string | null;
  type: TypeModel;
  status: StatusModel;
  activities: ActivitiesModel;
  additionalInformation: AdditionalInformationModel | null;
  emergenceDate: string;
  forecastDate: string | null;
  fixDate: string | null;
}

export const typeModel = [
  'damage',
  'hardwareFault',
  'softwareFault',
  'networkFailure',
  'powerFailure',
  'routeClosure',
  'siteClosure',
  'other',
] as const;
export type TypeModel = (typeof typeModel)[number];

export const statusModel = [
  'technicianInformed',
  'technicianOnTheWay',
  'errorSolved',
] as const;
export type StatusModel = (typeof statusModel)[number];

export const activitiesModel = ['remoteAccess', 'onSite'] as const;
export type ActivitiesModel = (typeof activitiesModel)[number];

export const additionalInformationModel = [
  'dangerousMalFunction',
  'usageImpossible',
  'renewalNecessary',
] as const;
export type AdditionalInformationModel =
  (typeof additionalInformationModel)[number];
