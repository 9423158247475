import { createApi } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { RawHotlineRatingReportModel } from './hotline-rating-reports/raw-reports/rawHotlineRatingReportModel';
import { RawHotlineRatingReportDto } from './hotline-rating-reports/raw-reports/rawHotlineRatingReportDto';
import { rawHotlineRatingReportNormalizer } from './hotline-rating-reports/raw-reports/normalizers/rawHotlineRatingReportNormalizer';
import { RawNonDiscriminatoryAccessReportModel } from './non-discriminatory-access-reports/raw-reports/rawNonDiscriminatoryAccessReportModel';
import { RawNonDiscriminatoryAccessReportDto } from './non-discriminatory-access-reports/raw-reports/rawNonDiscriminatoryAccessReportDto';
import { rawNonDiscriminatoryAccessReportNormalizer } from './non-discriminatory-access-reports/raw-reports/normalizers/rawNonDiscriminatoryAccessReportNormalizer';

export const systemRawReportsApi = createApi({
  reducerPath: 'systemRawReportsApi',
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/raw-reports`),
  keepUnusedDataFor: 0,
  tagTypes: [
    'SystemRawReports',
    'SystemRawReportsNonDiscriminatoryAccess',
    'SystemRawReportsHotlineRating',
  ],
  endpoints: (builder) => ({
    getSystemHotlineRatingRawReport: builder.query<
      RawHotlineRatingReportModel,
      { monthYear: string }
    >({
      query: ({ monthYear }) => ({
        url: `/hotline-ratings/${monthYear}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'SystemRawReportsHotlineRating',
          id: args.monthYear,
        },
      ],
      transformResponse: (response: RawHotlineRatingReportDto) => {
        return rawHotlineRatingReportNormalizer(response);
      },
    }),
    getSystemNonDiscriminatoryAccessRawReport: builder.query<
      RawNonDiscriminatoryAccessReportModel,
      { monthYear: string }
    >({
      query: ({ monthYear }) => ({
        url: `/nondiscriminatory-access/${monthYear}/latest`,
        method: 'GET',
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'SystemRawReportsNonDiscriminatoryAccess',
          id: args.monthYear,
        },
      ],
      transformResponse: (response: RawNonDiscriminatoryAccessReportDto) => {
        return rawNonDiscriminatoryAccessReportNormalizer(response);
      },
    }),
  }),
});

export const {
  useGetSystemHotlineRatingRawReportQuery,
  useGetSystemNonDiscriminatoryAccessRawReportQuery,
} = systemRawReportsApi;
