import { ESDialogContent, ESFullScreenDialog } from '@energy-stacks/core/ui';
import { DialogTitle, IconButton, Stack } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { AddEvseForm } from './AddEvseForm';
import { FC, useState } from 'react';
import { useCloseDialogPrompt } from '@energy-stacks/shared';

type AddEvseProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const AddEvse: FC<AddEvseProps> = ({ isOpen, onClose }) => {
  const [t] = useTranslation('chargingStations');
  const [isFormDirty, setIsFormDirty] = useState(false);

  const handleCloseDialog = useCloseDialogPrompt({
    shouldPrompt: isFormDirty,
    onClose: onClose,
  });

  return (
    <ESFullScreenDialog isOpen={isOpen}>
      <Stack
        sx={{
          alignItems: 'center',
          borderBottomColor: 'grey.200',
          borderBottomStyle: 'solid',
          borderBottomWidth: 0.25,
          flexDirection: 'row',
          justifyContent: 'space-between',
          pl: 4,
          pr: 4,
          pt: 6,
        }}
      >
        <DialogTitle>{t('addEvseDialogTitle')}</DialogTitle>
        <IconButton
          data-testid="addStationCloseModalButton"
          onClick={handleCloseDialog}
        >
          <IconX />
        </IconButton>
      </Stack>
      <ESDialogContent sx={{ pt: 2 }}>
        <AddEvseForm setIsFormDirty={setIsFormDirty} closeDialog={onClose} />
      </ESDialogContent>
    </ESFullScreenDialog>
  );
};
