import { Box, Grid, Paper, Skeleton } from '@mui/material';
import { range } from 'lodash-es';

export const ChargingHubDetailsGeneralCardSkeleton = () => {
  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        border: 'none',
        borderRadius: (theme) => theme.spacing(3),
        width: '100%',
        height: '100%',
      }}
    >
      <Grid
        container
        columns={2}
        rowSpacing={6}
        sx={{ pt: 5.5, height: 'fit-content' }}
        direction={'column'}
      >
        <Grid container columns={2} rowSpacing={5} sx={{ p: 5 }}>
          {range(0, 10).map((i, index) => {
            const firstItem = index === 0;

            return (
              <Grid item key={i} xs={2}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Skeleton
                    variant="text"
                    sx={{
                      width: firstItem ? '50%' : '100%',
                    }}
                  />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Paper>
  );
};
