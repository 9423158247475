import { createApi } from '@reduxjs/toolkit/query/react';
import { environment } from '@energy-stacks/feature-config';
import { createBaseQuery } from '@energy-stacks/shared';
import { ChargingStationsModel } from './chargingStationsModel';
import { chargingStationsNormalizer } from './normalizers/chargingStationsNormalizer';
import { ChargingStationsPageEntry } from './chargingStationsPageEntry';
import { chargingStationDetailsNormalizer } from './normalizers/chargingStationDetailsNormalizer';
import { ChargingStationDetails } from './chargingStationDetailsModel';
import { EvseCapabilityDto } from './chargingStationDetailsDto';
import {
  ConnectorFormatDto,
  ConnectorPowerTypeDto,
  ConnectorStandardDto,
} from '@energy-stacks/data';

type GetChargingStationsBody = {
  pageNumber: number;
  pageSize: number;
  chargingHubUid?: string;
  searchValue?: string;
};

type AddChargingStationBody = {
  identityKey: string;
  name: string;
  chargingHubUid: string;
  coordinates: {
    latitude: string;
    longitude: string;
  };
  chargingStationVendor?: string;
  chargingStationVendorModel?: string;
};

type EditChargingStationBody = {
  name: string;
  chargingHubUid: string;
  coordinates: {
    latitude: string;
    longitude: string;
  };
  chargingStationVendor?: string;
  chargingStationVendorModel?: string;
};

type AddEvsePayload = {
  evseId: string;
  capabilities: EvseCapabilityDto[];
  connectors: {
    connectorStandard: ConnectorStandardDto;
    connectorFormat: ConnectorFormatDto;
    powerType: ConnectorPowerTypeDto;
    maxVoltage: number;
    maxAmperage: number;
    maxPower: number;
  }[];
};

type DeleteEvseParameters = {
  identityKey: string;
  chargingPointUid: string;
};

type EditEvsePayload = {
  identityKey: string;
  chargingPointUid: string;
  body: {
    evseId: string;
    capabilities: EvseCapabilityDto[];
    connectors: {
      connectorStandard: ConnectorStandardDto;
      connectorFormat: ConnectorFormatDto;
      powerType: ConnectorPowerTypeDto;
      maxVoltage: number;
      maxAmperage: number;
      maxPower: number;
    }[];
  };
};

export const chargingStationsApi = createApi({
  reducerPath: 'chargingStationsApi',
  baseQuery: createBaseQuery(`${environment.ocppServiceUrl}/charging-stations`),
  tagTypes: ['ChargingStations', 'ChargingStationDetails'],
  endpoints: (builder) => ({
    getChargingStations: builder.query<
      ChargingStationsModel,
      GetChargingStationsBody
    >({
      query: (searchParams) => ({
        url: '/search',
        params: searchParams,
      }),
      providesTags: ['ChargingStations'],
      transformResponse: (chargingStations: ChargingStationsPageEntry) => {
        return {
          totalElements: chargingStations.totalElements ?? 0,
          totalPages: chargingStations.totalPages ?? 0,
          content: chargingStationsNormalizer(chargingStations.content ?? []),
        };
      },
    }),
    getChargingStationDetails: builder.query<ChargingStationDetails, string>({
      query: (identityKey) => `/${identityKey}`,
      providesTags: (result) => [
        { type: 'ChargingStationDetails', id: result?.id },
      ],
      transformResponse: chargingStationDetailsNormalizer,
    }),
    addChargingStation: builder.mutation<void, AddChargingStationBody>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['ChargingStations'],
    }),
    editChargingStation: builder.mutation<
      void,
      { id: string; body: EditChargingStationBody }
    >({
      query: ({ id, body }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: (_, error, arg) =>
        !error
          ? [{ type: 'ChargingStationDetails', id: arg.id }, 'ChargingStations']
          : [],
    }),
    deleteChargingStation: builder.mutation<void, string>({
      query: (identityKey) => ({
        url: `/${identityKey}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ChargingStations'],
    }),
    addEvse: builder.mutation<
      void,
      { chargingStationId: string; body: AddEvsePayload }
    >({
      query: ({ chargingStationId, body }) => ({
        url: `/${chargingStationId}/charging-points`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (_res, _error, args) => [
        'ChargingStations',
        { type: 'ChargingStationDetails', id: args.chargingStationId },
      ],
    }),
    deleteEvse: builder.mutation<void, DeleteEvseParameters>({
      query: ({ identityKey, chargingPointUid }) => ({
        url: `/${identityKey}/charging-points/${chargingPointUid}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_res, error, args) =>
        !error
          ? [
              'ChargingStations',
              { type: 'ChargingStationDetails', id: args.identityKey },
            ]
          : [],
    }),
    editEvse: builder.mutation<void, EditEvsePayload>({
      query: ({ identityKey, chargingPointUid, body }) => ({
        url: `/${identityKey}/charging-points/${chargingPointUid}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_res, error, args) =>
        !error
          ? [
              'ChargingStations',
              { type: 'ChargingStationDetails', id: args.identityKey },
            ]
          : [],
    }),
  }),
});

export const {
  useGetChargingStationsQuery,
  useGetChargingStationDetailsQuery,
  useAddChargingStationMutation,
  useEditChargingStationMutation,
  useDeleteChargingStationMutation,
  useAddEvseMutation,
  useDeleteEvseMutation,
  useEditEvseMutation,
} = chargingStationsApi;
