import { Box } from '@mui/material';
import { MouseEventHandler } from 'react';

type ContainerIconProps = {
  children: React.ReactNode;
  backgroundColor?: string;
  borderColor?: string;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
};

export const ContainerIcon = ({
  children,
  backgroundColor,
  borderColor,
  ...rest
}: ContainerIconProps) => {
  return (
    <Box
      sx={{
        mr: 2,
        border: '1px solid',
        borderColor: borderColor ? borderColor : 'dark.light',
        borderRadius: 1.5,
        display: 'flex',
        alignItems: 'center',
        p: 1.25,
        width: 30,
        height: 30,
        backgroundColor,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
