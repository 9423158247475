import {
  ChargePointComponentModel,
  ReasonModel,
} from '@energy-stacks/obelis/feature-charging-station-reports-data';

export const defaultRenewedComponents = {
  chargePointComponent: '' as ChargePointComponentModel,
  reason: '' as ReasonModel,
  isNew: true,
};
