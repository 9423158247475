import { Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { FormStepperModel } from './formStep';
import { FormStepperId } from './formStepperId';
import {
  useAppSelector,
  useAppDispatch,
  setIsLastStep,
  handlePreviousStep,
  handleNextStep,
} from '@energy-stacks/store';
import { ESButton } from '@energy-stacks/core/ui';

interface FormStepperActionProps<TStepNames> {
  activeStep: number;
  formSteps: FormStepperModel<TStepNames>;
  disabled: boolean;
  submitFormId: FormStepperId;
  isLoading?: boolean;
}

export const FormStepperAction = <TStepNames,>({
  activeStep,
  formSteps,
  disabled,
  submitFormId,
  isLoading,
}: FormStepperActionProps<TStepNames>) => {
  const [t] = useTranslation('shared');
  const isLastStep = useAppSelector((state) => state.stepper.isLastStep);
  const dispatch = useAppDispatch();

  const lastStep = activeStep === Object.values(formSteps).length - 1;
  const firstStep = activeStep === 0;

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        {!firstStep ? (
          <ESButton
            data-testid="formStepperBackButton"
            color="primary"
            size="medium"
            variant="text"
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              if (lastStep) {
                dispatch(setIsLastStep(false));
              }
              dispatch(handlePreviousStep());
            }}
            loading={isLoading}
          >
            {t('back')}
          </ESButton>
        ) : null}
      </Grid>
      <Grid item>
        <ESButton
          data-testid="formStepperNextFinishButton"
          disabled={disabled}
          color="primary"
          size="medium"
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          onClick={() => {
            if (lastStep) {
              dispatch(setIsLastStep(true));
            } else {
              dispatch(handleNextStep(Object.keys(formSteps).length));
            }
          }}
          type={isLastStep ? 'submit' : 'button'}
          form={submitFormId}
          loading={isLoading}
        >
          {lastStep ? t('finish') : t('next')}
        </ESButton>
      </Grid>
    </Grid>
  );
};
