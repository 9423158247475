import { SidebarGroup } from '@energy-stacks/core/sidebar';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';
import { IconChargingPile } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

export const ChargingStationsDrawerItem = () => {
  const { t } = useTranslation('chargingStations');

  return (
    <SidebarGroup
      testId="chargingStationsSidebarButton"
      groupInitialPath={ObelisRoutes.ChargingStations}
      groupIcon={<IconChargingPile />}
      groupLabel={t('sidebarItem')}
    ></SidebarGroup>
  );
};
