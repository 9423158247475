import {
  ESDialogActionButton,
  ESDialogActions,
  ESDialogContent,
  ESDialogTitle,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import {
  ChargingStation,
  chargingStationsApiErrors,
  useDeleteChargingStationMutation,
} from '@energy-stacks/obelis/feature-charging-stations-data';
import { Dialog } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type DeleteChargingStationDialogProps = {
  chargingStationDetails: {
    chargingStationId: ChargingStation['id'];
    chargingStationName: ChargingStation['name'];
    hasEVSEs: boolean;
  };
  onClose: () => void;
};

export const DeleteChargingStationDialog: FC<
  DeleteChargingStationDialogProps
> = ({ chargingStationDetails, onClose }) => {
  const { chargingStationId, chargingStationName, hasEVSEs } =
    chargingStationDetails;
  const [t] = useTranslation('chargingStations');
  const { showSnackbar } = useESSnackbar();

  const [deleteChargingStation, { isLoading }] =
    useDeleteChargingStationMutation();

  const handleDeleteChargingStation = useCallback(() => {
    return deleteChargingStation(chargingStationId)
      .unwrap()
      .then(() => {
        showSnackbar(
          'success',
          'deleteChargingStationSuccess',
          'chargingStations'
        );
        onClose();
      })
      .catch((error) => {
        const errorCode = chargingStationsApiErrors[error.data?.errorCode];
        showSnackbar(
          'error',
          errorCode ? `chargingStationsApiErrors.${errorCode}` : undefined,
          'chargingStations'
        );
        onClose();
      });
  }, [deleteChargingStation, chargingStationId, showSnackbar, onClose]);

  return hasEVSEs ? (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <ESDialogTitle>
        {t('cannotDeleteChargingStationTitle', { name: chargingStationName })}
      </ESDialogTitle>
      <ESDialogContent>
        {t('cannotDeleteChargingStationMessage')}
      </ESDialogContent>
      <ESDialogActions>
        <ESDialogActionButton variant="contained" onClick={onClose}>
          {t('cannotDeleteChargingStationButton')}
        </ESDialogActionButton>
      </ESDialogActions>
    </Dialog>
  ) : (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <ESDialogTitle>
        {t('confirmDeleteChargingStationTitle', { name: chargingStationName })}
      </ESDialogTitle>
      <ESDialogContent>
        {t('confirmDeleteChargingStationMessage')}
      </ESDialogContent>
      <ESDialogActions>
        <ESDialogActionButton
          variant="text"
          color="error"
          disabled={isLoading}
          onClick={onClose}
        >
          {t('cancelDeleteChargingStationButton')}
        </ESDialogActionButton>
        <ESDialogActionButton
          variant="contained"
          color="error"
          loading={isLoading}
          onClick={handleDeleteChargingStation}
        >
          {t('confirmDeleteChargingStationButton')}
        </ESDialogActionButton>
      </ESDialogActions>
    </Dialog>
  );
};
