import {
  ESAddItemButton,
  usePagination,
  useSort,
} from '@energy-stacks/core/ui';
import {
  ESPage,
  ESPageLoadingIndicator,
  RefetchOnError,
  useDocumentTitle,
} from '@energy-stacks/shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChargingStations } from './useChargingStations';
import { ChargingStationsTable } from './ChargingStationsTable';
import { Outlet, useNavigate } from 'react-router-dom';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';
import { useTheme } from '@mui/material';

export const ChargingStationsPage = () => {
  const [t] = useTranslation('chargingStations');
  const { spacing } = useTheme();
  const navigate = useNavigate();
  const pageTitle = t('title');
  useDocumentTitle(pageTitle);
  const { pagination, setPagination } = usePagination();
  const { sorting, setSorting } = useSort();
  const [search, setSearch] = useState('');

  const { chargingStations, isLoading, isError, refetch } = useChargingStations(
    {
      search,
      pagination: {
        page: pagination.pageIndex,
        size: pagination.pageSize,
      },
    }
  );

  return (
    <ESPage title={pageTitle}>
      {isLoading && <ESPageLoadingIndicator />}
      {isError && <RefetchOnError onRefetch={refetch} />}
      {chargingStations ? (
        <ChargingStationsTable
          testId="chargingStationsTable"
          chargingStations={chargingStations}
          search={search}
          setSearch={setSearch}
          pagination={pagination}
          onPaginationChange={setPagination}
          sorting={sorting}
          onSortingChange={setSorting}
          onRowClick={(chargingStationId) =>
            navigate(
              `${chargingStationId}/${ObelisRoutes.ChargingStationDetailsGeneralTab}`
            )
          }
          action={
            <ESAddItemButton
              title={t('addChargingStationButton')}
              onClick={() =>
                navigate(ObelisRoutes.AddChargingStation, {
                  state: {
                    from: ObelisRoutes.ChargingStations,
                  },
                })
              }
              style={{
                marginLeft: spacing(4),
              }}
            />
          }
        />
      ) : null}
      <Outlet />
    </ESPage>
  );
};
