import {
  ESPage,
  ESPageLoadingIndicator,
  RefetchOnError,
  useDocumentTitle,
} from '@energy-stacks/shared';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChargingHubsMap } from './ChargingHubsMap';
import { useChargingHubs } from './useChargingHubs';
import { ChargingHubsTable } from './ChargingHubsTable';
import { usePagination, useSort } from '@energy-stacks/core/ui';
import { useState } from 'react';
import { Box } from '@mui/material';

export const ChargingHubsPage = () => {
  const { sorting, setSorting } = useSort();
  const { pagination, setPagination } = usePagination();
  const [search, setSearch] = useState('');
  const { t } = useTranslation('chargingHubs');
  useDocumentTitle(t('sidebarItem'));

  const { chargingHubsResponse, refetch, isLoading, isError } = useChargingHubs(
    {
      search,
      pagination: {
        page: pagination.pageIndex,
        size: pagination.pageSize,
      },
    }
  );

  return (
    <ESPage title={t('sidebarItem')}>
      {isLoading && <ESPageLoadingIndicator />}
      {isError && <RefetchOnError onRefetch={refetch} />}
      {!isLoading && !isError ? (
        <>
          <Box mb={6} height={300}>
            <ChargingHubsMap
              chargingHubs={chargingHubsResponse?.chargingHubs}
            />
          </Box>
          <ChargingHubsTable
            chargingHubs={chargingHubsResponse}
            search={search}
            setSearch={setSearch}
            onPaginationChange={setPagination}
            sorting={sorting}
            pagination={pagination}
            onSortingChange={setSorting}
          />
        </>
      ) : null}

      <Outlet />
    </ESPage>
  );
};
