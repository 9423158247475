import { reportFacilityTypeDtoToModelMap } from '../../reportFacilityTypeDtoToModelMap';
import { RawFacilityDamagesReportDto } from '../rawFacilityDamagesReportDto';
import { RawFacilityDamagesReportModel } from '../rawFacilityDamagesReportModel';

export const rawFacilityDamagesReportsNormalizer = (
  rawFacilityDamagesReportDto: RawFacilityDamagesReportDto
): RawFacilityDamagesReportModel => {
  const {
    uid,
    chargingHubUid,
    facilityType,
    emergenceDate,
    fixDate,
    forecastDate,
  } = rawFacilityDamagesReportDto;

  return {
    uid: uid ?? undefined,
    chargingHubUid: chargingHubUid ?? '',
    facilityType: facilityType
      ? reportFacilityTypeDtoToModelMap[facilityType]
      : undefined,
    emergenceDate: emergenceDate ? emergenceDate : undefined,
    fixDate: fixDate ? fixDate : undefined,
    forecastDate: forecastDate ? forecastDate : undefined,
  };
};
