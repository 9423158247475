import { useParams } from 'react-router-dom';
import { useGetProcessedRatingReportByReportUidQuery } from '@energy-stacks/obelis/feature-charging-hub-reports-data';

export const useProcessedRatingReport = (isEditMode: boolean) => {
  const { uid } = useParams<{ uid: string }>();

  const { data, isLoading, error, isSuccess, refetch, isError } =
    useGetProcessedRatingReportByReportUidQuery(
      {
        uid: uid ?? '',
      },
      {
        skip: !isEditMode,
      }
    );

  return {
    data,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  };
};
