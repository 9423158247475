export const reasonsDto = [
  'EMERGENCY_STOP',
  'EV_DISCONNECTED',
  'HARDRESET',
  'LOCAL',
  'OTHER',
  'POWERLOSS',
  'REBOOT',
  'REMOTE',
  'SOFT_RESET',
  'UNLOCK_COMMAND',
  'DEAUTHORIZED',
] as const;

export type ReasonDto = (typeof reasonsDto)[number];
