import { ConnectorIcon } from '@energy-stacks/broker/shared';
import { ConnectorDetails } from '@energy-stacks/obelis/feature-charging-stations-data';
import {
  ESTooltip,
  formatDateTime,
  timeAgoFromDate,
} from '@energy-stacks/shared';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NO_VALUE } from './ChargingStationDetailsGeneralCard';

type ChargingStationDetailsConnectorCardProps = {
  connector: ConnectorDetails;
};

export const ChargingStationDetailsConnectorCard: FC<
  ChargingStationDetailsConnectorCardProps
> = ({ connector }) => {
  const [t] = useTranslation('chargingStations');
  const [tSharedChargingStations] = useTranslation('sharedChargingStations');

  return (
    <Paper
      sx={{
        backgroundColor: 'grey.50',
        borderRadius: 3,
        mt: 4,
        overflowY: 'hidden',
        padding: 4,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          borderBottomColor: 'grey.200',
          borderBottomStyle: 'solid',
          borderBottomWidth: 0.25,
          display: 'flex',
          justifyContent: 'space-between',
          pb: 3.25,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Box sx={{ px: 2 }}>
            <ConnectorIcon connectorType={connector.standard} size={34} />
          </Box>
          <Typography
            sx={({ spacing }) => ({
              fontSize: spacing(3.5),
              fontWeight: 700,
              ml: 4,
            })}
          >
            {connector.id}
          </Typography>
        </Box>
        <ESTooltip title={formatDateTime(connector.lastUpdated)}>
          <Typography
            sx={({ spacing }) => ({
              color: 'grey.600',
              fontSize: spacing(2.5),
              fontWeight: 400,
              lineHeight: spacing(4),
            })}
          >
            {`${t(`evseConnectorCardLastUpdatedLabel`)}: ${timeAgoFromDate(
              connector.lastUpdated
            )}`}
          </Typography>
        </ESTooltip>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          pt: 2.75,
        }}
      >
        <StackValuePair
          label={t('connectorCardContentLabels.type')}
          value={
            connector.standard &&
            tSharedChargingStations(`connectorTypes.${connector.standard}`)
          }
        />
        <StackValuePair
          label={t('connectorCardContentLabels.format')}
          value={
            connector.format &&
            tSharedChargingStations(`connectorFormats.${connector.format}`)
          }
        />
        <StackValuePair
          label={t('connectorCardContentLabels.powerType')}
          value={
            connector.powerType &&
            tSharedChargingStations(`powerTypes.${connector.powerType}`)
          }
        />
        <StackValuePair
          label={t('connectorCardContentLabels.maxVoltage')}
          value={connector.maxVoltage && `${connector.maxVoltage} V`}
        />
        <StackValuePair
          label={t('connectorCardContentLabels.maxAmperage')}
          value={connector.maxAmperage && `${connector.maxAmperage} A`}
        />
        <StackValuePair
          label={t('connectorCardContentLabels.power')}
          value={connector.maxPower && formatMaxPower(connector.maxPower)}
        />
      </Box>
    </Paper>
  );
};

const StackValuePair = ({
  label,
  value,
}: {
  label: string;
  value: string | number | undefined;
}) => {
  return (
    <Stack>
      <Typography variant="body2" color="grey.600" fontWeight="400">
        {label}
      </Typography>
      <Typography variant="body2" color="grey.900" fontWeight="500">
        {value ?? NO_VALUE}
      </Typography>
    </Stack>
  );
};

const formatMaxPower = (powerInWatts: number) => {
  return powerInWatts < 1000
    ? `${powerInWatts} W`
    : `${powerInWatts / 1000} kW`;
};
