import { useGetProcessedRenewalReportByUidQuery } from '@energy-stacks/obelis/feature-charging-station-reports-data';
import { useParams } from 'react-router-dom';

export const useRenewalProcessedReport = (isEditMode: boolean) => {
  const { uid } = useParams<{ uid: string }>();

  const {
    data: processedReport,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  } = useGetProcessedRenewalReportByUidQuery(
    {
      uid: uid ?? '',
    },
    {
      skip: !isEditMode,
    }
  );

  return {
    processedReport,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  };
};
