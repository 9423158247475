import { SystemProcessedReportDto } from '../systemProcessedReportDto';
import { SystemProcessedReport } from '../systemProcessedReportModel';
import { SystemReportTypeDto } from '../systemReportTypeDto';
import { SystemReportTypeModel } from '../systemReportTypeModel';

export const systemProcessedReportsNormalizer = (
  systemProcessedReportsDto: SystemProcessedReportDto[]
): SystemProcessedReport[] => {
  return systemProcessedReportsDto.map((systemProcessedReportsDto) => {
    const {
      uid,
      reportType,
      reportDate,
      reportedBy,
      modifiedBy,
      lastModifiedDate,
    } = systemProcessedReportsDto;

    return {
      uid: uid,
      reportType: systemReportTypeDtoToModelMap[reportType],
      reportDate: reportDate,
      reportedBy: reportedBy,
      modifiedBy: modifiedBy,
      lastModifiedDate: lastModifiedDate,
    };
  });
};

export const systemReportTypeDtoToModelMap: Record<
  SystemReportTypeDto,
  SystemReportTypeModel
> = {
  HOTLINE_RATING: 'hotlineRating',
  NON_DISCRIMINATORY_ACCESS: 'nonDiscriminatoryAccess',
} as const;
