import { FacilityTypeDto } from './facilityTypeDto';
import { FacilityTypeModel } from './facilityTypeModel';

export const reportFacilityTypeModelToDtoMap: Record<
  FacilityTypeModel,
  FacilityTypeDto
> = {
  roof: 'ROOF',
  foodService: 'FOOD_SERVICE',
  seating: 'SEATING',
  sanitation: 'SANITATION',
  airPressure: 'VEHICLE_SUPPLIES_AIR_PRESSURE',
  windshieldWiperSystem: 'VEHICLE_SUPPLIES_WINDSHIELD_WIPER_SYSTEM',
  vacuumCleaner: 'VEHICLE_SUPPLIES_VACUUM_CLEANER',
  greenRoof: 'GREEN_ROOF',
  photovoltaicSystem: 'PHOTOVOLTAIC_SYSTEM',
} as const;
