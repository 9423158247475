import { EvseCapabilityDto } from './chargingStationDetailsDto';
import { CapabilityModel } from './chargingStationDetailsModel';

export const capabilitiesToPayload = (capabilities: CapabilityModel[]) => {
  return capabilities.map((c) => mapCapabilitiesToPayload[c]);
};

const mapCapabilitiesToPayload: Record<CapabilityModel, EvseCapabilityDto> = {
  chargingProfileCapable: 'CHARGING_PROFILE_CAPABLE',
  chargingPreferencesCapable: 'CHARGING_PREFERENCES_CAPABLE',
  chipCardSupport: 'CHIP_CARD_SUPPORT',
  contactlessCardSupport: 'CONTACTLESS_CARD_SUPPORT',
  creditCardPayable: 'CREDIT_CARD_PAYABLE',
  debitCardPayable: 'DEBIT_CARD_PAYABLE',
  pedTerminal: 'PED_TERMINAL',
  remoteStartStopCapable: 'REMOTE_START_STOP_CAPABLE',
  reservable: 'RESERVABLE',
  rfidReader: 'RFID_READER',
  tokenGroupCapable: 'TOKEN_GROUP_CAPABLE',
  unlockCapable: 'UNLOCK_CAPABLE',
  startSessionConnectorRequired: 'START_SESSION_CONNECTOR_REQUIRED',
};
