import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';
import { JobStatus } from './types/jobStatuses';
import { ESTooltip } from '@energy-stacks/shared';
import { Link } from 'react-router-dom';
import { FleetRoutes } from './FleetRoutes';
import { PropsWithChildren } from 'react';

interface JobStatusChipProps {
  status: JobStatus | undefined;
  tourId?: string;
}

interface JobStatusInfo {
  bgColor: string;
  color: string;
  hoverBgColor?: string;
  hoverColor?: string;
}

const jobStatusToChipInfoMap: Record<JobStatus, JobStatusInfo> = {
  DONE: {
    bgColor: 'success.light',
    color: 'success.dark',
    hoverBgColor: 'success.dark',
    hoverColor: 'success.light',
  },
  ENROUTE: {
    bgColor: 'primary.light',
    color: 'primary.dark',
    hoverBgColor: 'primary.800',
    hoverColor: 'primary.light',
  },
  FAILED: {
    bgColor: 'orange.light',
    color: 'orange.dark',
  },
  CANCELLED: {
    bgColor: 'orange.light',
    color: 'orange.dark',
  },
  PLANNED: {
    bgColor: 'secondary.light',
    color: 'secondary.main',
    hoverBgColor: 'secondary.main',
    hoverColor: 'secondary.light',
  },
  TO_DO: {
    bgColor: 'cyan.50',
    color: 'cyan.500',
  },
  PENDING: {
    bgColor: 'gray.200',
    color: 'black',
  },
};

export const JobStatusChip: React.FC<JobStatusChipProps> = ({
  status,
  tourId,
}) => {
  const [t] = useTranslation('fleetShared');

  if (!status) {
    return null;
  }
  const { bgColor, color, hoverBgColor, hoverColor } =
    jobStatusToChipInfoMap[status];

  return (
    <JobStatusChipWrapper tourId={tourId}>
      <Chip
        size="medium"
        label={t(`jobStatuses.${status}`)}
        sx={{
          backgroundColor: bgColor,
          color,
          fontWeight: 500,
          cursor: 'inherit',
          '&:hover':
            tourId && hoverBgColor && hoverColor
              ? {
                  backgroundColor: hoverBgColor,
                  color: hoverColor,
                }
              : undefined,
        }}
      />
    </JobStatusChipWrapper>
  );
};

const JobStatusChipWrapper = ({
  tourId,
  children,
}: PropsWithChildren & { tourId: string | undefined }) => {
  const [t] = useTranslation('fleetShared');

  if (!tourId) {
    return <>{children}</>;
  }

  return (
    <ESTooltip title={t('jobStatusChipTooltipText')}>
      <Link to={`${FleetRoutes.Tours}/${tourId}`}>{children}</Link>
    </ESTooltip>
  );
};
