import { useParams } from 'react-router-dom';
import { useGetSystemHotlineRatingRawReportQuery } from '@energy-stacks/obelis/feature-system-reports-data';

export const useSystemHotlineRatingRawReport = (isEditMode: boolean) => {
  const { selectedMonth } = useParams<{ selectedMonth: string }>();

  const { data, isLoading, error, isSuccess, refetch, isError } =
    useGetSystemHotlineRatingRawReportQuery(
      {
        monthYear: selectedMonth ?? '',
      },
      {
        skip: isEditMode,
      }
    );

  return {
    data,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  };
};
