import { ESDialogContent, useESSnackbar } from '@energy-stacks/core/ui';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';
import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Typography,
} from '@mui/material';
import { IconX } from '@tabler/icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorApiResponse, useCloseDialogPrompt } from '@energy-stacks/shared';
import { EditChargingStationForm } from './EditChargingStationForm';
import { useChargingStationDetails } from '../details/useChargingStationDetails';
import { chargingStationsApiErrors } from '@energy-stacks/obelis/feature-charging-stations-data';

export const EditChargingStation = () => {
  const [t] = useTranslation('chargingStations');
  const navigate = useNavigate();
  const location = useLocation();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { showSnackbar } = useESSnackbar();

  const { chargingStationDetails, isLoading, error } =
    useChargingStationDetails();

  const closeDialog = useCallback(() => {
    if (location.state?.from) {
      navigate(-1);
    } else {
      navigate(ObelisRoutes.ChargingStations);
    }
  }, [location.state, navigate]);

  const handleCloseDialog = useCloseDialogPrompt({
    shouldPrompt: isFormDirty,
    onClose: closeDialog,
  });

  useEffect(() => {
    if (error) {
      if ('data' in error) {
        const response = error.data as ErrorApiResponse;
        showSnackbar(
          'error',
          `chargingStationsApiErrors.${
            chargingStationsApiErrors[response.errorCode]
          }`,
          'chargingStations'
        );
      } else {
        showSnackbar('error');
      }
      closeDialog();
    }
  }, [error, showSnackbar, closeDialog]);

  return (
    <Dialog open onClose={handleCloseDialog} maxWidth="lg" fullWidth>
      <Box
        sx={{
          alignItems: 'center',
          borderBottomColor: 'grey.200',
          borderBottomStyle: 'solid',
          borderBottomWidth: 0.25,
          display: 'flex',
          justifyContent: 'space-between',
          padding: 10,
          pb: 4,
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: 700 }}>
          {t('editChargingStationDialogTitle')}
        </Typography>
        <IconButton
          data-testid="editStationCloseModalButton"
          onClick={handleCloseDialog}
        >
          <IconX />
        </IconButton>
      </Box>
      <ESDialogContent>
        {(isLoading || !chargingStationDetails) && (
          <Box
            height={583}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={50} />
          </Box>
        )}
        {chargingStationDetails && (
          <EditChargingStationForm
            chargingStationDetails={chargingStationDetails}
            onFormDirty={setIsFormDirty}
            closeDialog={closeDialog}
          />
        )}
      </ESDialogContent>
    </Dialog>
  );
};
