import { ConnectorFormatDto } from '../dtos/connectorFormatDto';
import { ConnectorFormat } from '../models/connectorFormatModel';

export const connectorFormatDtoToModelMap: Record<
  ConnectorFormatDto,
  ConnectorFormat
> = {
  SOCKET: 'SOCKET',
  CABLE: 'CABLE',
} as const;

export const connectorFormatModelToDtoMap: Record<
  ConnectorFormat,
  ConnectorFormatDto
> = {
  SOCKET: 'SOCKET',
  CABLE: 'CABLE',
} as const;
