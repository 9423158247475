import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Box, Grid } from '@mui/material';
import { ESTextField, ESVirtualizedAutocomplete } from '@energy-stacks/core/ui';
import { useTranslation } from 'react-i18next';
import { EditableMap } from '@energy-stacks/shared';
import { EditChargingHubFormData } from './EditChargingHubForm';
import { states } from '@energy-stacks/obelis/shared';
import chargingHubPOIMarker from '../icons/chargingHubPOIMarker.svg';

interface EditChargingHubGeneralDetailsFormProps {
  onSetIsMapReady: (value: boolean) => void;
  defaultValues: EditChargingHubFormData | undefined;
}

export const EditChargingHubGeneralDetailsForm: React.FC<
  EditChargingHubGeneralDetailsFormProps
> = ({ defaultValues, onSetIsMapReady }) => {
  const [t] = useTranslation('chargingHubs');

  const {
    formState: { errors },
    control,
    setValue,
    trigger,
  } = useFormContext();

  const watchLongitude = useWatch({
    name: 'coordinates.longitude',
    control,
  });
  const watchLatitude = useWatch({
    name: 'coordinates.latitude',
    control,
  });

  const handleCoordsChange = (
    newCoords: EditChargingHubFormData['coordinates']
  ) => {
    setValue('coordinates.latitude', newCoords.latitude.slice(0, 10), {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue('coordinates.longitude', newCoords.longitude.slice(0, 11), {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <ESTextField
                    disabled
                    label={t('id')}
                    type="text"
                    onChange={onChange}
                    onBlur={onBlur}
                    error={Boolean(errors['id'])}
                    helperText={
                      Boolean(errors['id']) && t(`${errors['id']?.message}`)
                    }
                    value={value}
                    inputProps={{
                      'data-testid': 'chargingHubIdTextField',
                    }}
                  />
                );
              }}
              name="id"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <ESTextField
                    label={t('name')}
                    type="text"
                    required
                    onChange={onChange}
                    onBlur={onBlur}
                    error={Boolean(errors['name'])}
                    helperText={
                      Boolean(errors['name']) && t(`${errors['name']?.message}`)
                    }
                    value={value}
                    inputProps={{
                      'data-testid': 'chargingHubNameTextField',
                    }}
                  />
                );
              }}
              name="name"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <ESTextField
                    label={t('streetAndNumber')}
                    type="text"
                    required
                    onChange={onChange}
                    onBlur={onBlur}
                    error={Boolean(errors['address'])}
                    helperText={
                      Boolean(errors['address']) &&
                      t(`${errors['address']?.message}`)
                    }
                    value={value}
                    inputProps={{
                      'data-testid': 'chargingHubAddressTextField',
                    }}
                  />
                );
              }}
              name="address"
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <ESTextField
                    inputProps={{
                      'data-testid': `chargingHubPostalCodeTextField`,
                    }}
                    required
                    label={t('postalCode')}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={Boolean(errors.postalCode)}
                    helperText={
                      Boolean(errors.postalCode) &&
                      t(`${errors.postalCode?.message}`)
                    }
                  />
                );
              }}
              name={'postalCode'}
            />
          </Grid>
          <Grid item xs={8}>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <ESTextField
                    inputProps={{
                      'data-testid': `chargingHubCityTextField`,
                    }}
                    label={t('city')}
                    required
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={Boolean(errors.city)}
                    helperText={
                      Boolean(errors.city) && t(`${errors.city?.message}`)
                    }
                  />
                );
              }}
              name={'city'}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              render={({ field: { value, onChange, onBlur } }) => (
                <ESVirtualizedAutocomplete
                  data-testid="chargingHubStateTextField"
                  testId="chargingHubStateTextField"
                  label={t('state')}
                  required
                  value={{
                    label: value?.label || '',
                    value: value?.value || '',
                  }}
                  onBlur={onBlur}
                  options={states.map((state) => {
                    return {
                      label: state || '',
                      value: state || '',
                    };
                  })}
                  onChange={(_, value) => {
                    if (Array.isArray(value)) {
                      return;
                    }
                    const newValue = value
                      ? {
                          label: value.label,
                          value: value.value,
                        }
                      : { label: '', value: '' };
                    onChange(newValue);
                  }}
                />
              )}
              name="state"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Box height={370}>
              <EditableMap
                icon={chargingHubPOIMarker}
                center={{
                  longitude: watchLongitude || '15',
                  latitude: watchLatitude || '52',
                }}
                zoom={
                  watchLatitude !== defaultValues?.coordinates.latitude ||
                  watchLongitude !== defaultValues?.coordinates.longitude
                    ? 13
                    : 3
                }
                onMapReady={() => onSetIsMapReady(true)}
                onCoordsChange={handleCoordsChange}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <ESTextField
                    value={value}
                    label={t('locationDialogLatitudeLabel')}
                    type="text"
                    required
                    onChange={(event) => {
                      const isValid =
                        !isNaN(Number(event?.target.value)) ||
                        event?.target.value === '-';
                      if (event === undefined || isValid) {
                        trigger('coordinates.longitude');
                        onChange(event);
                      }
                    }}
                    onBlur={onBlur}
                    error={Boolean(errors['coordinates']?.latitude)}
                    helperText={
                      Boolean(errors['coordinates']?.latitude) &&
                      t(`${errors['coordinates']?.latitude?.message}`)
                    }
                    inputProps={{
                      'data-testid': 'addChargingHubLatitudeTextField',
                    }}
                  />
                );
              }}
              name="coordinates.latitude"
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <ESTextField
                    value={value}
                    required
                    label={t('locationDialogLongitudeLabel')}
                    type="text"
                    onChange={(event) => {
                      const isValid =
                        !isNaN(Number(event?.target.value)) ||
                        event?.target.value === '-';
                      if (event === undefined || isValid) {
                        trigger('coordinates.latitude');
                        onChange(event);
                      }
                    }}
                    onBlur={onBlur}
                    error={Boolean(errors['coordinates']?.longitude)}
                    helperText={
                      Boolean(errors['coordinates']?.longitude) &&
                      t(`${errors['coordinates']?.longitude?.message}`)
                    }
                    inputProps={{
                      'data-testid': 'addChargingHubLongitudeTextField',
                    }}
                  />
                );
              }}
              name="coordinates.longitude"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
