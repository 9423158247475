import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePagination, useSort } from '@energy-stacks/core/ui';
import {
  ChargingStationsTable,
  useChargingStations,
} from '@energy-stacks/obelis/feature-charging-stations';
import { ESPageLoadingIndicator, RefetchOnError } from '@energy-stacks/shared';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';

export const ChargingHubDetailsChargingStationsTab = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { pagination, setPagination } = usePagination();
  const { sorting, setSorting } = useSort();
  const [search, setSearch] = useState('');
  const { chargingStations, isLoading, isError, refetch } = useChargingStations(
    {
      chargingHubUid: id,
      search,
      pagination: {
        page: pagination.pageIndex,
        size: pagination.pageSize,
      },
    }
  );

  return (
    <>
      {isLoading && <ESPageLoadingIndicator />}
      {isError && <RefetchOnError onRefetch={refetch} />}
      {chargingStations ? (
        <ChargingStationsTable
          chargingStations={chargingStations}
          search={search}
          setSearch={setSearch}
          pagination={pagination}
          onPaginationChange={setPagination}
          sorting={sorting}
          onSortingChange={setSorting}
          onRowClick={(chargingStationId) =>
            navigate(
              `${ObelisRoutes.ChargingStations}/${chargingStationId}/${ObelisRoutes.ChargingStationDetailsGeneralTab}`
            )
          }
        />
      ) : null}
    </>
  );
};
