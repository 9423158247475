export const facilityTypeModel = [
  'roof',
  'foodService',
  'seating',
  'sanitation',
  'airPressure',
  'windshieldWiperSystem',
  'vacuumCleaner',
  'greenRoof',
  'photovoltaicSystem',
] as const;

export type FacilityTypeModel = (typeof facilityTypeModel)[number];
