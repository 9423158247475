import { additionalCostsReportDtoToModelNormalizer } from '../../normalizers/additionalCostsReportToPayload';
import { nonDiscriminatoryAccessReportContractualBasisDtoToModelMap } from '../../transformers/nonDiscriminatoryAccessReportContractualBasisMaps';
import { ProcessedNonDiscriminatoryAccessReportDto } from '../processedNonDiscriminatoryAccessReportDto';
import { ProcessedNonDiscriminatoryAccessReportModel } from '../processedNonDiscriminatoryAccessReportModel';

export const processedNonDiscriminatoryAccessReportNormalizer = (
  processedNonDiscriminatoryAccessReportDto: ProcessedNonDiscriminatoryAccessReportDto
): ProcessedNonDiscriminatoryAccessReportModel => {
  const {
    uid,
    monthYear,
    providerGroup,
    cost,
    additionalCosts,
    validFrom,
    validTo,
    contractualBasis,
    reasoningDifferentCosts,
    lastModifiedDate,
  } = processedNonDiscriminatoryAccessReportDto;

  return {
    uid: uid ?? '',
    monthYear: monthYear,
    providerGroup: providerGroup,
    cost: String(cost),
    additionalCosts: additionalCostsReportDtoToModelNormalizer(
      additionalCosts ?? []
    ),
    validFrom: validFrom,
    validTo: validTo,
    contractualBasis:
      nonDiscriminatoryAccessReportContractualBasisDtoToModelMap[
        contractualBasis
      ],
    reasoningDifferentCosts: reasoningDifferentCosts ?? '',
    lastModifiedDate: lastModifiedDate ?? '',
  };
};
