import { isAfter, setMonth, setYear } from 'date-fns';

export const getReportDefaultDate = (
  reportDateString: string | undefined,
  paramDateString: string | undefined,
  maxDate: Date
) => {
  if (reportDateString) {
    return monthYearStringToDate(reportDateString);
  } else if (
    paramDateString &&
    paramDateString.match(/^(0[1-9]|1[0-2])-\d{4}$/)
  ) {
    const paramDate = monthYearStringToDate(paramDateString);
    return !isAfter(paramDate, maxDate) ? paramDate : maxDate;
  } else {
    return maxDate;
  }
};

const monthYearStringToDate = (dateString: string): Date => {
  const [month, year] = dateString.split('-').map((str) => Number(str));
  return setYear(setMonth(new Date(), month - 1), year);
};
