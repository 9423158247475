import { ESDialogTitle } from '@energy-stacks/core/ui';
import { useCloseDialogPrompt } from '@energy-stacks/shared';
import { Dialog, IconButton, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { AddChargingHubForm } from './AddChargingHubForm';
import { useDispatch } from 'react-redux';
import { addChargingHubSteps } from './addChargingHubSteps';
import { resetStepper, useAppSelector } from '@energy-stacks/store';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';
import { useLocation, useNavigate } from 'react-router-dom';

export const AddChargingHub = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [t] = useTranslation('chargingHubs');
  const activeStep = useAppSelector((state) => state.stepper.stepperActiveStep);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { palette } = useTheme();

  useEffect(() => {
    return () => {
      dispatch(resetStepper());
    };
  }, [dispatch]);

  const closeDialog = useCallback(() => {
    if (location.state?.from) {
      navigate(-1);
    } else {
      navigate(ObelisRoutes.ChargingHubs);
    }
  }, [location.state, navigate]);

  const handleCloseDialog = useCloseDialogPrompt({
    shouldPrompt: isFormDirty,
    onClose: closeDialog,
  });

  return (
    <Dialog open onClose={handleCloseDialog} maxWidth="lg" fullWidth>
      <ESDialogTitle
        data-testid="addChargingHubDialogTitle"
        sx={{
          borderBottom: `1px solid ${palette.grey[300]}`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: 4,
        }}
        justifyContent="space-between"
      >
        {t('addChargingHub')}
        <IconButton
          data-testid="addStationCloseModalButton"
          onClick={handleCloseDialog}
        >
          <IconX />
        </IconButton>
      </ESDialogTitle>
      <AddChargingHubForm
        display={addChargingHubSteps[activeStep].name}
        activeStep={activeStep}
        onFormDirty={setIsFormDirty}
        closeDialog={closeDialog}
      />
    </Dialog>
  );
};
