import * as yup from 'yup';
import { numberValidityTest } from '@energy-stacks/shared';
import { isAfter, isValid } from 'date-fns';

const additionalCostsValidationSchema = yup.object().shape({
  cost: numberValidityTest.required('additionalCostIsRequired'),
  description: yup.string().required('additionalCostDescriptionIsRequired'),
});

export const systemNonDiscriminatoryAccessReportValidationSchema = yup
  .object()
  .shape({
    monthYear: yup.date().required('monthIsRequired'),
    providerGroup: yup
      .string()
      .required('providerGroupIsRequired')
      .matches(/^[A-Z]{2}[A-Z0-9]{3}$/, 'mustBeInFormatProviderGroup')
      .max(5, 'providerGroupTooLong'),
    cost: numberValidityTest.required('costFieldIsRequired'),
    validFrom: yup
      .date()
      .nullable()
      .typeError('invalidValidFromDate')
      .required('validFromDateFieldIsRequired'),
    validTo: yup.date().when('validFrom', {
      is: (value: Date | null) => isValid(value),
      then: yup
        .date()
        .nullable()
        .typeError('invalidValidToDate')
        .required('validToDateFieldIsRequired')
        .test(
          'isAfterValidFrom',
          'validToDateIsBeforeOrEqualToValidFrom',
          (validTo, { parent: { validFrom } }) => {
            if (!validTo) {
              return true;
            }
            return isAfter(validTo, validFrom);
          }
        ),
      otherwise: yup
        .date()
        .nullable()
        .typeError('invalidValidToDate')
        .required('validToDateFieldIsRequired'),
    }),
    reasoningDifferentCosts: yup
      .string()
      .notRequired()
      .max(255, 'reasoningTooLong'),
    contractualBasis: yup.string().required('contractualBasisIsRequired'),
    additionalCosts: yup
      .array()
      .of(additionalCostsValidationSchema)
      .nullable()
      .when('$additionalCostsExists', {
        is: true,
        otherwise: yup.array().notRequired(),
      }),
  });
