import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ESPageLoadingIndicator, RefetchOnError } from '@energy-stacks/shared';
import { ProcessedReportsTable } from '@energy-stacks/obelis/feature-charging-hub-reports';
import { usePagination, useSort } from '@energy-stacks/core/ui';
import { format } from 'date-fns';
import { useChargingHubDetailsReports } from './useChargingHubDetailsReports';

export const ChargingHubDetailsReportsTab = () => {
  const { id } = useParams<{ id: string }>();
  const [monthYearFilterValue, setMonthYearFilterValue] = useState(new Date());
  const { sorting, setSorting } = useSort();
  const { pagination, setPagination } = usePagination();

  const { processedReports, isLoading, isError, refetch } =
    useChargingHubDetailsReports({
      chargingHubUid: id ?? '',
      monthYear: format(monthYearFilterValue, 'MM-yyyy'),
      pagination: {
        page: pagination.pageIndex,
        size: pagination.pageSize,
      },
    });

  return (
    <>
      {isLoading && <ESPageLoadingIndicator />}
      {isError && <RefetchOnError onRefetch={refetch} />}
      {processedReports ? (
        <ProcessedReportsTable
          processedReports={processedReports}
          date={monthYearFilterValue}
          setDate={setMonthYearFilterValue}
          pagination={pagination}
          onPaginationChange={setPagination}
          sorting={sorting}
          onSortingChange={setSorting}
        />
      ) : null}
    </>
  );
};
