import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import { endOfDay, isValid, lastDayOfMonth, subMonths } from 'date-fns';
import {
  ESDateTimePicker,
  ESTextField,
  useESSnackbar,
} from '@energy-stacks/core/ui';
import {
  AdditionalCostsReportModel,
  processedReportsApiErrors,
  useCreateProcessedAdHocTariffReportMutation,
  useEditProcessedAdHocTariffReportMutation,
} from '@energy-stacks/obelis/feature-charging-hub-reports-data';
import { chargingHubsApi } from '@energy-stacks/obelis/feature-charging-hubs-data';
import { useAppDispatch } from '@energy-stacks/store';
import { ErrorApiResponse, formatDateTime } from '@energy-stacks/shared';
import { ChargingHubReportContainer } from '../ChargingHubReportContainer';
import { chargingHubAdHocTariffReportValidationSchema } from './chargingHubAdHocTariffReportValidationSchema';
import { useRawAdHocTariffReports } from './useRawAdHocTariffReports';
import { useProcessedAdHocTariffReports } from './useProcessedAdHocTariffReports';
import {
  getReportDefaultDate,
  MonthYearPicker,
} from '@energy-stacks/obelis/shared';
import { AdditionalCosts } from './AdditionalCosts';

export type ProcessedAdHocTariffReportFormData = {
  monthYear: Date;
  cost: string;
  additionalCosts?: AdditionalCostsReportModel[];
  validFrom: Date | null;
  validTo: Date | null;
};

const lastMonth = endOfDay(lastDayOfMonth(subMonths(new Date(), 1)));

export const ChargingHubAdHocTariffReportForm = ({
  setIsAdHocTariffFormDirty,
  setIsDisabled,
  activeStep,
  setActiveStep,
  isEditMode,
}: {
  setIsAdHocTariffFormDirty: (isAdHocTariffFormDirty: boolean) => void;
  setIsDisabled: (isDisabled: boolean) => void;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isEditMode: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('chargingHubReports');
  const [createProcessedAdHocTariffReport, { isLoading: isCreating }] =
    useCreateProcessedAdHocTariffReportMutation();
  const [editProcessedAdHocTariffReport, { isLoading: isEditing }] =
    useEditProcessedAdHocTariffReportMutation();
  const {
    data: rawReportData,
    isLoading: isAdHocTariffRawReportLoading,
    error: rawDataError,
  } = useRawAdHocTariffReports(isEditMode);
  const {
    data: processedReportData,
    isLoading: isAdHocTariffProcessedReportLoading,
    error: processedDataError,
  } = useProcessedAdHocTariffReports(isEditMode);
  const { uid: reportUid, id: chargingHubUid, selectedMonth } = useParams();
  const dispatch = useAppDispatch();
  const { showSnackbar } = useESSnackbar();

  const reportData = isEditMode ? processedReportData : rawReportData;

  const defaultAdHocTariffValues = useMemo(() => {
    return {
      monthYear: getReportDefaultDate(
        reportData?.monthYear,
        selectedMonth,
        lastMonth
      ),
      cost: reportData?.cost ? reportData?.cost : '',
      validFrom: reportData?.validFrom ? new Date(reportData.validFrom) : null,
      validTo: reportData?.validTo ? new Date(reportData.validTo) : null,
      additionalCosts:
        reportData?.additionalCosts && reportData?.additionalCosts?.length > 0
          ? reportData?.additionalCosts
          : [],
    };
  }, [reportData, selectedMonth]);

  const formMethods = useForm<ProcessedAdHocTariffReportFormData>({
    defaultValues: defaultAdHocTariffValues,
    mode: 'onTouched',
    resolver: yupResolver(chargingHubAdHocTariffReportValidationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { isValid: isFormValid, isDirty: isFormDirty, errors },
    reset: resetForm,
    trigger,
  } = formMethods;

  const validFrom = useWatch({
    control,
    name: 'validFrom',
  });
  const validTo = useWatch({
    control,
    name: 'validTo',
  });

  const handleClose = useCallback(() => {
    resetForm();
    navigate(-1);
  }, [navigate, resetForm]);

  const isDisabled =
    isAdHocTariffProcessedReportLoading ||
    isAdHocTariffRawReportLoading ||
    isEditing ||
    isCreating;

  const submitChargingHubAdHocTariffReportForm: SubmitHandler<ProcessedAdHocTariffReportFormData> =
    useCallback(
      (data) => {
        const { monthYear, cost, validFrom, validTo, additionalCosts } = data;

        if (
          (!isEditMode && !chargingHubUid) ||
          (isEditMode && !reportUid) ||
          !validFrom ||
          !validTo
        ) {
          return;
        }

        const successMessage = isEditMode
          ? 'updateChargingHubAdHocTariffSuccess'
          : 'createChargingHubAdHocTariffSuccess';

        const reportHandler = isEditMode
          ? editProcessedAdHocTariffReport({
              uid: reportUid as string,
              body: {
                monthYear,
                cost,
                validFrom,
                validTo,
                additionalCosts,
              },
            })
          : createProcessedAdHocTariffReport({
              chargingHubUid: chargingHubUid as string,
              monthYear,
              cost,
              validFrom,
              validTo,
              additionalCosts,
            });

        reportHandler
          .unwrap()
          .then(() => {
            showSnackbar('success', successMessage, 'chargingHubReports');
            dispatch(
              chargingHubsApi.util.invalidateTags(['ChargingHubReports'])
            );
            handleClose();
          })
          .catch((error) => {
            showSnackbar(
              'error',
              processedReportsApiErrors[error.data?.errorCode],
              'chargingHubReports'
            );
          });
      },
      [
        chargingHubUid,
        createProcessedAdHocTariffReport,
        editProcessedAdHocTariffReport,
        handleClose,
        isEditMode,
        dispatch,
        reportUid,
        showSnackbar,
      ]
    );

  useEffect(() => {
    setIsAdHocTariffFormDirty && setIsAdHocTariffFormDirty(isFormDirty);
    setIsDisabled(isDisabled);
  }, [isFormDirty, isDisabled, setIsAdHocTariffFormDirty, setIsDisabled]);

  useEffect(() => {
    resetForm(defaultAdHocTariffValues);
  }, [defaultAdHocTariffValues, resetForm]);

  useEffect(() => {
    if (rawDataError) {
      if ('status' in rawDataError && rawDataError.status === 404) {
        return;
      } else {
        showSnackbar('error');
      }
    }
  }, [rawDataError, showSnackbar]);

  useEffect(() => {
    if (processedDataError) {
      if ('data' in processedDataError) {
        const response = processedDataError.data as ErrorApiResponse;
        processedDataError.data
          ? showSnackbar(
              'error',
              processedReportsApiErrors[response.errorCode],
              'chargingHubReports'
            )
          : showSnackbar('error');
      } else {
        showSnackbar('error');
      }
      handleClose();
    }
  }, [processedDataError, showSnackbar, handleClose]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(submitChargingHubAdHocTariffReportForm)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 124px)',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <ChargingHubReportContainer
          title={t('adHocTariff')}
          isFormValid={isFormValid}
          isFormDirty={isFormDirty}
          isLoading={isEditing || isCreating}
          activeStep={activeStep ?? 1}
          setActiveStep={setActiveStep}
          isEditMode={isEditMode}
        >
          <Grid
            container
            sx={{
              paddingLeft: 4,
              paddingRight: 8,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('month') + ' *'}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.monthYear ?? '-'}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Controller
                control={control}
                name="monthYear"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <MonthYearPicker
                      value={value}
                      onChange={onChange}
                      maxDate={lastMonth}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              paddingLeft: 4,
              paddingRight: 8,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {`${t('cost')} [${t('ctPerKwhWithoutVat')}] *`}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1">{reportData?.cost ?? '-'}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Controller
                control={control}
                name="cost"
                render={({ field: { onChange, onBlur, value } }) => (
                  <ESTextField
                    placeholder={reportData?.cost ?? '-'}
                    value={value}
                    disabled={isDisabled}
                    onChange={(event) => {
                      const inputValue = event?.target.value;
                      // this regex matches a string that consists entirely of one or more digits, with no other characters before or after
                      if (inputValue === '' || /^\d+$/.test(inputValue ?? '')) {
                        onChange(inputValue);
                      }
                    }}
                    onBlur={onBlur}
                    error={Boolean(errors['cost'])}
                    helperText={
                      Boolean(errors['cost']) && t(`${errors['cost']?.message}`)
                    }
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              paddingLeft: 4,
              paddingRight: 8,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('validFrom') + ' *'}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.validFrom
                  ? formatDateTime(reportData?.validFrom)
                  : '-'}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Controller
                control={control}
                name="validFrom"
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESDateTimePicker
                      value={value}
                      disabled={isDisabled}
                      onChange={(value) => {
                        onChange(value);
                        if (isValid(validTo)) {
                          trigger('validTo');
                        }
                      }}
                      onBlur={onBlur}
                      onClose={() => trigger('validFrom')}
                      error={Boolean(errors['validFrom'])}
                      helperText={
                        Boolean(errors['validFrom']) &&
                        t(`${errors['validFrom']?.message}`)
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              paddingLeft: 4,
              paddingRight: 8,
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={500} color={'grey.600'}>
                {t('validTo') + ' *'}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1">
                {reportData?.validTo
                  ? formatDateTime(reportData?.validTo)
                  : '-'}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Controller
                control={control}
                name="validTo"
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <ESDateTimePicker
                      value={value}
                      disabled={isDisabled}
                      minDateTime={validFrom || undefined}
                      onChange={onChange}
                      onBlur={onBlur}
                      onClose={() => trigger('validTo')}
                      error={Boolean(errors['validTo'])}
                      helperText={
                        Boolean(errors['validTo']) &&
                        t(`${errors['validTo']?.message}`)
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>

          <AdditionalCosts isDisabled={isDisabled} />
        </ChargingHubReportContainer>
      </form>
    </FormProvider>
  );
};
