import { useParams } from 'react-router-dom';
import { useGetRawAdHocTariffReportQuery } from '@energy-stacks/obelis/feature-charging-hub-reports-data';

export const useRawAdHocTariffReports = (isEdit: boolean) => {
  const { id } = useParams<{ id: string }>();
  const { selectedMonth } = useParams<{ selectedMonth: string }>();

  const { data, isLoading, error, isSuccess, refetch, isError } =
    useGetRawAdHocTariffReportQuery(
      {
        chargingHubUid: id ?? '',
        monthYear: selectedMonth ?? '',
      },
      {
        skip: isEdit,
      }
    );

  return {
    data,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  };
};
