import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IconPlus } from '@tabler/icons-react';
import { ESButton } from '@energy-stacks/core/ui';
import { useAppLocation } from '@energy-stacks/shared';
import { format } from 'date-fns';
import { ObelisRoutes } from '../ObelisRoutes';

export const CreateReportButton = ({
  selectedMonth,
  disabled,
}: {
  selectedMonth: Date;
  disabled?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useAppLocation();
  const { t } = useTranslation('obelisShared');

  const onClickHandler = useCallback(() => {
    navigate(
      `${ObelisRoutes.CreateReport}/${format(selectedMonth, 'MM-yyyy')}`,
      {
        state: { background: location, shouldBackgroundFreeze: true },
      }
    );
  }, [location, navigate, selectedMonth]);

  return (
    <ESButton
      disabled={disabled}
      data-testid="createReportButton"
      onClick={onClickHandler}
      sx={{
        width: '210px',
      }}
      startIcon={<IconPlus />}
    >
      {t('createReportButtonLabel')}
    </ESButton>
  );
};
