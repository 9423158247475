import { useMemo } from 'react';
import { Box, Skeleton, useMediaQuery } from '@mui/material';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';
import { useChargingHubDetails } from './useChargingHubDetails';
import { ChargingHubDetailsLocationMapView } from './ChargingHubDetailsLocationMapView';

export const ChargingHubDetailsMap = () => {
  const { chargingHubDetails, isLoading: isLoadingChargingHubDetails } =
    useChargingHubDetails();
  const isFullHD = useMediaQuery('(min-width: 1920px)');

  const coords = useMemo(() => {
    if (chargingHubDetails?.coordinates) {
      return {
        latitude: chargingHubDetails.coordinates.latitude,
        longitude: chargingHubDetails.coordinates.longitude,
      };
    }
    return null;
  }, [chargingHubDetails?.coordinates]);

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        aspectRatio: isFullHD ? 'unset' : '2/1',
        borderRadius: 3,
      }}
    >
      {isLoadingChargingHubDetails ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          height="100%"
          width="100%"
        />
      ) : (
        <ChargingHubDetailsLocationMapView
          coords={coords}
          editRoute={ObelisRoutes.EditChargingHub}
        />
      )}
    </Box>
  );
};
