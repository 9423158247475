import { TabConfig } from '@energy-stacks/core/ui';
import { ObelisRoutes } from '@energy-stacks/obelis/shared';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChargingStationDetailsGeneralTab } from './ChargingStationDetailsGeneralTab';
import { ChargingStationDetailsSessionsTab } from './ChargingStationDetailsSessionsTab';
import { ChargingStationDetailsReportsTab } from './ChargingStationDetailsReportsTab';

export const useChargingStationDetailsTabs = () => {
  const [t] = useTranslation('chargingStations');

  const config: TabConfig = useMemo(() => {
    return {
      tabRootPath: `${ObelisRoutes.ChargingStations}/${ObelisRoutes.ChargingStationDetails}`,
      tabs: [
        {
          component: ChargingStationDetailsGeneralTab,
          name: t('chargingStationDetailsTabs.general'),
          path: ObelisRoutes.ChargingStationDetailsGeneralTab,
        },
        {
          component: ChargingStationDetailsSessionsTab,
          name: t('chargingStationDetailsTabs.sessions'),
          path: ObelisRoutes.ChargingStationDetailsSessionsTab,
        },
        {
          component: ChargingStationDetailsReportsTab,
          name: t('chargingStationDetailsTabs.reports'),
          path: ObelisRoutes.ChargingStationDetailsReportsTab,
        },
      ],
    };
  }, [t]);

  return { config };
};
