import { Typography, MenuItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface FilterPopupClearButtonProps {
  label?: string;
  onClick: () => void;
  disabled?: boolean;
}

export const FilterPopupClearButton: React.FC<FilterPopupClearButtonProps> = ({
  label,
  onClick,
  disabled,
}) => {
  const [t] = useTranslation('shared');

  return (
    <MenuItem
      data-testid="filterPopupClearButton"
      onClick={onClick}
      disabled={disabled}
      sx={{
        p: 4,
      }}
    >
      <Typography sx={{ fontWeight: 500 }}>{label ?? t('clearAll')}</Typography>
    </MenuItem>
  );
};
