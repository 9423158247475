import { DEFAULT_PAGINATION_CONFIG } from '@energy-stacks/core/ui';
import { useGetChargingHubProcessedReportsQuery } from '@energy-stacks/obelis/feature-charging-hubs-data';

interface GetChargingHubProcessedReportsConfig {
  monthYear: string;
  chargingHubUid: string;
  pagination: {
    page: number;
    size: number;
  };
}

export const useChargingHubDetailsReports = (
  config: GetChargingHubProcessedReportsConfig
) => {
  const {
    data: processedReports,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  } = useGetChargingHubProcessedReportsQuery({
    monthYear: config.monthYear,
    chargingHubUid: config.chargingHubUid,
    pageNumber: config?.pagination?.page ?? DEFAULT_PAGINATION_CONFIG.page,
    pageSize: config?.pagination?.size ?? DEFAULT_PAGINATION_CONFIG.size,
  });

  return {
    processedReports,
    isLoading,
    error,
    isSuccess,
    refetch,
    isError,
  };
};
