import { HotlineReportRatingDto } from './hotlineReportRatingDto';
import { HotlineReportRatingModel } from './hotlineReportRatingModel';

export const hotlineReportRatingModelToDtoMap: Record<
  HotlineReportRatingModel,
  HotlineReportRatingDto
> = {
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  '6': 6,
} as const;
